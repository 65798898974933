import moment from 'moment';
import {
  HStack,
  Pressable,
  Text,
  useClipboard,
  useToast,
  VStack,
} from 'native-base';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAsLocalNumber } from '../../utils/number-formatter';
import MkCard from '../basic/MkCard';
import { CopyTextIcon } from '../MkIcons';

type InvoiceDetailsPaymentInfoProps = {
  accountNumber: string;
  id: string;
  amount: number;
  dueDate: string;
};

type PaymentInfoListItemProps = {
  title: string;
  value: string;
  suffix?: string;
  showIcon?: boolean;
  handleClick?: (value: string) => void;
};

const InvoiceDetailsPaymentInfo = ({
  accountNumber,
  id,
  amount,
  dueDate,
}: InvoiceDetailsPaymentInfoProps) => {
  const { t } = useTranslation();

  const { onCopy } = useClipboard();
  const toast = useToast();
  const [status, setStatus] = useState(false);
  const handleClick = (value: string) => {
    if (!status) {
      onCopy(value.replace(' ', ''));
      setStatus(true);
      toast.show({
        title: t('profile.invoicesScreen.invoiceDetails.copied'),
        duration: 700,
        onCloseComplete: () => setStatus(false),
      });
    }
  };

  return (
    <MkCard>
      <VStack space={8} mx={8} my={9}>
        <PaymentInfoListItem
          title={t('profile.invoicesScreen.invoiceDetails.accountNumber')}
          value={accountNumber}
          handleClick={handleClick}
        />
        <PaymentInfoListItem
          title={t('profile.invoicesScreen.invoiceDetails.accountId')}
          value={id}
          handleClick={handleClick}
        />
        <PaymentInfoListItem
          title={t('profile.invoicesScreen.invoiceDetails.amount')}
          value={`${formatAsLocalNumber(amount)}`}
          suffix={'kroner'}
          handleClick={handleClick}
        />
        <PaymentInfoListItem
          title={t('profile.invoicesScreen.invoiceDetails.dueDate')}
          value={t('profile.invoicesScreen.date', {
            date: moment(dueDate).toDate(),
          })}
          showIcon={false}
        />
      </VStack>
    </MkCard>
  );
};

const PaymentInfoListItem = ({
  title,
  value,
  suffix = '',
  showIcon = true,
  handleClick,
}: PaymentInfoListItemProps) => {
  function copyValue() {
    if (handleClick) handleClick(value);
  }

  return (
    <HStack justifyContent={'space-between'} alignItems={'center'}>
      <VStack>
        <Text fontFamily={'readerPro'} fontSize={16}>
          {title}
        </Text>
        <Text fontFamily={'readerRegular'} fontSize={16}>
          {`${value} ${suffix}`}
        </Text>
      </VStack>
      {showIcon && (
        <Pressable hitSlop={20} onPress={copyValue}>
          <CopyTextIcon />
        </Pressable>
      )}
    </HStack>
  );
};

export default InvoiceDetailsPaymentInfo;
