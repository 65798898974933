import {
  BottomSheetModal,
  useBottomSheetTimingConfigs,
} from '@gorhom/bottom-sheet';
import { Pressable, useColorMode } from 'native-base';
import { RefObject, useMemo } from 'react';
import { motkraftTheme } from '../../theme';
import MkMetersActionSheet from './MkMetersActionSheet';
import MkRegionsActionSheet from './MkRegionsActionSheet';

type MkBottomSheetProps = {
  isCustomer: boolean;
  bottomSheetModalRef: RefObject<BottomSheetModal>;
  closeFunction: () => void;
  data: any;
  setData: any;
  dismissFunction: () => void;
};

const MkBottomSheet = ({
  isCustomer,
  bottomSheetModalRef,
  closeFunction,
  data,
  setData,
  dismissFunction,
}: MkBottomSheetProps) => {
  const snapPoints = useMemo(() => ['50%'], []);
  const { colorMode } = useColorMode();

  const animationConfigs = useBottomSheetTimingConfigs({
    duration: 750,
  });

  return (
    <BottomSheetModal
      ref={bottomSheetModalRef}
      index={0}
      snapPoints={snapPoints}
      animationConfigs={animationConfigs}
      enablePanDownToClose={true}
      enableContentPanningGesture={false}
      backdropComponent={(props) => (
        <Pressable
          onPress={() => {
            bottomSheetModalRef.current?.close();
          }}
          bgColor={'rgba(0,0,0,0.75)'}
          {...props}
        />
      )}
      onDismiss={dismissFunction}
      backgroundStyle={{
        backgroundColor:
          colorMode === 'dark' ? motkraftTheme.colors.cardBgDark : 'white',
      }}
      handleIndicatorStyle={{
        backgroundColor: motkraftTheme.colors.chartUnselectedBar,
        width: 25,
      }}
    >
      {isCustomer ? (
        <MkMetersActionSheet
          closeCallback={closeFunction}
          data={data}
          setActionSheetData={setData}
        />
      ) : (
        <MkRegionsActionSheet
          closeCallback={closeFunction}
          data={data}
          setActionSheetData={setData}
        />
      )}
    </BottomSheetModal>
  );
};

export default MkBottomSheet;
