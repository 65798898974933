import { useState, useCallback, useEffect, useMemo } from 'react';
import { getItem, removeItem, setItem } from '../utils/storage';

type EphemeralValue = {
  value: string;
  expiry?: number;
};

const KEY_PREFIX = 'ephemeral';

export const generateKey = (key: string) => `${KEY_PREFIX}-${key}`;

export const unixTimestamp = () => Math.floor(Date.now() / 1000);

export const useEphemeralValue = (key: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [ephemeralValue, setEphemeralValue] = useState<EphemeralValue | null>(
    null
  );
  const fullKey = generateKey(key);

  useEffect(() => {
    getItem(fullKey).then((value) => {
      const newValue = value ? (JSON.parse(value) as EphemeralValue) : null;
      setEphemeralValue(newValue);
      setLoading(false);
    });
  }, []);

  const isExpired = useCallback(() => {
    if (ephemeralValue?.expiry) {
      return unixTimestamp() > ephemeralValue.expiry;
    } else {
      return false;
    }
  }, [ephemeralValue]);

  const setValue = async ({ value, expiry }: EphemeralValue) => {
    setLoading(true);
    const newEphemeralValue: EphemeralValue = { value, expiry };
    await setItem(fullKey, JSON.stringify(newEphemeralValue));
    setEphemeralValue(newEphemeralValue);
    setLoading(false);
  };

  const destroy = useCallback(async () => {
    await removeItem(fullKey);
    setEphemeralValue(null);
  }, [key]);

  return {
    loading,
    ...useMemo(
      () => ({
        value: ephemeralValue?.value ?? null,
        setValue,
      }),
      [ephemeralValue?.value ?? null]
    ),
    destroy,
    isExpired,
  };
};
