import { Center, ScrollView, Text, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { ErrorSVG, MkLogoTitle } from '../../components/MkIcons';
import MkLogoWithChat from '../../components/basic/MkLogoWithChat';

type ErrorPageScreenProps = {
  showTitle?: boolean;
  showChatButton?: boolean;
};

const ErrorPageScreen = ({
  showTitle = true,
  showChatButton = false,
}: ErrorPageScreenProps) => {
  const { t } = useTranslation();

  return (
    <ScrollView>
      <VStack>
        {showTitle && (showChatButton ? <MkLogoWithChat /> : <MkLogoTitle />)}
        <VStack pl={5} w={80} pt={90}>
          <Text
            _light={{
              color: 'black',
            }}
            fontFamily={'readerBold'}
            fontSize={20}
            textAlign={'center'}
            pb={30}
          >
            {t('errorCard.title')}
          </Text>
          <Text
            _light={{
              color: 'black',
            }}
            fontFamily={'readerRegular'}
            fontSize={17}
            textAlign={'center'}
            pb={25}
          >
            {t('errorCard.content')}
          </Text>
          <Text
            _light={{
              color: 'black',
            }}
            fontFamily={'readerRegular'}
            fontSize={17}
            textAlign={'center'}
          >
            {t('errorCard.updatecontent')}
          </Text>
        </VStack>
      </VStack>
      <Center pl={5} w={80} pt={66} pb={50}>
        <ErrorSVG />
      </Center>
    </ScrollView>
  );
};

export default ErrorPageScreen;
