// import { LOCALE_NORWEGIAN } from './constants';

// TODO GM: find proper library implementation for this
//  hardcoded for Norwegian locale
export const formatAsLocalNumber = (
  number: number,
  digits = 2
  // locale = LOCALE_NORWEGIAN
): string => {
  const numberStr = number.toFixed(digits);
  const parts = numberStr.split('.');
  const wholeNumberPart = parts[0];
  const formattedWholeNumberPart = wholeNumberPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ' '
  );
  const decimalPart = parts.length > 1 ? parts[1] : undefined;
  return [formattedWholeNumberPart, decimalPart]
    .filter((part) => part !== undefined)
    .join(',');
};
