import { HStack, Pressable, Text, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { InvoiceItemProp } from '../../types/invoice';
import { yearMonthToDate } from '../../utils/date';
import { FileLogo } from '../MkIcons';
import MkCard from '../basic/MkCard';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../../types/Navigation';
import { motkraftTheme } from '../../theme';
import moment from 'moment';

export const InvoiceListItem = ({ invoice, label, style }: InvoiceItemProp) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  let color = '';
  let dateTitle = '';
  let statusTitle = '';

  if (
    !invoice.isPaid &&
    moment()
      .startOf('day')
      .isAfter(moment(invoice.dueDate).add(2, 'days').startOf('day'))
  ) {
    color = motkraftTheme.colors.invoiceIconExpired;
    dateTitle = `${t('profile.invoicesScreen.invoiceDetails.Overdue')}: ${t(
      'profile.invoicesScreen.date',
      {
        date: moment(invoice.dueDate).toDate(),
      }
    )}`;
    statusTitle = t(`profile.invoicesScreen.invoiceDetails.textExpired`);
  } else if (!invoice.isPaid) {
    color = motkraftTheme.colors.invoiceIconUnpaid;
    dateTitle = `${t('profile.invoicesScreen.invoiceDetails.paymentDue')}: ${t(
      'profile.invoicesScreen.date',
      {
        date: moment(invoice.dueDate).toDate(),
      }
    )}`;
    statusTitle = t(`profile.invoicesScreen.invoiceDetails.textUnpaid`);
  } else {
    color = motkraftTheme.colors.primary;
    dateTitle = `${t('profile.invoicesScreen.invoiceDetails.paymentDate')}: ${t(
      'profile.invoicesScreen.date',
      {
        date:
          invoice.paymentDate !== null
            ? moment(invoice.paymentDate).toDate()
            : moment().toDate(),
      }
    )}`;
    statusTitle = t(`profile.invoicesScreen.invoiceDetails.textPaid`);
  }

  return (
    <Pressable
      onPress={() => {
        navigation.navigate('gated', {
          screen: 'invoiceDetails',
          params: {
            accountId: invoice.accountId,
            invoiceNumber: invoice.invoiceNumber,
            statusTitle: statusTitle,
            amount: invoice.amount,
            monthYear: t('profile.invoicesScreen.monthYear', {
              date: yearMonthToDate(invoice.year, invoice.month),
              capitalize: true,
            }),
          },
        });
      }}
    >
      <MkCard style={style}>
        <HStack px={18} py={15} alignItems={'center'}>
          <FileLogo stroke={color} />
          <VStack flex={1} pl={29}>
            <Text fontFamily={'readerPro'} fontSize={16}>
              {label
                ? label
                : `${t('profile.invoicesScreen.monthYear', {
                    date: yearMonthToDate(invoice.year, invoice.month),
                    capitalize: true,
                  })}`}
            </Text>
          </VStack>
          <Text fontFamily={'readerRegular'} fontSize={14}>
            {statusTitle}
          </Text>
        </HStack>
      </MkCard>
    </Pressable>
  );
};
