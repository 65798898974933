import { apiSlice } from './apiSlice';
import {
  InvoiceApiParams,
  InvoiceDetailResponse,
  InvoiceList,
  InvoiceListApiResponse,
} from '../../types/invoice';

const enhancedInvoiceApiSlice = apiSlice.enhanceEndpoints({
  addTagTypes: ['invoiceDetails'],
});

export const invoiceApiSlice = enhancedInvoiceApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    invoiceList: builder.query<InvoiceList, number>({
      query: (limit: number) => ({
        url: '/v3/invoices',
        params: { offset: '0', limit: limit },
        method: 'get',
      }),
      transformResponse: (response: InvoiceListApiResponse) => {
        const responseObj: InvoiceList = {
          invoices: [],
          totalNumberOfInvoices: response.totalNumberOfInvoices,
        };
        Object.values(response.invoices).forEach((invoices) => {
          responseObj.invoices.push(...invoices);
        });
        return responseObj;
      },
    }),
    invoiceDetails: builder.query<InvoiceDetailResponse, InvoiceApiParams>({
      query: ({ accountId, invoiceNumber }) => ({
        url: `/v3/account/${accountId}/invoices/${invoiceNumber}`,
        method: 'get',
      }),
      providesTags: ['invoiceDetails'],
    }),
  }),
});

export const { useInvoiceListQuery, useInvoiceDetailsQuery } = invoiceApiSlice;
