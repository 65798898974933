import { ScrollView, Text, View, VStack } from 'native-base';
import { useEffect, useState } from 'react';
import { InvoiceListItem } from '../../components/invoice/InvoiceListItem';
import MkBackground from '../../components/basic/MkBackground';
import { useInvoiceListQuery } from '../../redux/slices/invoiceApiSlice';
import { InvoiceListItemType } from '../../types/invoice';
import LoadingSkeleton from '../miscellaneous/LoadingSkeleton';
import AllInvoicesCollapsible from '../../components/invoice/AllInvoicesCollapsible';
import { useTranslation } from 'react-i18next';
import ErrorPageScreen from '../miscellaneous/ErrorPageScreen';
import { RefreshControl } from 'react-native';
import moment from 'moment';
import { useCustomerExistQuery } from '../../redux/slices/customerExistApiSlice';
import InvoicesEmptyScreen from '../../components/invoice/InvoicesEmptyScreen';
import useProgressViewOffset from '../../hooks/useProgressViewOffset';
const InvoicesScreen = () => {
  const { t } = useTranslation();
  const progressViewOffset = useProgressViewOffset();
  const {
    data: customerExists,
    isLoading: customerExistsLoading,
    isFetching: customerExistsFetching,
    refetch: customerExistsRefetch,
  } = useCustomerExistQuery();

  const {
    data: invoicesList,
    isLoading: invoicesLoading,
    isFetching: invoicesFetching,
    refetch: invoicesRefetch,
  } = useInvoiceListQuery(100);

  const [unpaidList, setUnpaidList] = useState<InvoiceListItemType[]>(
    [] as InvoiceListItemType[]
  );
  const [overdueList, setOverdueList] = useState<InvoiceListItemType[]>(
    [] as InvoiceListItemType[]
  );
  const [allInvoices, setAllInvoices] = useState<InvoiceListItemType[]>(
    [] as InvoiceListItemType[]
  );

  useEffect(() => {
    if (invoicesList) {
      const unpaid = [] as InvoiceListItemType[];
      const overdue = [] as InvoiceListItemType[];
      const all = [] as InvoiceListItemType[];

      invoicesList.invoices.forEach((invoice) => {
        if (
          !invoice.isPaid &&
          moment()
            .startOf('day')
            .isAfter(moment(invoice.dueDate).add(2, 'days').startOf('day'))
        ) {
          overdue.push(invoice);
          all.push(invoice);
        } else if (!invoice.isPaid) {
          unpaid.push(invoice);
        } else {
          all.push(invoice);
        }
      });
      setUnpaidList(unpaid);
      setOverdueList(overdue);
      setAllInvoices(all);
    }
  }, [invoicesList]);

  if (
    invoicesLoading ||
    invoicesFetching ||
    customerExistsLoading ||
    customerExistsFetching
  )
    return <LoadingSkeleton />;

  return (
    <MkBackground pt={12} pb={10}>
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={invoicesFetching && customerExistsFetching}
            onRefresh={() => {
              invoicesRefetch();
              customerExistsRefetch();
            }}
            progressViewOffset={progressViewOffset}
          />
        }
      >
        {invoicesList ? (
          <View>
            {invoicesList.invoices.length > 0 ? (
              <ScrollView>
                {unpaidList.length > 0 && (
                  <>
                    <Text
                      fontFamily={'readerRegular'}
                      fontSize={18}
                      _light={{ color: 'black' }}
                    >
                      {t('profile.invoicesScreen.invoiceDetails.textUnpaid')}
                    </Text>
                    <VStack space={2} mt={19}>
                      {unpaidList.map((i, index) => {
                        return (
                          <InvoiceListItem
                            key={index}
                            invoice={i}
                            label={`${t('profile.invoices')} ${index + 1}`}
                          />
                        );
                      })}
                    </VStack>
                  </>
                )}
                {overdueList.length > 0 && (
                  <>
                    <Text
                      mt={38}
                      fontFamily={'readerRegular'}
                      fontSize={18}
                      _light={{ color: 'black' }}
                    >
                      {t('profile.invoicesScreen.invoiceDetails.textExpired')}
                    </Text>
                    <VStack space={2} mt={19}>
                      {overdueList.map((i, index) => {
                        return (
                          <InvoiceListItem
                            key={index}
                            invoice={i}
                            label={`${t('profile.invoices')} ${index + 1}`}
                          />
                        );
                      })}
                    </VStack>
                  </>
                )}
                {allInvoices.length > 0 && (
                  <>
                    <Text
                      mt={38}
                      mb={19}
                      fontFamily={'readerRegular'}
                      fontSize={18}
                      _light={{ color: 'black' }}
                    >
                      {t(
                        'profile.invoicesScreen.invoiceDetails.textAllInvoices'
                      )}
                    </Text>
                    <AllInvoicesCollapsible invoices={allInvoices} />
                  </>
                )}
              </ScrollView>
            ) : (
              <InvoicesEmptyScreen />
            )}
          </View>
        ) : customerExists && !customerExists.exists ? (
          <InvoicesEmptyScreen />
        ) : (
          <ErrorPageScreen />
        )}
      </ScrollView>
    </MkBackground>
  );
};

export default InvoicesScreen;
