import {
  MeterNickName,
  ResidenceMeter,
} from '../../screens/gated/PowerAgreementScreen';
import { apiSlice } from './apiSlice';
import { invoiceApiSlice } from './invoiceApiSlice';

const enhancedResidenceApiSlice = apiSlice.enhanceEndpoints({
  addTagTypes: ['residences'],
});

const residencesApiSlice = enhancedResidenceApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    residences: builder.query<ResidenceMeter[], void>({
      query: () => ({
        url: '/v2/residences',
        method: 'get',
      }),
      providesTags: ['residences'],
      transformResponse: (response: ResidenceMeter[]) => {
        const resObj = [] as ResidenceMeter[];
        response?.forEach((m: ResidenceMeter) => {
          const meterObj = m;
          m['name'] = m.name;
          m['metzumCustomerId'] = m.metzumCustomerId;
          m['address'] = m.address;
          m['owner'] = m.owner;
          resObj.push(meterObj);
        });

        return resObj;
      },
    }),
    addMeterNickname: builder.mutation<MeterNickName, MeterNickName>({
      query: (payload) => ({
        url: '/update/nicknames',
        method: 'post',
        data: payload,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(invoiceApiSlice.util.invalidateTags(['invoiceDetails']));
      },
      invalidatesTags: ['residences'],
    }),
  }),
  overrideExisting: true,
});

export const { useResidencesQuery, useAddMeterNicknameMutation } =
  residencesApiSlice;
