import { Text, useColorMode, View, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { ErrorSVG, MkLogoTitle } from '../MkIcons';

const InvoicesEmptyScreen = () => {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();

  return (
    <VStack alignItems={'center'}>
      <View alignSelf={'flex-start'}>
        <MkLogoTitle />
      </View>
      <Text
        mt={95}
        fontSize={20}
        fontFamily={'readerBold'}
        _light={{ color: 'black' }}
      >
        {t('profile.invoicesScreen.invoicesEmptyScreen.title')}
      </Text>
      <Text
        _light={{ color: 'black' }}
        mt={55}
        fontSize={17}
        fontFamily={'readerRegular'}
        textAlign={'center'}
      >
        {t('profile.invoicesScreen.invoicesEmptyScreen.desc')}
      </Text>
      <View mt={70}>
        <ErrorSVG />
      </View>
    </VStack>
  );
};

export default InvoicesEmptyScreen;
