import { useContext } from 'react';
import { AuthenticationContext } from '../screens/context/AuthenticationContext';

export const useAuthentication = () => {
  const authenticationContext = useContext(AuthenticationContext);
  if (authenticationContext === undefined) {
    throw new Error(
      'authenticationContext must be inside a AuthenticationContext'
    );
  }
  return { ...authenticationContext };
};
