import { Button, HStack, useColorMode } from 'native-base';
import { PriceScreenIntervalDropdownValue } from '../../screens/gated/PowerPriceScreen';
import { PowerPriceIntervals } from '../../types/PowerPrice';
import { convertIntervalToDropdownData } from '../../utils/functions';
import { useTranslation } from 'react-i18next';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { motkraftTheme } from '../../theme';
import { Dispatch, SetStateAction } from 'react';
import { UseTrackEventType } from '../../../mixpanel.native';
import { Platform } from 'react-native';
import moment from 'moment';

let useTrackEvent: UseTrackEventType = null;
if (Platform.OS !== 'web') {
  import('../../../mixpanel.native').then((mixP) => {
    useTrackEvent = mixP.useTrackEvent;
  });
} else {
  console.warn('Mixpanel is disabled for web');
}

type MkDayIntervalSwitchProps = {
  selectedInterval: PriceScreenIntervalDropdownValue;
  handleIntervalChange: (interval: PriceScreenIntervalDropdownValue) => void;
  toggleChartSwapping: Dispatch<SetStateAction<boolean>>;
};

const MkDayIntervalSwitch = ({
  selectedInterval,
  handleIntervalChange,
  toggleChartSwapping,
}: MkDayIntervalSwitchProps) => {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();
  const DAYS = Object.values(PowerPriceIntervals);
  const intervals = convertIntervalToDropdownData(DAYS, t);
  const pillWidth = 135;
  const bgTranslation = useSharedValue(
    selectedInterval.value === PowerPriceIntervals.today ? 0 : pillWidth
  );
  const trackTodayTomorrowPress = useTrackEvent
    ? useTrackEvent('Today/Tomorrow Price Switch Change')
    : null;

  const bgTranslationAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: bgTranslation.value }],
    };
  });

  const todayTextStyle = useAnimatedStyle(() => {
    return {
      color: interpolateColor(
        bgTranslation.value,
        [0, pillWidth],
        [motkraftTheme.colors.primary, 'white']
      ),
    };
  });

  const tomorrowTextStyle = useAnimatedStyle(() => {
    return {
      color: interpolateColor(
        bgTranslation.value,
        [0, pillWidth],
        ['white', motkraftTheme.colors.primary]
      ),
    };
  });

  const handleButtonPress = (index: number) => {
    if (selectedInterval.label === intervals[index].label) {
      return;
    }
    toggleChartSwapping(true);
    bgTranslation.value = withTiming(index === 0 ? 0 : pillWidth);
    handleIntervalChange(intervals[index]);
    if (trackTodayTomorrowPress) {
      trackTodayTomorrowPress({
        'Date-Time': moment().format('HH:mm DD:MM:YYYY'),
      });
    }
  };

  return (
    <HStack
      borderRadius={'20'}
      bgColor={colorMode === 'dark' ? '#1E2828' : '#9AB8B8'}
      w={pillWidth * 2}
      h={9}
    >
      <Animated.View
        style={[
          {
            backgroundColor: motkraftTheme.colors.chartUnselectedBar,
            width: '50%',
            height: '100%',
            position: 'absolute',
            borderRadius: 20,
          },
          bgTranslationAnimatedStyle,
        ]}
      />
      <Button
        flex={1}
        onPress={() => {
          handleButtonPress(0);
        }}
        borderRadius={20}
        px={5}
        py={1}
      >
        <Animated.Text
          style={[{ fontSize: 18, fontFamily: 'readerPro' }, todayTextStyle]}
        >
          {intervals[0].label}
        </Animated.Text>
      </Button>
      <Button
        flex={1}
        onPress={() => {
          handleButtonPress(1);
        }}
        borderRadius={20}
        px={5}
        py={1}
      >
        <Animated.Text
          style={[{ fontSize: 18, fontFamily: 'readerPro' }, tomorrowTextStyle]}
        >
          {intervals[1].label}
        </Animated.Text>
      </Button>
    </HStack>
  );
};

export default MkDayIntervalSwitch;
