import { HStack, Text, useColorMode, View } from 'native-base';
import { useState } from 'react';
import {
  AllInvoicesCollapsibleProps,
  GroupedInvoices,
} from '../../types/invoice';
import {
  getSortedDescendingKeys,
  toInvoiceMonthYearString,
} from '../../utils/functions';
import MkCard from '../basic/MkCard';
import Accordion from 'react-native-collapsible/Accordion';
import { InvoiceListItem } from './InvoiceListItem';
import { useTranslation } from 'react-i18next';
import { CollapsibleDownArrow, CollapsibleUpArrow, FileLogo } from '../MkIcons';
import { motkraftTheme } from '../../theme';

const AllInvoicesCollapsible = ({ invoices }: AllInvoicesCollapsibleProps) => {
  const [activeSections, setActiveSections] = useState<number[]>([]);
  const [showAllInvoices, setShowAllInvoices] = useState<boolean>(false);
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  const arrowColor =
    colorMode === 'dark'
      ? motkraftTheme.colors.white
      : motkraftTheme.colors.chartUnselectedBar;
  const groupedItems = {} as GroupedInvoices;

  invoices.forEach((item) => {
    const dateString = `${item.year}-${item.month}`;
    if (groupedItems[dateString] == undefined) {
      groupedItems[dateString] = [item];
    } else {
      groupedItems[dateString] = [...groupedItems[dateString], item];
    }
  });

  let keys = getSortedDescendingKeys(Object.keys(groupedItems));
  const totalInvoices = keys.length;

  if (!showAllInvoices) {
    keys = keys.splice(0, 3);
  }

  function renderHeader(section: string) {
    const [year, month] = section.split('-');
    const dateString = toInvoiceMonthYearString(
      parseInt(year),
      parseInt(month),
      t
    );
    const isOpen = section === keys[activeSections[0]];
    return (
      <HStack
        _dark={{
          bgColor: 'cardBgDark',
        }}
        _light={{
          bgColor: 'white',
        }}
        px={18}
        py={25}
        my={isOpen ? 0 : 2}
        alignItems={'center'}
        borderTopRadius={10}
        borderBottomRadius={isOpen ? 0 : 10}
      >
        <FileLogo />
        <Text ml={13} flex={1} fontFamily={'readerPro'} fontSize={18}>
          {dateString}
        </Text>
        {isOpen ? (
          <CollapsibleUpArrow color={arrowColor} />
        ) : (
          <CollapsibleDownArrow color={arrowColor} />
        )}
      </HStack>
    );
  }

  function renderContent(section: string) {
    return (
      <MkCard borderRadius={0} borderBottomRadius={10} pb={29}>
        {groupedItems[section].map((item, index) => {
          return (
            <InvoiceListItem
              key={index}
              invoice={item}
              label={`${t('profile.invoices')} ${index + 1}`}
              style={{
                backgroundColor:
                  colorMode === 'dark'
                    ? motkraftTheme.colors.innerCardBorder
                    : 'white',
                borderRadius: 0,
                marginVertical: 4,
                borderTopWidth: colorMode === 'light' ? 1 : 0,
                borderBottomWidth: colorMode === 'light' ? 1 : 0,
                borderColor:
                  colorMode === 'light'
                    ? motkraftTheme.colors.switchOffTrack
                    : null,
              }}
            />
          );
        })}
      </MkCard>
    );
  }
  return (
    <View>
      <Accordion
        containerStyle={{
          marginBottom: 60,
        }}
        sections={keys}
        activeSections={activeSections}
        renderHeader={renderHeader}
        renderContent={renderContent}
        onChange={setActiveSections}
        underlayColor={'rgba(0,0,0,0)'} //By default it shows black color on click changing opacity to 0 to hide that
      />
      {!showAllInvoices && totalInvoices > 3 && (
        <View
          alignSelf={'center'}
          borderBottomWidth={1.5}
          borderBottomColor="primary"
        >
          <Text
            _light={{ color: 'black' }}
            fontFamily={'readerPro'}
            fontSize={18}
            mb={1}
            onPress={() => {
              setShowAllInvoices(true);
            }}
          >
            {t('dashboard.tabs.seemore')}
          </Text>
        </View>
      )}
    </View>
  );
};

export default AllInvoicesCollapsible;
