import { HStack, Text, VStack, View } from 'native-base';
import MkCard from '../basic/MkCard';
import GraphSettingsSwitch, { SwitchTypes } from './GraphSettingsSwitch';

export type GraphSettingsItemButtonProps = {
  iconName: any;
  title: string;
  description?: string;
  text1: string;
  text2: string;
  switchType: SwitchTypes;
};

const GraphSettingsItemButton = ({
  iconName,
  title,
  description,
  text1,
  text2,
  switchType,
}: GraphSettingsItemButtonProps) => {
  return (
    <MkCard p={18}>
      <HStack alignItems={'center'}>
        <HStack alignItems={'center'} flex={6}>
          {iconName}
          <VStack pl={5}>
            <Text fontFamily={'readerPro'} fontSize={16}>
              {title}
            </Text>
            {description && (
              <Text fontFamily={'readerPro'} fontSize={16}>
                {description}
              </Text>
            )}
          </VStack>
        </HStack>
        <View flex={4}>
          <GraphSettingsSwitch
            text1={text1}
            text2={text2}
            switchType={switchType}
          />
        </View>
      </HStack>
    </MkCard>
  );
};

export default GraphSettingsItemButton;
