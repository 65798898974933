import { LinkingOptions } from '@react-navigation/native';
import { RootStackParamList } from '../types/Navigation';
import * as Linking from 'expo-linking';

export const LinkingConfig: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL(''), 'https://app.motkraft.no/'],
  config: {
    screens: {
      public: {
        screens: {
          landing: 'landing',
          login: 'login',
        },
      },
      gated: {
        path: 'app',
        screens: {
          addressDetail: 'addressDetail',
          agreement: 'agreement',
          invoiceDetails: 'invoiceDetails',
          invoices: 'invoices',
          powerConsumption: 'powerConsumption',
          powerPrice: 'powerPrice',
          termsandconditions: 'termsandconditions',
          bottomNavigator: {
            screens: {
              services: 'services',
              dashboard: 'dashboard',
              profile: 'profile',
            },
          },
        },
      },
    },
  },
};
