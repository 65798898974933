import { DiscoveryDocument } from 'expo-auth-session';
import { createContext, useCallback, useEffect, useState, useRef } from 'react';
import Auth0, { Credentials } from 'react-native-auth0';
import { AppState } from 'react-native';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { showMessage } from 'react-native-flash-message';
import { useToast } from 'native-base';
import { useTranslation } from 'react-i18next';
import { reactAppConfig } from '../../config/environment';
import { useEphemeralValue } from '../../hooks/useEphemeralValue';
import {
  AppVersionsFeatureSpecificationType,
  useLazyFeatureToggleQuery,
} from '../../redux/slices/featureToggleApiSlice';
export const CLIENT_ID = reactAppConfig.clientId;
export const CLIENT_DOMAIN = reactAppConfig.clientDomain;
export const CLIENT_AUDIENCE = reactAppConfig.clientAudience;
export const VIPPS_CONNECTION_NAME = reactAppConfig.vippsConnectionName;

const auth0 = new Auth0({
  clientId: CLIENT_ID,
  domain: CLIENT_DOMAIN,
});
const audience = CLIENT_AUDIENCE;

export interface AuthenticationContextProps {
  accessToken: string | null;
  jwt: string | null;
  loading: boolean;
  userAuthenticated: boolean;
  isFeaturesInitialized: boolean; //
  enforceUpdate: boolean; //
  signInWithSMS: (phoneNumber: string, callingCode: string) => Promise<void>;
  verifyOTP: (phoneNumber: string, otp: string) => Promise<Credentials>;
  accessTokenExpired: () => boolean;
  refreshTokenExpired: () => boolean;
  loginWithVipps: () => Promise<void>;
  refreshSession: () => Promise<
    | {
        accessToken: string;
        jwt: string;
      }
    | undefined
  >;
  destroySession: () => Promise<void>;
  authorizeSession: (authorizationCode: Credentials) => Promise<void>;
  canRefreshSession: () => boolean;
  signWithEmailPassword: () => Promise<void>;
  tokenLoading: boolean;
}
export interface AccessTokenDecode {
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
}

interface AuthenticationProviderProps {
  children: React.ReactNode;
}

export const discoveryDocument = {
  authorizationEndpoint: `${CLIENT_DOMAIN}/authorize`,
  tokenEndpoint: `${CLIENT_DOMAIN}/oauth/token`,
  userInfoEndpoint: `${CLIENT_DOMAIN}/userinfo`,
  revocationEndpoint: `${CLIENT_DOMAIN}/oauth/revoke`,
} as DiscoveryDocument;

export const AuthenticationContext = createContext(
  {} as AuthenticationContextProps
);

const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
  children,
}: AuthenticationProviderProps) => {
  const [numActiveTasks, setNumActiveTasks] = useState<number>(0);
  const startTask = () => setNumActiveTasks((numTasks) => numTasks + 1);
  const finishTask = () => setNumActiveTasks((numTasks) => numTasks - 1);
  const { t } = useTranslation();
  const toast = useToast();
  const [tokenLoading, setTokenLoading] = useState(true);
  const [fetchFeatures] = useLazyFeatureToggleQuery();
  const [enforceUpdate, setEnforceUpdate] = useState(false);
  const lastFetchedMoment = useRef<moment.Moment>();
  const appState = useRef(AppState.currentState);
  const [isFeaturesInitialized, setIsFeaturesInitialized] = useState(false);
  const {
    loading: accessTokenLoading,
    setValue: setAccessToken,
    value: accessToken,
    isExpired: accessTokenExpired,
    destroy: destroyAccessToken,
  } = useEphemeralValue('oauth-access-token');
  const {
    loading: refreshTokenLoading,
    setValue: setRefreshToken,
    value: refreshToken,
    isExpired: refreshTokenExpired,
    destroy: destroyRefreshToken,
  } = useEphemeralValue('oauth-refresh-token');

  const {
    loading: jwtLoading,
    setValue: setJwt,
    value: jwt,
    destroy: destroyJwt,
    isExpired: jwtTokenExpired,
  } = useEphemeralValue('user-data-jwt');

  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const loading =
    [accessTokenLoading, refreshTokenLoading, jwtLoading]
      .map((valueLoading: boolean) => (valueLoading ? 1 : 0))
      .reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        numActiveTasks
      ) !== 0;

  const canRefreshSession = useCallback(
    () => Boolean(refreshToken && !refreshTokenExpired()),
    [refreshToken, refreshTokenExpired]
  );

  const loadingWrapper = async (
    method: () => Promise<void>,
    cleanupOnError?: () => Promise<void>
  ) => {
    let error: unknown = false;
    let result: unknown = false;
    try {
      startTask();
      result = await method();
    } catch (e: unknown) {
      if (e instanceof Error) {
        const stack = new Error().stack;
        if (stack) {
          e.stack =
            e.stack +
            '\nFrom previous ' +
            stack.split('\n').slice(0, 2).join('\n') +
            '\n';
        }
      }
      error = e;
      if (cleanupOnError) await cleanupOnError();
    } finally {
      finishTask();
      // eslint-disable-next-line no-unsafe-finally
      if (error) throw error;
    }
    return result;
  };
  const loginWithVipps = async () => {
    setTokenLoading(true);
    try {
      const response = await auth0.webAuth.authorize(
        {
          connection: VIPPS_CONNECTION_NAME,
          audience,
          scope: 'openid profile email offline_access',
          additionalParameters: { prompt: 'login' },
        },
        {
          ephemeralSession: true,
        }
      );
      await authorizeSession(response);
    } catch (err: any) {
      toast.show({ title: err.error, description: err.error_description });
    } finally {
      setTokenLoading(false);
      navigator;
    }
  };

  const signInWithSMS = async (phoneNumber: string, callingCode: string) => {
    return auth0.auth.passwordlessWithSMS({
      phoneNumber: '+' + callingCode + phoneNumber,
      send: 'code',
    });
  };

  const signWithEmailPassword = async () => {
    const response = await auth0.webAuth.authorize(
      {
        connection: 'Username-Password-Authentication',
        audience,
        scope:
          'openid profile email offline_access Username-Password-Authentication',
        additionalParameters: { prompt: 'login' },
      },
      {
        ephemeralSession: true,
      }
    );
    await authorizeSession(response);
  };

  const verifyOTP = async (phoneNumber: string, otp: string) => {
    try {
      const response = await auth0.auth.loginWithSMS({
        phoneNumber,
        code: otp,
        scope: 'openid offline_access',
        audience,
      });
      await authorizeSession(response);

      return response;
    } catch (err: any) {
      throw err;
    } finally {
      setTokenLoading(false);
    }
  };

  const removeSessionData = useCallback(async () => {
    await destroyAccessToken();
    await destroyRefreshToken();
    await destroyJwt();
    setIsFeaturesInitialized(false);
    setUserAuthenticated(false);
  }, [destroyAccessToken, destroyRefreshToken, destroyJwt]);

  const destroySession = useCallback(async () => {
    await loadingWrapper(async () => {
      await removeSessionData();
    });
  }, [accessToken, removeSessionData]);

  const fetchGlobalFeaturesHelper = () => {
    lastFetchedMoment.current = moment();
    fetchFeatures()
      .then((features) => {
        const appVersionFeature = features.data?.find(
          (e) => e.featureName === 'appVersions'
        );
        if (appVersionFeature?.isEnabled) {
          const featureSpecification: AppVersionsFeatureSpecificationType =
            JSON.parse(appVersionFeature?.featureSpecification);
          const currentAppVersion = reactAppConfig.versionNumber;
          const isCurrentSupported =
            featureSpecification.supportedVersions?.includes(currentAppVersion);
          if (!isCurrentSupported) {
            setEnforceUpdate(true);
          } else {
            setEnforceUpdate(false);
          }
        }
      })
      .finally(() => {
        setIsFeaturesInitialized(true);
      });
  };
  const processTokenResponse = async (response: Credentials) => {
    try {
      const decode: AccessTokenDecode = jwt_decode(response.accessToken);
      await setAccessToken({
        value: response.accessToken,
        expiry: decode.exp,
      });
      if (response.refreshToken) {
        await setRefreshToken({ value: response.refreshToken });
      }
    } catch (err) {}
  };

  const authorizeSession = async (tokenResponse: Credentials) => {
    await loadingWrapper(
      async () => {
        await processTokenResponse(tokenResponse);
        await getUserData(tokenResponse);
      },
      async () => {
        if (accessToken) {
          await destroySession();
        }
      }
    );
  };

  const getUserData = async (token: Credentials) => {
    const decodeIdToken: unknown = jwt_decode(token.idToken);
    if (
      typeof decodeIdToken === 'object' &&
      decodeIdToken !== null &&
      'exp' in decodeIdToken &&
      typeof decodeIdToken.exp === 'number'
    )
      await setJwt({ value: token.idToken, expiry: decodeIdToken.exp });
  };

  const refreshSession = useCallback(async () => {
    const token = {
      accessToken: '',
      jwt: '',
    };
    if (!canRefreshSession() || loading) return;
    await loadingWrapper(
      async () => {
        const tokenResponse = await auth0.auth.refreshToken({
          refreshToken: refreshToken as string,
          scope: 'openid profile email',
          grant: 'refresh_token',
        });
        token.accessToken = tokenResponse.accessToken;
        token.jwt = tokenResponse.idToken;
        await processTokenResponse(tokenResponse);
        await getUserData(tokenResponse);
      },
      async () => {
        if (accessToken) {
          await destroySession();
          showMessage({
            type: 'info',
            message: t('errors.authSessionExpired'),
          });
        }
      }
    );
    return token;
  }, [refreshToken, loading, destroySession]);

  const isAuthenticated = useCallback(async () => {
    if (accessToken && !accessTokenExpired() && jwt && !jwtTokenExpired()) {
      return true;
    } else if (canRefreshSession()) {
      await refreshSession();
      return true;
    } else {
      return false;
    }
  }, [accessToken, accessTokenExpired, jwt, canRefreshSession, refreshSession]);

  useEffect(() => {
    if (!loading) {
      isAuthenticated().then((auth) => {
        setUserAuthenticated(auth);
        setTokenLoading(false);
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (userAuthenticated !== null) {
      setIsFeaturesInitialized(false);
      fetchGlobalFeaturesHelper();
    }
  }, [userAuthenticated]);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (
        appState.current.match(/background/) &&
        nextAppState === 'active' &&
        (moment().isAfter(
          lastFetchedMoment.current?.clone().add(10, 'minutes')
        ) ||
          moment().isBefore(lastFetchedMoment.current) ||
          moment()
            .startOf('hour')
            .isAfter(lastFetchedMoment.current?.clone().startOf('hour')))
      ) {
        setIsFeaturesInitialized(false);
        fetchGlobalFeaturesHelper();
      }
      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        accessToken,
        jwt,
        loading,
        userAuthenticated,
        signInWithSMS,
        verifyOTP,
        accessTokenExpired,
        refreshTokenExpired,
        canRefreshSession,
        refreshSession,
        destroySession,
        loginWithVipps,
        authorizeSession,
        signWithEmailPassword,
        tokenLoading,
        isFeaturesInitialized,
        enforceUpdate,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
