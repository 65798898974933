import { useColorMode } from 'native-base';
import { Dimensions, Platform } from 'react-native';
import {
  LineSegment,
  VictoryAxis,
  VictoryChart,
  VictoryCursorContainer,
  VictoryLine,
  VictoryScatter,
} from 'victory-native';
import { motkraftTheme } from '../../theme';

type MkLineChartProps = {
  scatterData: any;
  chartData: any;
  minDomain: number;
  maxDomain: number;
};

const MkLineChart = ({
  scatterData,
  chartData,
  minDomain,
  maxDomain,
}: MkLineChartProps) => {
  let noOfZeros;
  const range = Math.round(maxDomain - minDomain);
  if (range < 10) {
    noOfZeros = range.toString().length - 1;
  } else {
    noOfZeros = 1;
  }
  const TICK_DIFF = 2 * Math.pow(10, noOfZeros);
  const XLABELS = [0, 4, 8, 12, 16, 20, 23];
  const lineChartWidth = Dimensions.get('screen').width;
  const { colorMode } = useColorMode();
  const minD = Math.floor(minDomain / TICK_DIFF) * TICK_DIFF;
  const maxD = Math.ceil(maxDomain / TICK_DIFF) * TICK_DIFF;
  let increment = (maxD - minD) / 4;
  const YLABELS = noOfZeros
    ? [
        minD,
        Math.round(minD + increment),
        Math.round(minD + 2 * increment),
        Math.round(minD + 3 * increment),
        maxD,
      ]
    : [minD, Math.round(minD + 2 * increment), maxD];
  return (
    <VictoryChart
      containerComponent={
        <VictoryCursorContainer
          cursorComponent={
            <LineSegment
              style={{
                stroke:
                  colorMode === 'dark'
                    ? 'white'
                    : motkraftTheme.colors.lightText,
                strokeDasharray: 3,
                x1: '10%',
                strokeWidth: 1,
              }}
            />
          }
          cursorDimension={'y'}
          defaultCursorValue={scatterData[0].y}
          disable={true}
        />
      }
      width={lineChartWidth - 72}
      height={195}
      padding={{ right: 8, left: 45, bottom: 25, top: 10 }}
      minDomain={{ y: minD }}
      maxDomain={{ y: maxD }}
    >
      <VictoryLine
        style={{
          data: {
            stroke:
              colorMode === 'dark'
                ? motkraftTheme.colors.primary
                : motkraftTheme.colors.lightText,
          },
        }}
        data={chartData}
      />
      <VictoryAxis
        style={{
          axis: {
            stroke: null,
          },
          tickLabels: {
            fill:
              colorMode === 'dark' ? 'white' : motkraftTheme.colors.lightText,
            fontSize: 13,
            fontFamily: 'readerPro',
          },
        }}
        tickValues={XLABELS}
        tickFormat={(x) => {
          return x < 10 ? `0${x}` : `${x}`;
        }}
      />
      <VictoryAxis
        offsetX={35}
        crossAxis={false}
        dependentAxis={true}
        style={{
          axis: {
            stroke: null,
          },
          tickLabels: {
            fill:
              colorMode === 'dark' ? 'white' : motkraftTheme.colors.lightText,
            fontSize: 13,
            fontFamily: 'readerPro',
          },
        }}
        tickValues={YLABELS}
        tickFormat={(y) => {
          const formattedNumber =
            y < 0
              ? `-${Math.abs(y).toString().padStart(2, '0')}`
              : y < 10
              ? `0${y}`
              : y.toString();
          return formattedNumber;
        }}
      />
      <VictoryScatter
        style={{ data: { fill: motkraftTheme.colors.primary } }}
        size={7}
        data={scatterData}
      />
    </VictoryChart>
  );
};

export default MkLineChart;
