import { NativeBaseProvider } from 'native-base';
import { motkraftTheme } from './src/theme';
import { Provider } from 'react-redux';
import { store } from './src/redux/store';
import './src/i18n/i18n-config';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useFonts } from 'expo-font';
import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';
import { reactAppConfig } from './src/config/environment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { useTranslation } from 'react-i18next';
import { UseTrackEventType } from './mixpanel.native';
import AppWrapper from './src/components/basic/AppWrapper';
import AuthenticationProvider from './src/screens/context/AuthenticationContext';

/*
Created and exported for automatic performance instrumentation config.

Ref: https://docs.sentry.io/platforms/react-native/performance/instrumentation/automatic-instrumentation/#react-navigation
*/
export const routingInstrumentation =
  Platform.OS !== 'web'
    ? new Sentry.Native.ReactNavigationInstrumentation()
    : undefined;

Sentry.init({
  dsn: reactAppConfig.sentryDsn,
  tracesSampleRate: 0.4,
  enableInExpoDevelopment: false,
  debug: false,
  integrations:
    Platform.OS === 'web'
      ? []
      : [new Sentry.Native.ReactNativeTracing({ routingInstrumentation })],
});

let useTrackEvent: UseTrackEventType = null;
// Mixpanel react native does not work on web so we have to conditionally import it.
if (Platform.OS !== 'web') {
  import('./mixpanel.native').then((mixP) => {
    useTrackEvent = mixP.useTrackEvent;
  });
} else {
  console.warn('Mixpanel is disabled for web');
}

const App = () => {
  const { i18n } = useTranslation();

  AsyncStorage.getItem('language').then((language) => {
    if (!language) {
      AsyncStorage.setItem('language', 'no');
    } else if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  });

  const [fontsLoaded] = useFonts({
    readerBold: require('./src/assets/Fonts/reader-bold.otf'),
    readerRegular: require('./src/assets/Fonts/reader-regular.otf'),
    readerPro: require('./src/assets/Fonts/reader-medium-pro.ttf'),
  });

  const colorModeManager = {
    get: async () => {
      try {
        let val = await AsyncStorage.getItem('@color-mode');
        return val === 'light' ? 'light' : 'dark';
      } catch (e) {
        return 'dark';
      }
    },
    set: async (value: any) => {
      try {
        await AsyncStorage.setItem('@color-mode', value);
      } catch (e) {}
    },
  };

  if (fontsLoaded) {
    return (
      <Provider store={store}>
        <AuthenticationProvider>
          <GestureHandlerRootView style={{ flex: 1 }}>
            <NativeBaseProvider
              theme={motkraftTheme}
              colorModeManager={colorModeManager}
            >
              <SafeAreaProvider style={{ flex: 1 }}>
                <AppWrapper />
              </SafeAreaProvider>
            </NativeBaseProvider>
          </GestureHandlerRootView>
        </AuthenticationProvider>
      </Provider>
    );
  }

  return null;
};

export default Platform.OS === 'web' ? App : Sentry.Native.wrap(App);
