import { ScrollView, Text, VStack, Pressable } from 'native-base';
import MkBackground from '../../components/basic/MkBackground';
import { useTranslation } from 'react-i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { UseTrackEventType } from '../../../mixpanel.native';
import { Platform } from 'react-native';
import moment from 'moment';

let useTrackEvent: UseTrackEventType = null;
if (Platform.OS !== 'web') {
  import('../../../mixpanel.native').then((mixP) => {
    useTrackEvent = mixP.useTrackEvent;
  });
} else {
  console.warn('Mixpanel is disabled for web');
}

type Language = {
  code: string;
  label: string;
};

type LanguageButtonProps = {
  language: Language;
};

export const LANGUAGES: Language[] = [
  { code: 'no', label: 'Norwegian' },
  { code: 'en', label: 'English' },
];

const LanguageSelectorScreen = () => {
  const { t } = useTranslation();

  return (
    <MkBackground mt={9}>
      <ScrollView mt={37}>
        <VStack space={6}>
          {LANGUAGES.map((language) => {
            return <LanguageButton key={language.code} language={language} />;
          })}
        </VStack>
      </ScrollView>
    </MkBackground>
  );
};

const LanguageButton = ({ language }: LanguageButtonProps) => {
  const { i18n } = useTranslation();
  const trackAppLanguage = useTrackEvent ? useTrackEvent('App language') : null;

  const currentLanguage = i18n.language;

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    AsyncStorage.setItem('language', language.code);
    if (trackAppLanguage) {
      trackAppLanguage({
        'Date-Time': moment().format('HH:mm DD:MM:YYYY'),
        'App language': i18n.language,
      });
    }
  };

  return (
    <Pressable
      p={23}
      borderRadius={15}
      _dark={{
        bgColor: currentLanguage === language.code ? 'primary' : 'cardBgDark',
      }}
      _light={{
        bgColor:
          currentLanguage === language.code ? 'chartUnselectedBar' : 'white',
      }}
      onPress={() => changeLanguage(language.code)}
    >
      <Text
        fontSize={16}
        fontFamily={'readerRegular'}
        _light={{
          color: currentLanguage === language.code ? 'white' : 'lightText',
        }}
      >
        {language.label}
      </Text>
    </Pressable>
  );
};

export default LanguageSelectorScreen;
