import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../slices/apiSlice';
import impersonateReducer from '../slices/impersonateSlice';
import removedCarouselReducer from '../slices/removedCarouselSlice';
import meterReducer from '../slices/meterSlice';
import selectedMeterReducer from '../slices/selectedMeterSlice';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistStore, persistReducer } from 'redux-persist';
import regionReducer from '../slices/regionSlice';
import selectedRegionReducer from '../slices/selectedRegionSlice';
import graphSettingsReducer from '../slices/graphSettingsSlice';
import appReviewReducer from '../slices/appReviewSlice';
import colorModeReducer from '../slices/colorModeSlice';
import consumptionYAxisModeReducer from '../slices/consumptionYAxisModeSlice';
import meterNickNameReducer from '../slices/meterNickNameSlice';

const reducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  impersonateReducer,
  carosals: removedCarouselReducer,
  meters: meterReducer,
  selectedMeterId: selectedMeterReducer,
  regions: regionReducer,
  selectedRegion: selectedRegionReducer,
  graphSettings: graphSettingsReducer,
  appReview: appReviewReducer,
  colorMode: colorModeReducer,
  consumptionYAxisMode: consumptionYAxisModeReducer,
  meterNickName: meterNickNameReducer,
});

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: [
    'meters',
    'carosals',
    'regions',
    'graphSettings',
    'appReview',
    'colorMode',
    'consumptionYAxisMode',
  ],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(apiSlice.middleware),
  devTools: true,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
