import { LinearGradient } from 'expo-linear-gradient';
import { useColorMode } from 'native-base';
import { LinearGradientColors } from '../../theme';
import { StatusBar } from 'expo-status-bar';
import { useEffect, useRef } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from '../../redux/store';
import MkLoader from './MkLoader';
import { AxiosInterceptor } from '../../config/axiosConfig';
import {
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import { LinkingConfig } from '../../navigations/LinkingConfig';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { RootNavigator } from '../../navigations/RootNavigator';
import { RootStackParamList } from '../../types/Navigation';
import { UseTrackEventType } from '../../../mixpanel.native';
import { AppState, Platform } from 'react-native';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useTranslation } from 'react-i18next';
import { Locale, getLocales } from 'expo-localization';
import * as Linking from 'expo-linking';
import moment from 'moment';

let useTrackEvent: UseTrackEventType = null;
if (Platform.OS !== 'web') {
  import('../../../mixpanel.native').then((mixP) => {
    useTrackEvent = mixP.useTrackEvent;
  });
} else {
  console.warn('Mixpanel is disabled for web');
}

const AppWrapper = () => {
  const navigationRef = useNavigationContainerRef<RootStackParamList>();
  const routeNameRef = useRef<string>();
  const trackRouteName = useTrackEvent ? useTrackEvent('Screen visited') : null;
  const { colorMode } = useColorMode();
  const { isFeaturesInitialized } = useAuthentication();
  const { i18n } = useTranslation();
  const trackAppLanguage = useTrackEvent ? useTrackEvent('App language') : null;
  const appState = useRef(AppState.currentState);
  const trackOSLanguage = useTrackEvent ? useTrackEvent('OS language') : null;
  const trackColorMode = useTrackEvent
    ? useTrackEvent('Dark/Light Mode')
    : null;
  const trackAppOpenMethod = useTrackEvent
    ? useTrackEvent('App Open Method')
    : null;

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (appState.current.match(/background/) && nextAppState === 'active') {
        const OSDefaultSetting: Locale[] = getLocales();
        if (trackOSLanguage) {
          trackOSLanguage({
            'OS language': OSDefaultSetting[0].languageCode,
          });
        }
      }
      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    if (trackColorMode) {
      trackColorMode({
        'Selected Mode': colorMode === 'dark' ? 'light' : 'dark',
      });
    }
  }, []);

  return (
    <LinearGradient
      start={[0, 0]}
      end={[0.5, 0.3]}
      colors={
        colorMode === 'dark'
          ? LinearGradientColors.colors
          : ['#F0F0F0', '#F0F0F0']
      }
      style={{ flex: 1 }}
    >
      <StatusBar style={colorMode === 'dark' ? 'light' : 'dark'} />
      <PersistGate persistor={persistor} loading={<MkLoader />}>
        <AxiosInterceptor />
        <NavigationContainer
          ref={navigationRef}
          onReady={() => {
            routeNameRef.current = navigationRef?.getCurrentRoute()?.name;
            if (trackRouteName) {
              trackRouteName({
                'Screen Name': routeNameRef.current,
              });
            }
            if (trackAppLanguage) {
              trackAppLanguage({
                'App language': i18n.language,
              });
            }
            Linking.getInitialURL().then((res) => {
              if (trackAppOpenMethod) {
                trackAppOpenMethod({
                  'Date-Time': moment().format('HH:MM:SS DD:MM:YYYY'),
                  'Deep-Link': res,
                });
              }
            });
          }}
          onStateChange={() => {
            const previousRouteName = routeNameRef.current;
            const currentRouteName = navigationRef?.getCurrentRoute()?.name;

            if (previousRouteName !== currentRouteName) {
              if (trackRouteName) {
                trackRouteName({ 'Screen Name': currentRouteName });
              }
            }

            routeNameRef.current = currentRouteName;
          }}
          linking={LinkingConfig}
        >
          <BottomSheetModalProvider>
            {isFeaturesInitialized ? (
              <RootNavigator />
            ) : (
              <MkLoader renderMkBackground />
            )}
          </BottomSheetModalProvider>
        </NavigationContainer>
      </PersistGate>
    </LinearGradient>
  );
};

export default AppWrapper;
