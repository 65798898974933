import { HStack, Pressable, Text, useColorMode, VStack } from 'native-base';
import DraggableFlatList, {
  RenderItemParams,
  ScaleDecorator,
} from 'react-native-draggable-flatlist';
import { DraggableActiveIcon, DraggableIcon, XIcon } from '../MkIcons';
import { gestureHandlerRootHOC } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Dimensions } from 'react-native';
import { RegionDropDownData } from '../../screens/gated/PowerPriceScreen';
import { setSelectedRegion } from '../../redux/slices/selectedRegionSlice';
import { saveRegionIds } from '../../redux/slices/regionSlice';

type MkRegionActionSheetProps = {
  closeCallback: () => void;
  data: RegionDropDownData[];
  setActionSheetData: any;
};

const MkRegionsActionSheet = gestureHandlerRootHOC(
  ({
    closeCallback,
    data: regions,
    setActionSheetData,
  }: MkRegionActionSheetProps) => {
    const dispatch = useDispatch();
    const DRAGGBLE_FLATLIST_HEIGHT = Dimensions.get('screen').height / 2.5;
    const selectedRegion = useSelector(
      (state: RootState) => state.selectedRegion
    );
    const { t } = useTranslation();
    const { colorMode } = useColorMode();
    const [isDragging, setIsDragging] = useState(false);

    const renderItem = ({
      item,
      drag,
      isActive,
    }: RenderItemParams<RegionDropDownData>) => {
      return (
        <ScaleDecorator>
          <HStack
            alignItems={'center'}
            justifyContent={'space-between'}
            px={27}
            py={13}
            my={19}
            _light={{
              bgColor: isActive ? 'light' : 'transparent',
            }}
            _dark={{ bgColor: isActive ? 'chartUnselectedBar' : 'transparent' }}
            borderRightRadius={15}
          >
            <Pressable
              flex={1}
              flexDirection={'row'}
              justifyContent={'space-between'}
              onPress={() => {
                dispatch(setSelectedRegion(item));
              }}
            >
              <VStack space={2}>
                <Text
                  fontFamily={'readerPro'}
                  fontSize={18}
                  _light={{
                    color:
                      item.value === selectedRegion.value
                        ? 'primary'
                        : isDragging
                        ? 'bottomNavBorder'
                        : 'chartUnselectedBar',
                  }}
                  _dark={{
                    color:
                      item.value === selectedRegion.value
                        ? 'primary'
                        : isDragging
                        ? 'bottomNavBorder'
                        : 'white',
                  }}
                  maxW={280}
                >
                  {`${t('dashboard.onboarding.regions_dropdown_title')} ${
                    item.value
                  }`}
                </Text>
              </VStack>
              <Pressable onLongPress={drag} hitSlop={30}>
                {isActive ? (
                  <DraggableActiveIcon />
                ) : (
                  <DraggableIcon
                    color={colorMode === 'dark' ? '#DCDCDC' : '#294747'}
                  />
                )}
              </Pressable>
            </Pressable>
          </HStack>
        </ScaleDecorator>
      );
    };

    return (
      <VStack py={4}>
        <HStack px={27} alignItems={'center'}>
          <Text
            fontFamily={'readerPro'}
            fontSize={18}
            textAlign={'center'}
            flex={1}
            _light={{ color: '#294747' }}
          >
            {t('dashboard.onboarding.select_regions_text')}
          </Text>
          <Pressable onPress={closeCallback} hitSlop={10}>
            <XIcon />
          </Pressable>
        </HStack>
        <DraggableFlatList
          keyExtractor={(item) => item.value}
          data={regions}
          renderItem={renderItem}
          style={{ height: DRAGGBLE_FLATLIST_HEIGHT, marginVertical: 8 }}
          persistentScrollbar={true}
          onDragBegin={() => {
            setIsDragging(true);
          }}
          onDragEnd={({ data }) => {
            setIsDragging(false);
            const regionIds = data.map((region) => region.value);
            dispatch(saveRegionIds(regionIds));
            setActionSheetData(data);
          }}
          dragHitSlop={20}
          extraData={selectedRegion}
        />
      </VStack>
    );
  }
);

export default MkRegionsActionSheet;
