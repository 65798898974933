import moment from 'moment';
import { Pressable, Text, View } from 'native-base';

type MkDayComponentProps = {
  date: moment.Moment;
  disabled: boolean;
  isDifferentMonth: boolean;
  selectedInterval?: string;
  setSelectedInterval: any;
  wasPreviouslySelected?: boolean;
  isWeekNumber?: boolean;
  selectedWeek?: string;
  mode: 'day' | 'week';
};

const MkDayComponent = ({
  date,
  disabled,
  isDifferentMonth,
  selectedInterval,
  setSelectedInterval,
  wasPreviouslySelected,
  isWeekNumber,
  selectedWeek,
  mode,
}: MkDayComponentProps) => {
  const isSelected = date.format('YYYY-MM-DD') === selectedInterval;
  const numberToRender = isWeekNumber
    ? `${date.clone().isoWeek()}`
    : `${date.get('D')}`;
  return (
    <Pressable
      justifyContent={'center'}
      alignItems={'center'}
      flex={1}
      onPress={() => {
        if (disabled) return;
        if (mode === 'week') {
          setSelectedInterval(date.startOf('isoWeek').format('YYYY-MM-DD'));
        } else {
          setSelectedInterval(date.format('YYYY-MM-DD'));
        }
      }}
      borderLeftRadius={isWeekNumber ? 2 : undefined}
      _dark={{
        bgColor:
          isWeekNumber && moment(date).isSame(moment(selectedWeek))
            ? 'primary'
            : undefined,
      }}
      _light={{
        bgColor:
          isWeekNumber && moment(date).isSame(moment(selectedWeek))
            ? 'chartUnselectedBar'
            : undefined,
      }}
    >
      <View
        borderRadius={
          isSelected ||
          wasPreviouslySelected ||
          (isWeekNumber && wasPreviouslySelected)
            ? 'full'
            : 0
        }
        borderWidth={
          (wasPreviouslySelected && !isSelected) ||
          (isWeekNumber && wasPreviouslySelected)
            ? 1
            : 0
        }
        justifyContent={'center'}
        alignItems={'center'}
        h={9}
        w={9}
        _dark={{
          bgColor: isSelected ? 'primary' : undefined,
          borderColor:
            !isSelected && wasPreviouslySelected ? 'primary' : undefined,
        }}
        _light={{
          bgColor: isSelected ? 'chartUnselectedBar' : undefined,
          borderColor:
            !isSelected && wasPreviouslySelected
              ? 'chartUnselectedBar'
              : undefined,
        }}
      >
        <Text
          fontFamily={'readerPro'}
          fontSize={16}
          textAlign={'center'}
          _dark={{
            color:
              isWeekNumber && moment(date).isSame(moment(selectedWeek))
                ? 'black'
                : isWeekNumber
                ? 'primary'
                : isSelected
                ? 'black'
                : isDifferentMonth || disabled
                ? '#9B9B9B'
                : 'white',
          }}
          _light={{
            color:
              isWeekNumber && moment(date).isSame(moment(selectedWeek))
                ? 'white'
                : isWeekNumber
                ? 'chartUnselectedBar'
                : isSelected
                ? 'white'
                : isDifferentMonth || disabled
                ? '#858585'
                : 'chartUnselectedBar',
          }}
        >
          {numberToRender}
        </Text>
      </View>
    </Pressable>
  );
};

export default MkDayComponent;
