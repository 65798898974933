import { PowerPrice } from '../../types/PowerPrice';
import { apiSlice } from './apiSlice';

type PowerPriceByRegionParams = {
  region: string;
  offset: string;
};

export const powerPriceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    powerPricesByRegion: builder.query<PowerPrice, PowerPriceByRegionParams>({
      query: ({ region, offset }) => ({
        url: `/v2/region-prices/${region}`,
        params: { offset },
      }),
    }),
  }),
});

export const { usePowerPricesByRegionQuery } = powerPriceApiSlice;
