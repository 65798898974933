import { Text, useColorMode, View } from 'native-base';
import {
  LineSegment,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory-native';
import { useState } from 'react';
import { motkraftTheme } from '../../theme';
import { Dimensions } from 'react-native';
import { formatAsLocalNumber } from '../../utils/number-formatter';
import { ConsumptionInterval } from '../../types/PowerConsumption';
import { YAxisMode } from '../../redux/slices/consumptionYAxisModeSlice';

type ChartProps = {
  data: DataSet[];
  defaultSelectedBar?: number;
  xLabels?: any;
  screen: string;
  graphLabel: string;
  interval: ConsumptionInterval;
  chartHeight: number;
  parentSrollRef: any;
};

type DataSet = {
  x: number;
  y: number;
  xLabel?: string;
  label?: string[];
  labelLine1: string;
  labelLine2: string;
  labelLine3: string;
};

const MkDetailsBarChart = ({
  data,
  defaultSelectedBar,
  xLabels,
  screen,
  interval,
  graphLabel,
  chartHeight,
  parentSrollRef,
}: ChartProps) => {
  const defaultSelected = data.find((e) => e.x === defaultSelectedBar);
  const [selectedPoint, setSelectedPoint] = useState({
    x: defaultSelected?.x || -1,
    y: defaultSelected?.y || -1,
  });
  const [dataSet, setDataSet] = useState<DataSet[]>(
    addSelectedLabel(data, selectedPoint.x)
  );
  const [active, setActive] = useState(defaultSelectedBar ? true : false);
  const [isUserDragging, setIsUserDragging] = useState(false);
  const [yLabels, setYLabels] = useState([]);
  const { colorMode } = useColorMode();

  const handleDrag = (x: number, y: number) => {
    if (!isUserDragging && x === selectedPoint.x) {
      setActive(false);
      setSelectedPoint({ x: -1, y: -1 });
      return;
    }
    const active: any = dataSet.find((e) => e.x === x);
    if (!active) return;
    const index = dataSet.indexOf(active);
    const newData = dataSet;
    newData.forEach((data) => (data.label = ['']));
    newData.splice(index, 1, {
      ...active,
      label: [
        `${data[index].labelLine1}`,
        `${data[index].labelLine2}`,
        `${data[index].labelLine3}`,
      ],
    });
    setDataSet(newData);
    setSelectedPoint({ x: x, y: y });
  };

  const minY = Math.min(...dataSet.map((item) => item.y));
  const maxY = Math.max(...dataSet.map((item) => item.y));
  const maxX = Math.max(...dataSet.map((item) => item.x));
  const difference = yLabels[1] - yLabels[0];
  const yDigits = difference < 1 ? 2 : 0;
  const minYDomain = minY > 0 ? minY - (minY * 35) / 100 : minY;
  const maxYDomain = maxY > 0 ? maxY + difference : maxY;

  let offsetValue: number;
  let offsetValueX: number;
  switch (interval) {
    case ConsumptionInterval.daily:
      offsetValue = 4;
      offsetValueX = 13;
      break;
    case ConsumptionInterval.weekly:
      offsetValue = 2;
      offsetValueX = 4;
      break;
    case ConsumptionInterval.monthly:
      offsetValue = 6;
      offsetValueX = 15;
      break;
    default:
      offsetValue = 0;
      break;
  }

  const getYAxisPadding = () => {
    let value = maxY.toFixed().length;
    if (yDigits === 2) {
      value += 2;
    }
    return Math.max(value * 10 + 12, 25);
  };

  const getYAxisUnitPadding = () => {
    let value = maxY.toFixed().length;
    if (screen === 'powerPrice') {
      return value - 1;
    } else if (yDigits === 2 && graphLabel === YAxisMode.cost) {
      return value + 3;
    }
    return value;
  };

  const handleVoronoiOnActivated = (points: any) => {
    if (!active) {
      setActive(true);
    }
    handleDrag(points[0]._x, points[0]._y);
  };

  const handleVoronoiTouchStart = () => {
    setIsUserDragging(true);
    parentSrollRef?.current.setNativeProps({ scrollEnabled: false });
  };

  const handleVoronoiTouchEnd = () => {
    setIsUserDragging(false);
    parentSrollRef?.current.setNativeProps({ scrollEnabled: true });
  };

  return (
    <View>
      <Text pl={getYAxisUnitPadding()}>{graphLabel}</Text>
      <VictoryChart
        containerComponent={
          <VictoryVoronoiContainer
            onActivated={handleVoronoiOnActivated}
            voronoiDimension={'x'}
            style={{
              pointerEvents: 'auto',
              touchAction: 'initial',
            }}
            onTouchStart={handleVoronoiTouchStart}
            onTouchEnd={handleVoronoiTouchEnd}
          />
        }
        height={chartHeight}
        width={Dimensions.get('screen').width - 40}
        padding={{
          top: 5,
          bottom: 40,
          right: 15,
          left:
            interval === ConsumptionInterval.weekly
              ? getYAxisPadding() + 10
              : getYAxisPadding(),
        }}
        domain={{
          y: maxYDomain > 0 ? [minYDomain, maxYDomain] : undefined,
        }}
      >
        <VictoryAxis
          crossAxis
          style={{
            tickLabels: {
              fontFamily: (tick: any) =>
                tick.tickValue === selectedPoint.x
                  ? 'readerBold'
                  : 'readerRegular',
              fill: (tick: any) =>
                tick.tickValue === selectedPoint.x
                  ? motkraftTheme.colors.primary
                  : colorMode === 'dark'
                  ? motkraftTheme.colors.white
                  : motkraftTheme.colors.lightText,
              fontSize: 16,
            },
            axis: {
              stroke:
                colorMode === 'dark'
                  ? '#294747'
                  : motkraftTheme.colors.lightText,
              strokeWidth: 3,
            },
          }}
          tickFormat={(x) => {
            return x < 10 ? `0${x}` : `${x}`;
          }}
          tickValues={xLabels}
          axisComponent={
            <LineSegment x1={-100} x2={Dimensions.get('window').width} />
          }
        />
        <VictoryBar
          labelComponent={
            <VictoryTooltip
              renderInPortal={false}
              constrainToVisibleArea
              active={active}
              flyoutWidth={87}
              flyoutHeight={82}
              cornerRadius={5}
              flyoutStyle={{
                fill: ({ datum }) =>
                  datum.label.length > 1 ? 'white' : 'none',
                stroke: 'none',
              }}
              centerOffset={{
                x: ({ datum }) =>
                  datum.y < yLabels[yLabels.length - 2] && datum.x < offsetValue
                    ? 45
                    : datum.x < offsetValueX &&
                      datum.y > yLabels[yLabels.length - 2]
                    ? interval === ConsumptionInterval.weekly
                      ? 20
                      : 10
                    : datum.y > yLabels[yLabels.length - 2]
                    ? interval === ConsumptionInterval.weekly
                      ? -20
                      : -10
                    : 0,
                y: -10,
              }}
              orientation={({ datum }) => {
                return datum.y < yLabels[yLabels.length - 2]
                  ? 'top'
                  : datum.x < offsetValueX
                  ? 'right'
                  : 'left';
              }}
              pointerLength={0}
              labelComponent={
                <VictoryLabel
                  style={[
                    { fill: '#386666', fontFamily: 'readerPro', fontSize: 13 },
                    { fill: '#386666', fontFamily: 'readerPro', fontSize: 16 },
                    {
                      fill: '#386666',
                      fontFamily: 'readerPro',
                      fontSize: screen === 'powerPrice' ? 11 : 16,
                    },
                  ]}
                  lineHeight={[1, 2, 1]}
                />
              }
            />
          }
          style={{
            data: {
              fill: ({ datum }) =>
                datum.x === selectedPoint.x
                  ? motkraftTheme.colors.primary
                  : colorMode === 'dark'
                  ? motkraftTheme.colors.chartUnselectedBar
                  : motkraftTheme.colors.lightText,
            },
          }}
          data={dataSet}
          barWidth={({ datum }) =>
            datum.x === selectedPoint.x
              ? interval === ConsumptionInterval.weekly
                ? 29
                : 14
              : interval === ConsumptionInterval.weekly
              ? 20
              : 6
          }
          cornerRadius={({ datum }) =>
            datum.x === selectedPoint.x
              ? interval === ConsumptionInterval.weekly
                ? 15
                : 7
              : interval === ConsumptionInterval.weekly
              ? 10
              : 3
          }
        />
        <VictoryAxis
          crossAxis
          dependentAxis
          style={{
            axis: {
              stroke: null,
            },
            tickLabels: {
              fill: (tick: any) =>
                colorMode === 'dark'
                  ? motkraftTheme.colors.white
                  : motkraftTheme.colors.lightText,
              fontSize: 13,
              fontFamily: 'readerPro',
            },
          }}
          tickFormat={(y, index, args) => {
            if (args.toString() !== yLabels.toString()) {
              setYLabels(args);
            }
            return formatAsLocalNumber(y, yDigits);
          }}
          offsetX={getYAxisPadding()}
        />
        {selectedPoint.x !== -1 && (
          <VictoryLine
            data={[
              {
                x: interval === ConsumptionInterval.monthly ? 1 : 0,
                y: selectedPoint.y,
              },
              { x: maxX, y: selectedPoint.y },
            ]}
            style={{
              data: {
                stroke: colorMode === 'dark' ? 'white' : '#919191',
                strokeDasharray: 3,
                strokeWidth: 1,
              },
            }}
          />
        )}
      </VictoryChart>
    </View>
  );
};

export default MkDetailsBarChart;

const addSelectedLabel = (data: DataSet[], selectIndex: number) => {
  const index = data.findIndex((d) => d.x === selectIndex);
  if (index >= 0 && data.length - 1 >= index && data[index].y > 0) {
    data[index].label = [
      `${data[index].labelLine1}`,
      `${data[index].labelLine2}`,
      `${data[index].labelLine3}`,
    ];
  }
  return data;
};
