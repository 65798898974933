import {
  ConsumptionIntervalResponse,
  ConsumptionResponse,
} from '../../types/PowerConsumption';
import { apiSlice } from './apiSlice';

type ConsumptionQueryParams = {
  meterId: string;
  interval: string;
  params: { [key: string]: string };
};

type ConsumptionIntervalQueryParams = {
  meterId: string;
};

export const powerConsumptionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    chartIntervals: builder.query<
      ConsumptionIntervalResponse,
      ConsumptionIntervalQueryParams
    >({
      query: ({ meterId }) => ({
        url: `/v4/meter/${meterId}/consumption/intervals`,
        method: 'get',
      }),
    }),
    consumptionData: builder.query<ConsumptionResponse, ConsumptionQueryParams>(
      {
        query: ({ meterId, interval, params }) => ({
          url: `/v4/meter/${meterId}/consumption/data/${interval}`,
          method: 'get',
          params: params,
        }),
      }
    ),
  }),
});

export const { useChartIntervalsQuery, useConsumptionDataQuery } =
  powerConsumptionApiSlice;
