export function capitalize(str: string): string {
  if (!str) {
    return str;
  }

  return str.charAt(0).toLocaleUpperCase() + str.slice(1);
}

export function padWithZeroes(value: number, length: number): string {
  return value.toString().padStart(length, '0');
}

export const removeWhiteSpaces = (str: string): string => {
  return str.replace(/\s+/g, ' ').trim();
};
