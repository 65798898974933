import { createNativeStackNavigator } from '@react-navigation/native-stack';
import BottomNavigator from '../bottomNavigation/bottom-navigator';
import InvoiceScreen from '../../screens/gated/InvoicesScreen';
import PowerConsumptionScreen from '../../screens/gated/PowerConsumptionScreen';
import PowerAgreementScreen from '../../screens/gated/PowerAgreementScreen';
import { GatedStackParamList } from '../../types/Navigation';
import PowerPriceScreen from '../../screens/gated/PowerPriceScreen';
import { InvoiceDetailScreen } from '../../screens/gated/InvoiceDetailScreen';
import TermsAndCondition from '../../screens/gated/TermsAndCondition';
import AddressDetail from '../../screens/gated/AddressDetail';
import { Pressable, useColorMode, View } from 'native-base';
import { BackIcon, SettingsLogo } from '../../components/MkIcons';
import SettingsScreen from '../../screens/gated/SettingsScreen';
import LanguageSelectorScreen from '../../screens/gated/LanguageSelectorScreen';
import UpdateEnforcementScreen from '../../screens/gated/UpdateEnforcementScreen';
import GraphSettingsScreen from '../../screens/gated/GraphSettingsScreen';
import { useTranslation } from 'react-i18next';

const GatedStack = createNativeStackNavigator<GatedStackParamList>();

const GatedNavigator = () => {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();

  return (
    <GatedStack.Navigator
      screenOptions={(props) => ({
        headerShown: true,
        title: '',
        headerShadowVisible: false,
        headerTransparent: true,
        animation: 'slide_from_right',
        headerLeft: () => {
          return (
            <Pressable onPress={() => props.navigation.goBack()} hitSlop={30}>
              <BackIcon color={colorMode === 'dark' ? 'white' : 'black'} />
            </Pressable>
          );
        },
        headerTitleStyle: {
          fontSize: 25,
          fontFamily: 'readerRegular',
          color: colorMode === 'dark' ? 'white' : 'black',
        },
        headerTitleAlign: 'center',
      })}
      initialRouteName={'bottomNavigator'}
    >
      <GatedStack.Screen
        name="bottomNavigator"
        options={{ headerShown: false }}
        component={BottomNavigator}
      />
      <GatedStack.Screen
        name="invoices"
        component={InvoiceScreen}
        options={{
          title: `${t('profile.invoicesScreen.title')}`,
        }}
      />
      <GatedStack.Screen
        name="invoiceDetails"
        component={InvoiceDetailScreen}
      />
      <GatedStack.Screen
        options={(props) => ({
          title: `${t('dashboard.powerConsumption.title')}`,
          headerRight: () => (
            <Pressable
              onPress={() =>
                props.navigation.navigate('gated', { screen: 'graphSettings' })
              }
            >
              <SettingsLogo />
            </Pressable>
          ),
        })}
        component={PowerConsumptionScreen}
        name="powerConsumption"
      />
      <GatedStack.Screen
        options={(props) => ({
          title: `${t('dashboard.powerPrice.title')}`,
          headerRight: () => (
            <Pressable
              onPress={() =>
                props.navigation.navigate('gated', { screen: 'graphSettings' })
              }
            >
              <SettingsLogo />
            </Pressable>
          ),
        })}
        component={PowerPriceScreen}
        name="powerPrice"
      />
      <GatedStack.Screen
        name="agreement"
        component={PowerAgreementScreen}
        options={{
          title: `${t('service.agreement')}`,
        }}
      />
      <GatedStack.Screen
        name="addressDetail"
        component={AddressDetail}
        options={{
          title: `${t(
            'profile.residences.details.addressDetailStatusBarTitle'
          )}`,
        }}
      />
      <GatedStack.Screen
        options={{
          title: `${t('settings.title')}`,
        }}
        name="settings"
        component={SettingsScreen}
      />
      <GatedStack.Screen
        name="termsandconditions"
        component={TermsAndCondition}
        options={{
          title: `${t('profile.privacy')}`,
        }}
      />
      <GatedStack.Screen
        name="languageSelector"
        component={LanguageSelectorScreen}
        options={{
          title: `${t('languageSelector.language')}`,
        }}
      />
      <GatedStack.Screen
        name="updateEnforcement"
        component={UpdateEnforcementScreen}
      />
      <GatedStack.Screen
        options={{
          title: `${t('profile.graphSettings.title')}`,
        }}
        name="graphSettings"
        component={GraphSettingsScreen}
      />
    </GatedStack.Navigator>
  );
};

export default GatedNavigator;
