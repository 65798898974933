import React from 'react';
import MkBackground from '../../components/basic/MkBackground';
import { ScrollView, Text, VStack, View } from 'native-base';
import { useTranslation } from 'react-i18next';
import { LineGraphIcon, YAxisIcon } from '../../components/MkIcons';
import { motkraftTheme } from '../../theme';
import { SwitchTypes } from '../../components/settings/GraphSettingsSwitch';
import GraphSettingsItemButton from '../../components/settings/GraphSettingsItemButton';
import { useCustomerExistQuery } from '../../redux/slices/customerExistApiSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { GraphIntervals } from '../../components/basic/MkChartSwitch';

const GraphSettingsScreen = () => {
  const { t } = useTranslation();
  const { data: customerExists } = useCustomerExistQuery();
  const priceChartMode = useSelector(
    (state: RootState) => state.graphSettings.priceChartMode
  );
  const consumptionChartMode = useSelector(
    (state: RootState) => state.graphSettings.consumptionChartMode
  );

  return (
    <MkBackground pt={9}>
      <ScrollView mt={22}>
        <VStack space={4}>
          <Text fontFamily={'readerPro'} fontSize={18}>
            {t('profile.graphSettings.priceGraph')}
          </Text>
          <GraphSettingsItemButton
            switchType={SwitchTypes.priceChart}
            iconName={
              <LineGraphIcon
                width={30}
                height={30}
                fill={motkraftTheme.colors.primary}
              />
            }
            title={t('profile.graphSettings.chartStyle')}
            description={`${t(
              `profile.graphSettings.chartSwitch.${
                priceChartMode === GraphIntervals.lineChart
                  ? 'lineChart'
                  : 'barChart'
              }.label`
            )}`}
            text1={`${t(
              'profile.graphSettings.chartSwitch.barChart.shortLabel'
            )}`}
            text2={`${t(
              'profile.graphSettings.chartSwitch.lineChart.shortLabel'
            )}`}
          />
          {customerExists && customerExists.exists && (
            <VStack space={4} pt={5}>
              <Text fontFamily={'readerPro'} fontSize={18}>
                {t('profile.graphSettings.consumptionGraph.title')}
              </Text>
              <GraphSettingsItemButton
                switchType={SwitchTypes.consumptionChart}
                iconName={
                  <LineGraphIcon
                    width={30}
                    height={30}
                    fill={motkraftTheme.colors.primary}
                  />
                }
                title={t('profile.graphSettings.chartStyle')}
                description={`${t(
                  `profile.graphSettings.chartSwitch.${
                    consumptionChartMode === GraphIntervals.lineChart
                      ? 'lineChart'
                      : 'barChart'
                  }.label`
                )}`}
                text1={`${t(
                  'profile.graphSettings.chartSwitch.barChart.shortLabel'
                )}`}
                text2={`${t(
                  'profile.graphSettings.chartSwitch.lineChart.shortLabel'
                )}`}
              />
              <GraphSettingsItemButton
                switchType={SwitchTypes.consumptionYAxis}
                iconName={
                  <YAxisIcon
                    width={30}
                    height={30}
                    fill={motkraftTheme.colors.primary}
                  />
                }
                title={t('profile.graphSettings.consumptionGraph.yAxis')}
                text1={`${t('profile.graphSettings.consumptionGraph.kwtUnit')}`}
                text2={`${t('profile.graphSettings.consumptionGraph.krUnit')}`}
              />
            </VStack>
          )}
        </VStack>
      </ScrollView>
    </MkBackground>
  );
};

export default GraphSettingsScreen;
