import { Text, VStack, View } from 'native-base';
import { useAccountDetailQuery } from '../../redux/slices/accountDetailsSlice';
import MkCard from '../basic/MkCard';
import { MkLogo } from '../MkIcons';
import MkLoader from '../basic/MkLoader';

const AccountDetailsWidget = () => {
  const { data: accountData, isLoading: accountLoading } =
    useAccountDetailQuery();

  if (accountLoading)
    return (
      <MkCard p={15} h={160}>
        <MkLoader renderMkBackground={false} />
      </MkCard>
    );

  const formatPhoneNumber = (phoneNumber: string) => {
    return `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
      2,
      5
    )} ${phoneNumber.slice(5, 7)} ${phoneNumber.slice(7)}`;
  };

  return (
    <>
      {accountData && (
        <MkCard p={15}>
          <MkLogo height={24} width={30} />
          <Text
            mt={30}
            fontFamily={'readerPro'}
            fontSize={20}
            _light={{
              color: 'chartUnselectedBar',
            }}
          >
            {accountData.name}
          </Text>
          <Text
            mt={1}
            fontFamily={'readerRegular'}
            fontSize={16}
            _light={{
              color: 'chartUnselectedBar',
            }}
          >
            {accountData.email}
          </Text>
          <Text
            mt={15}
            fontFamily={'readerRegular'}
            fontSize={16}
            textAlign={'right'}
            _light={{
              color: 'chartUnselectedBar',
            }}
          >
            {formatPhoneNumber(accountData.phoneNumber)}
          </Text>
        </MkCard>
      )}
    </>
  );
};

export default AccountDetailsWidget;
