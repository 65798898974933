import { createSlice } from '@reduxjs/toolkit';

interface impersonateState {
  value: string;
}

const initialState = { value: '' } as impersonateState;

const impersonateSlice = createSlice({
  name: 'impersonate',
  initialState,
  reducers: {
    addImpersonate(state: impersonateState, action: any) {
      state.value = action.payload.headerValue;
    },
  },
});

export const impersonateActions = impersonateSlice.actions;
export default impersonateSlice.reducer;
