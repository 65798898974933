import { Button, HStack, Text, VStack, View, useColorMode } from 'native-base';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { MKLogoLong, VipsIcon } from '../../components/MkIcons';
import MkBackground from '../../components/basic/MkBackground';
import { RootStackParamList } from '../../types/Navigation';
import MkLoader from '../../components/basic/MkLoader';
import { useAuthentication } from '../../hooks/useAuthentication';
import { openBrowserAsync } from 'expo-web-browser';
import { reactAppConfig } from '../../config/environment';
const LandingScreen = () => {
  const { loading, loginWithVipps } = useAuthentication();
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const redirectToLoginScreen = () => {
    navigation.navigate('public', {
      screen: 'login',
    });
  };

  if (loading) return <MkLoader />;

  return (
    <MkBackground
      px={16}
      justifyContent={'space-evenly'}
      alignItems={'center'}
      darkMode
    >
      <View flex={1} justifyContent={'center'}>
        <MKLogoLong />
      </View>
      <VStack space={4} flex={1}>
        <Button
          borderRadius={'full'}
          bg={'primary'}
          onPress={redirectToLoginScreen}
        >
          <Text fontFamily={'readerPro'} fontSize={16} color={''}>
            {t('landingScreen.landingLoginButton')}
          </Text>
        </Button>
        <Button
          borderRadius={'full'}
          bg={'#1E1E1E'}
          borderWidth={1}
          borderColor={'light'}
          onPress={loginWithVipps}
        >
          <HStack alignItems={'center'}>
            <Text
              fontFamily={'readerPro'}
              fontSize={18}
              color={'white'}
              textAlign={'center'}
            >
              {t('landingScreen.loginViaVipps') + ' '}
            </Text>
            <VipsIcon />
          </HStack>
        </Button>
        <HStack justifyContent={'center'} mt={51}>
          <Text textAlign={'center'}>
            <Text fontFamily={'readerPro'} fontSize={16} color={'light'}>
              {t('public.registrationQuestion') + ' '}
            </Text>
            <Text
              fontSize={16}
              color={'primary'}
              fontFamily={'readerBold'}
              onPress={() => {
                openBrowserAsync(reactAppConfig.onboardingUrl);
              }}
            >
              {t('public.register')}
            </Text>
          </Text>
        </HStack>
      </VStack>
      <HStack
        space={1}
        alignSelf={'center'}
        alignItems={'baseline'}
        paddingBottom={54}
      >
        <Text fontFamily={'readerBold'} fontSize={15} color={'light'}>
          {t('splash.subtitle')}
        </Text>
        <Text lineHeight={15} paddingBottom={'3px'}>
          <View h={1} w={1} bgColor={'primary'} borderRadius={'full'} />
        </Text>
      </HStack>
    </MkBackground>
  );
};

export default LandingScreen;
