import { LinearGradient } from 'expo-linear-gradient';
import { theme as defaultTheme, extendTheme } from 'native-base';

export const config = {
  useSystemColorMode: false,
  initialColorMode: 'dark',
  dependencies: {
    'linear-gradient': LinearGradient,
  },
};

export const motkraftTheme = extendTheme({
  ...defaultTheme,
  config,
  colors: {
    dark: '#121212',
    light: '#F0F0F0',
    white: '#FFFFFF',
    black: '#000000',
    darkText: '#03dac4',
    lightText: '#386666',
    primary: '#61D8AA',
    cardBgDark: '#1E2828',
    dropDownBorder: '#212121',
    chartUnselectedBar: '#294747',
    carouselPaginationDefault: '#386666',
    paidPillBg: '#E1FFFA',
    unpaidPillText: '#9E6100',
    unpaidPillBg: '#FBF2E0',
    expiredPillText: '#BC2F2F',
    expiredPillBg: '#FEF4F2',
    invoiceIconExpired: '#FF7866',
    invoiceIconUnpaid: '#EDC06B',
    bottomNavBorder: '#919191',
    innerCardBorder: '#283B39',
    onboardingButtonText: '#262626',
    deleteButtonBg: '#EE7261',
    deleteCancelButtonBg: '#D9D9D9',
    switchOffTrack: '#DCDCDC',
    lightUsage: '#7D7D7D',
    lightChartSwitchBg: '#9AB8B8',
    updateButtonBg: '#43AD8C',
    lightBottomSheetButtonText: '#34866D',
    invoicePdfBgDark: '#3B9A7D',
    graphSwitchUnselectedText: '#25604E',
    encouragementTitle: '#363636',
    encouragementDesc: '#222222',
    encouragementButtonBg: '#163A2F',
    darkScheme: {
      500: '#03dac4',
    },
    motkraftScheme: {
      50: '#ecfffd',
      100: '#c4fff9',
      200: '#9ffcf3',
      300: '#7df7ea',
      400: '#57f4e5',
      500: '#47eddc',
      600: '#38e4d3',
      700: '#2bdac8',
      800: '#2bc3b3',
      900: '#2da99d',
    },
  },
  sizes: {
    xl: {
      fontSize: '64px',
    },
    lg: {
      fontSize: '32px',
    },
    md: {
      fontSize: '16px',
    },
    sm: {
      fontSize: '12px',
    },
  },
  components: {
    Skeleton: {
      baseStyle: () => ({
        ...defaultTheme.components.Skeleton.baseStyle,
        borderRadius: 12,
      }),
    },
    Box: {
      variants: {
        solidRounded: ({ colorScheme }: Record<string, any>) => {
          return {
            bg: `${colorScheme}.500`,
            rounded: 'full',
            _text: {
              color: 'dark',
            },
          };
        },
      },
      baseStyle: () => ({
        ...defaultTheme.components.Box.baseStyle,
        _dark: { color: 'darkText' },
      }),
    },
    Heading: {
      baseStyle: () => {
        return {
          ...defaultTheme.components.Heading.baseStyle,
          _dark: { color: 'darkText' },
        };
      },
    },
    Text: {
      baseStyle: () => {
        return {
          ...defaultTheme.components.Text.baseStyle,
          fontFamily: 'readerRegular',
          _light: {
            color: 'lightText',
          },
        };
      },
    },
    Button: {
      variants: {
        solidRounded: ({ colorScheme }: Record<string, any>) => {
          return {
            h: 50,
            w: [255, 300],
            bg: `primary`,
            borderRadius: 40,
            _text: {
              fontSize: 18,
              fontFamily: 'readerPro',
              letterSpacing: 0.1,
              _dark: {
                color: 'lightText',
              },
            },
          };
        },
        outlinedRounded: ({ colorScheme }: Record<string, any>) => {
          return {
            h: 50,
            w: [255, 300],
            borderRadius: 40,
            borderWidth: 1,
            color: `rbga(0,0,0,0)`,
            borderColor: `#FFFFFF`,
            _text: {
              color: `#FFFFFF`,
              fontSize: 18,
              fontFamily: 'readerPro',
              py: 1,
            },
          };
        },
      },
    },
    ScrollView: {
      baseStyle: {
        showsVerticalScrollIndicator: false,
      },
    },
  },
});

export const LinearGradientColors = {
  colors: [motkraftTheme.colors.innerCardBorder, motkraftTheme.colors.dark],
  start: [0, 0],
  end: [0.5, 0.3],
};
