import { HStack, Pressable, Text, View, useColorMode } from 'native-base';
import { CalendarLeftArrow, CalendarRightArrow } from '../MkIcons';
import { useState } from 'react';
import moment from 'moment';
import MkDayNameComponent from './MkDayNameComponent';
import MkDayComponent from './MkDayComponent';
import { useTranslation } from 'react-i18next';
import { getDayNames } from '../../utils/functions';

type MkDayDatePickerProps = {
  selectedDay: string;
  setSelectedDay: any;
  minDayDate: string;
  maxDayDate: string;
  previousSelectedDay: string;
};

const MkDayDatePicker = ({
  selectedDay,
  setSelectedDay,
  minDayDate,
  maxDayDate,
  previousSelectedDay,
}: MkDayDatePickerProps) => {
  const { colorMode } = useColorMode();
  const [monthYearState, setMonthYearState] = useState(moment(selectedDay));
  const { t } = useTranslation();
  const monthYearTitle = t('dashboard.power.monthYearTitle', {
    date: monthYearState.toDate(),
    capitalize: true,
  });
  const dayNames = getDayNames(t);

  const startDayToRender = monthYearState
    .clone()
    .startOf('month')
    .startOf('isoWeek');
  const endDayToRender = monthYearState
    .clone()
    .endOf('month')
    .endOf('isoWeek')
    .add(1, 'week');

  const diffInDays = endDayToRender.diff(startDayToRender, 'days') + 1;
  const allDaysToRender = [];

  for (let i = 0; i <= diffInDays; i++) {
    const day = moment(startDayToRender).add(i, 'days');
    allDaysToRender.push(day);
  }

  const weeksToRender = [];
  for (let i = 0; i < 6; i++) {
    weeksToRender.push(allDaysToRender.slice(i * 7, (i + 1) * 7));
  }

  const handleLeftArrowPress = () => {
    setMonthYearState(moment(monthYearState).subtract(1, 'month'));
  };

  const handleRightArrowPress = () => {
    setMonthYearState(moment(monthYearState).add(1, 'month'));
  };

  return (
    <View
      bgColor={colorMode === 'dark' ? '#294747' : '#EBEBEB'}
      borderRadius={20}
      py={3}
      px={3}
    >
      <HStack justifyContent={'space-between'} alignItems={'center'} px={3}>
        <Pressable onPress={handleLeftArrowPress} hitSlop={20}>
          <CalendarLeftArrow
            stroke={colorMode === 'dark' ? 'white' : '#294747'}
          />
        </Pressable>
        <Text
          fontFamily={'readerPro'}
          fontSize={18}
          _dark={{ color: 'white' }}
          _light={{ color: '#294747' }}
        >
          {monthYearTitle}
        </Text>
        <Pressable onPress={handleRightArrowPress} hitSlop={20}>
          <CalendarRightArrow
            stroke={colorMode === 'dark' ? 'white' : '#294747'}
          />
        </Pressable>
      </HStack>
      <HStack mt={6} mb={3}>
        {dayNames.map((dayName) => (
          <MkDayNameComponent key={dayName} text={dayName} />
        ))}
      </HStack>
      {weeksToRender.map((week, index) => (
        <HStack key={index} my={1} borderWidth={1} borderColor={'transparent'}>
          {week.map((day, index) => (
            <MkDayComponent
              key={index}
              date={day}
              disabled={
                !moment(day).isBetween(
                  moment(minDayDate),
                  moment(maxDayDate),
                  null,
                  '[]'
                )
              }
              isDifferentMonth={
                moment(monthYearState).month() !== moment(day).month()
              }
              selectedInterval={selectedDay}
              setSelectedInterval={setSelectedDay}
              wasPreviouslySelected={
                day.format('YYYY-MM-DD') === previousSelectedDay
              }
              mode={'day'}
            />
          ))}
        </HStack>
      ))}
    </View>
  );
};

export default MkDayDatePicker;
