import { FlatList, HStack, Pressable, Text, View, VStack } from 'native-base';
import MkBackground from '../../components/basic/MkBackground';
import { ForwardArrow, LocationIcon } from '../../components/MkIcons';
import MkCard from '../../components/basic/MkCard';
import { useTranslation } from 'react-i18next';
import { useResidencesQuery } from '../../redux/slices/residencesApiSlice';
import LoadingSkeleton from '../miscellaneous/LoadingSkeleton';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../../types/Navigation';
import ErrorPageScreen from '../miscellaneous/ErrorPageScreen';
import { RefreshControl } from 'react-native';
import { useCustomerExistQuery } from '../../redux/slices/customerExistApiSlice';
import MkOnboardingWidget from '../../components/basic/MkOnboardingWidget';
import useProgressViewOffset from '../../hooks/useProgressViewOffset';
import { useDispatch } from 'react-redux';
import { setSelectedMeterNickName } from '../../redux/slices/meterNickNameSlice';

type MeterCardProps = {
  meter: ResidenceMeter;
};

export type ResidenceMeter = {
  name: MeterNickName | null;
  metzumCustomerId: string;
  address: Address;
  owner: string;
};

export type MeterNickName = {
  meterId: string;
  nickName: string;
};

type Address = {
  id: string;
  street: string;
  city: string;
  postalCode: string;
};

const PowerAgreementScreen = () => {
  const {
    data: customerExists,
    isLoading: customerExistsLoading,
    error: customerExistsError,
  } = useCustomerExistQuery();

  if (customerExistsLoading) return <LoadingSkeleton />;

  return (
    <>
      {customerExistsError ? (
        <MkBackground pt={12}>
          <ErrorPageScreen />
        </MkBackground>
      ) : customerExists?.exists ? (
        <MkResidencesList />
      ) : (
        <MkBackground pt={12}>
          <MkOnboardingWidget />
        </MkBackground>
      )}
    </>
  );
};

const MkResidencesList = () => {
  const progressViewOffset = useProgressViewOffset();

  const {
    data: residences,
    isLoading: residencesLoading,
    isFetching: residencesFetching,
    refetch: residencesRefetch,
  } = useResidencesQuery();
  if (residencesLoading || residencesFetching) return <LoadingSkeleton />;

  return (
    <MkBackground pt={55}>
      {residences ? (
        <FlatList
          showsVerticalScrollIndicator={false}
          mb={25}
          data={residences}
          renderItem={({ item }) => <MeterCard meter={item} />}
          refreshControl={
            <RefreshControl
              refreshing={residencesFetching}
              onRefresh={residencesRefetch}
              progressViewOffset={progressViewOffset}
            />
          }
        />
      ) : (
        <View>
          <ErrorPageScreen />
        </View>
      )}
    </MkBackground>
  );
};

const MeterCard = ({ meter }: MeterCardProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  return (
    <Pressable
      onPress={() => {
        dispatch(setSelectedMeterNickName(meter.name?.nickName));
        navigation.navigate('gated', {
          screen: 'addressDetail',
          params: {
            meter: meter,
          },
        });
      }}
    >
      <MkCard mb={19} px={23} py={25} minH={136}>
        <HStack flex={1}>
          <LocationIcon />
          <VStack flex={1} ml={17} space={2}>
            {meter.name !== null && meter.name.nickName != '' && (
              <Text fontFamily={'readerPro'} fontSize={18}>
                {meter.name.nickName}
              </Text>
            )}
            <Text fontFamily={'readerRegular'} fontSize={16}>
              {meter.address.street ||
                meter.address.city ||
                meter.address.postalCode}
            </Text>
            <Text fontFamily={'readerRegular'} fontSize={12} mt={2}>
              {`${t('profile.invoicesScreen.invoiceDetails.measurementId')} ${
                meter.name !== null ? meter.name.meterId : ''
              }`}
            </Text>
          </VStack>
          <View alignSelf={'flex-end'}>
            <ForwardArrow />
          </View>
        </HStack>
      </MkCard>
    </Pressable>
  );
};

export default PowerAgreementScreen;
