import { createSlice } from '@reduxjs/toolkit';

export const meterSlice = createSlice({
  name: 'meters',
  initialState: [] as string[],
  reducers: {
    saveMeterIds: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { saveMeterIds } = meterSlice.actions;

export default meterSlice.reducer;
