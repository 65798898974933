import moment from 'moment';
import { ConsumptionInterval } from '../types/PowerConsumption';
import { PowerPriceIntervals, Regions } from '../types/PowerPrice';
import { yearMonthToDate } from './date';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function emptyFn(): void {}

export const convertIntervalToDropdownData = (
  intervals: PowerPriceIntervals[],
  t: any
) =>
  intervals.map((interval) => ({
    value: interval,
    label: `${t(`electricityPrice.${interval}`)}`,
  }));

export const getRegionsDropdownData = () =>
  Object.keys(Regions).map((region) => ({
    label: region,
    value: Regions[region],
  }));

export const convertIntervalToDropdownDataConsumption = (
  intervals: string[],
  intervalType: ConsumptionInterval,
  t: any
) => {
  let translationPath = `consumptionData.resolution.${intervalType}.dateFormat`;
  return [...intervals].reverse().map((interval) => ({
    value: interval,
    label: t(translationPath, {
      date: moment(interval).toDate(),
      capitalize: true,
    }),
  }));
};

export function getSortedDescendingKeys(keys: string[]) {
  return keys.sort((a, b) => {
    return moment(b, 'YYYY-MM').diff(moment(a, 'YYYY-MM'));
  });
}

export function toInvoiceMonthYearString(
  year: number,
  month: number,
  t: any
): string {
  return t('profile.invoicesScreen.monthYear', {
    date: yearMonthToDate(year, month),
    capitalize: true,
  });
}

export function getDayNames(t: any, lengthOfDayNames: number = 2) {
  const dayNames = [];
  const mondayDate = moment().startOf('isoWeek');

  for (let i = 0; i <= 6; i++) {
    dayNames.push(
      t('general.weekday', {
        date: mondayDate.clone().add(i, 'd').toDate(),
        capitalize: true,
      }).substring(0, lengthOfDayNames)
    );
  }
  return dayNames;
}
