import {
  BottomTabBarProps,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import DashboardScreen from '../../screens/gated/DashboardScreen';
import ProfileScreen from '../../screens/gated/ProfileScreen';
import { HomeLogo, UserLogo } from '../../components/MkIcons';
import { BottomTabParamList } from '../../types/Navigation';
import { motkraftTheme } from '../../theme';
import { HStack, Pressable, Text, useColorMode } from 'native-base';
import Animated, {
  SharedValue,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { Dimensions, Platform } from 'react-native';
import { useEffect } from 'react';

type BottomTabAnimationParams = {
  index: number;
  dashboardLabelOpacity: SharedValue<number>;
  profileLabelOpacity: SharedValue<number>;
};
import { useTranslation } from 'react-i18next';

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

const BottomNavigator = () => {
  return (
    <BottomTab.Navigator
      initialRouteName="dashboard"
      tabBar={(props) => <MkBottomNavBar {...props} />}
      screenOptions={() => ({
        headerShown: false,
      })}
    >
      <BottomTab.Screen component={DashboardScreen} name="dashboard" />
      <BottomTab.Screen component={ProfileScreen} name="profile" />
    </BottomTab.Navigator>
  );
};

const MkBottomNavBar = ({ state, navigation }: BottomTabBarProps) => {
  const ACTIVE_LABEL_OPACITY = 1;
  const INACTIVE_LABEL_OPACITY = 0;
  const ACTIVE_ICON_TRANSLATE = -10;
  const INACTIVE_ICON_TRANSLATE = 25;

  const { colorMode } = useColorMode();

  const dashboardLabelOpacity = useSharedValue(ACTIVE_LABEL_OPACITY);
  const profileLabelOpacity = useSharedValue(INACTIVE_LABEL_OPACITY);

  const { height, width } = Dimensions.get('window');
  const hasNotch = height / width > 2;

  const { t } = useTranslation();

  const animatedDashboardLabelStyle = useAnimatedStyle(() => {
    return {
      opacity: dashboardLabelOpacity.value,
    };
  });

  const animatedProfileLabelStyle = useAnimatedStyle(() => {
    return {
      opacity: profileLabelOpacity.value,
    };
  });

  const animatedDashboardIconStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: interpolate(
            dashboardLabelOpacity.value,
            [INACTIVE_LABEL_OPACITY, ACTIVE_LABEL_OPACITY],
            [INACTIVE_ICON_TRANSLATE, ACTIVE_ICON_TRANSLATE]
          ),
        },
      ],
    };
  });

  const animatedProfileIconStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: interpolate(
            profileLabelOpacity.value,
            [INACTIVE_LABEL_OPACITY, ACTIVE_LABEL_OPACITY],
            [INACTIVE_ICON_TRANSLATE, ACTIVE_ICON_TRANSLATE]
          ),
        },
      ],
    };
  });

  const handleBottomTabAnimation = ({
    index,
    dashboardLabelOpacity,
    profileLabelOpacity,
  }: BottomTabAnimationParams) => {
    if (index === 1) {
      dashboardLabelOpacity.value = withTiming(INACTIVE_LABEL_OPACITY);
      profileLabelOpacity.value = withTiming(ACTIVE_LABEL_OPACITY);
    } else {
      dashboardLabelOpacity.value = withTiming(ACTIVE_LABEL_OPACITY);
      profileLabelOpacity.value = withTiming(INACTIVE_LABEL_OPACITY);
    }
  };

  useEffect(() => {
    handleBottomTabAnimation({
      index: state.index,
      dashboardLabelOpacity,
      profileLabelOpacity,
    });
  }, [state.index]);

  return (
    <HStack
      bgColor={colorMode === 'light' ? 'white' : 'black'}
      pt={1}
      pb={5}
      px={20}
      mt={Platform.OS === 'ios' && hasNotch ? -8 : undefined}
    >
      {state.routes.map((route: any, index: number) => {
        const isFocused = state.index === index;
        const iconColor = isFocused
          ? motkraftTheme.colors.primary
          : colorMode === 'dark'
          ? 'white'
          : 'black';

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          });
          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
            handleBottomTabAnimation({
              index,
              dashboardLabelOpacity,
              profileLabelOpacity,
            });
          }
        };

        const onLongPress = () => {
          navigation.emit({
            type: 'tabLongPress',
            target: route.key,
          });
        };

        return (
          <Pressable
            key={route.key}
            onPress={onPress}
            onLongPress={onLongPress}
            p={4}
            flex={1}
          >
            <HStack justifyContent={'center'}>
              {route.name === 'dashboard' ? (
                <Animated.View style={[animatedDashboardIconStyle]}>
                  <HomeLogo color={iconColor} />
                </Animated.View>
              ) : (
                <Animated.View style={[animatedProfileIconStyle]}>
                  <UserLogo color={iconColor} />
                </Animated.View>
              )}
              <Animated.View
                style={[
                  route.name === 'dashboard'
                    ? animatedDashboardLabelStyle
                    : animatedProfileLabelStyle,
                ]}
              >
                <Text fontSize={16}>
                  {route.name === 'dashboard'
                    ? t('bottomNavigator.home')
                    : t('bottomNavigator.profile')}
                </Text>
              </Animated.View>
            </HStack>
          </Pressable>
        );
      })}
    </HStack>
  );
};

export default BottomNavigator;
