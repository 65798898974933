import { Skeleton, VStack } from 'native-base';
import MkBackground from '../../components/basic/MkBackground';
import { MkLogoTitle } from '../../components/MkIcons';

type LoadingSkeletonProps = {
  isBackEnabled?: boolean;
};

const LoadingSkeleton = ({ isBackEnabled = true }: LoadingSkeletonProps) => {
  return (
    <MkBackground pt={isBackEnabled ? 66 : 9} pb={22}>
      <VStack flex={1}>
        <MkLogoTitle />
        <Skeleton flex={2} my={8} />
        <Skeleton flex={1} mb={3} />
        <Skeleton flex={3} mb={8} />
        <VStack space={2} flex={3}>
          <Skeleton flex={1} />
          <Skeleton flex={1} />
          <Skeleton flex={1} />
        </VStack>
      </VStack>
    </MkBackground>
  );
};

export default LoadingSkeleton;
