import { createSlice } from '@reduxjs/toolkit';
import { GraphIntervals } from '../../components/basic/MkChartSwitch';

type GraphSettingsStateType = {
  priceChartMode: GraphIntervals;
  consumptionChartMode: GraphIntervals;
};

const initialState: GraphSettingsStateType = {
  priceChartMode: GraphIntervals.lineChart,
  consumptionChartMode: GraphIntervals.lineChart,
};

export const graphSettingsSlice = createSlice({
  name: 'graphSettings',
  initialState: initialState,
  reducers: {
    swapPriceChartMode: (state) => {
      return {
        priceChartMode:
          state.priceChartMode === GraphIntervals.lineChart
            ? GraphIntervals.barChart
            : GraphIntervals.lineChart,
        consumptionChartMode: state.consumptionChartMode,
      };
    },
    swapConsumptionChartMode: (state) => {
      return {
        priceChartMode: state.priceChartMode,
        consumptionChartMode:
          state.consumptionChartMode === GraphIntervals.lineChart
            ? GraphIntervals.barChart
            : GraphIntervals.lineChart,
      };
    },
  },
});

export const { swapPriceChartMode, swapConsumptionChartMode } =
  graphSettingsSlice.actions;

export default graphSettingsSlice.reducer;
