import { Text, TextInput } from 'react-native';
import registerRootComponent from 'expo/build/launch/registerRootComponent';
import App from './App';

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.allowFontScaling = false;

TextInput.defaultProps = TextInput.defaultProps || {};
TextInput.defaultProps.allowFontScaling = false;

registerRootComponent(App);
