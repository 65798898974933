import { Spinner, View } from 'native-base';
import MkBackground from './MkBackground';

type MkLoaderProps = {
  renderMkBackground?: boolean;
};

const MkLoader = ({ renderMkBackground = true }: MkLoaderProps) => {
  return renderMkBackground ? (
    <MkBackground darkMode justifyContent={'center'} alignItems={'center'}>
      <Spinner size={'lg'} />
    </MkBackground>
  ) : (
    <View flex={1} justifyContent={'center'} alignItems={'center'}>
      <Spinner size={'lg'} />
    </View>
  );
};

export default MkLoader;
