import { apiSlice } from './apiSlice';

export type AppVersionsFeatureSpecificationType = {
  supportedVersions: string[];
  encouragementVersions: string[];
};

type FeatureName = 'appVersions' | 'carouselCards';

export type FeatureType = {
  id: string;
  isEnabled: boolean;
  featureSpecification: string;
  featureName: FeatureName;
  applyToNewUsers: boolean;
};

export const featureToggleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    featureToggle: builder.query<FeatureType[], void>({
      query: () => ({
        url: '/v2/features',
        method: 'get',
      }),
    }),
  }),
});

export const { useFeatureToggleQuery, useLazyFeatureToggleQuery } =
  featureToggleApiSlice;
