export enum ConsumptionInterval {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}
export type ConsumptionIntervalResponse = {
  [key in ConsumptionInterval]: string[];
};

type Consumption = {
  startTime: string;
  energy: number;
  cost: number;
};
export type ConsumptionResponse = {
  values: Consumption[];
};
