import {
  Box,
  Button,
  FormControl,
  HStack,
  Input,
  KeyboardAvoidingView,
  ScrollView,
  Text,
  VStack,
  View,
} from 'native-base';
import { Platform, TouchableOpacity } from 'react-native';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CheckMarkIcon, MKLogoLong, XIcon } from '../../components/MkIcons';
import MkBackground from '../../components/basic/MkBackground';
import MkLoader from '../../components/basic/MkLoader';
import { useAuthentication } from '../../hooks/useAuthentication';

const LoginScreen = () => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [remainingTime, setRemainingTime] = useState({
    targetTime: moment(new Date()),
    remainingTime: 0,
  });
  const [phoneNumberErrors, setPhoneNumberErrors] = useState({
    message: '',
  });
  const [otpErrors, setotpErrors] = useState({
    message: '',
  });
  const { loading, signInWithSMS, verifyOTP, signWithEmailPassword } =
    useAuthentication();
  const [inputError, setInputError] = useState(false);

  const gotoLoginScreen = () => {
    setShowOtpScreen(false);
    setRemainingTime((remainingTime: any) => {
      return {
        ...remainingTime,
        targetTime: moment(new Date()),
        remainingTime: 0,
      };
    });
  };

  useEffect(() => {
    let interval: any = null;
    interval = setInterval(() => {
      setRemainingTime((prevTime: any) => {
        const now = moment(new Date());
        const duration = moment.duration(remainingTime.targetTime.diff(now));

        if (duration.asSeconds() <= 0) {
          clearInterval(interval);
          return {
            ...prevTime,
            remainingTime: 0,
            targetTime: moment(new Date()),
          };
        }

        return {
          ...prevTime,
          remainingTime: duration.asSeconds().toFixed(0),
        };
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [remainingTime.remainingTime]);

  const memoRamainTime = useMemo(() => {
    return remainingTime;
  }, [remainingTime]);
  const filterOtp = useMemo(() => {
    return otp.replace(/[^0-9]/g, '');
  }, [otp]);

  const filterPhoneNumber = useMemo(() => {
    return phoneNumber.replace(/[^0-9]/g, '');
  }, [phoneNumber]);
  const minutes = Math.floor(memoRamainTime.remainingTime / 60);
  const seconds = memoRamainTime.remainingTime % 60;

  const sendOtp = async () => {
    const regExp = /^[4|9]\d{7}$/;
    const isMobileNumberValid = filterPhoneNumber.match(regExp);
    try {
      if (filterPhoneNumber === '88888888') {
        signWithEmailPassword();
        return;
      }
      if (isMobileNumberValid === null) {
        setPhoneNumberErrors({
          ...phoneNumberErrors,
          message: t('loginScreen.phoneNumberInvalid'),
        });
        return;
      }
      await signInWithSMS(filterPhoneNumber, '47');
      setotpErrors({
        ...otpErrors,
        message: '',
      });
      setRemainingTime((remainingTime: any) => {
        return {
          ...remainingTime,
          targetTime: moment().add(60, 'seconds'),
          remainingTime: 60,
        };
      });
      setPhoneNumberErrors({
        message: '',
      });
      setOtp('');
      setShowOtpScreen(true);
    } catch (err: any) {
      console.error(JSON.stringify(err));
      setShowOtpScreen(false);
      setPhoneNumberErrors({
        ...phoneNumberErrors,
        message: t('loginScreen.phoneNumberInvalid'),
      });
    }
  };

  const login = async () => {
    try {
      if (!filterOtp || filterOtp.length !== 6) {
        throw new Error('Please enter valid OTP!!');
      }
      await verifyOTP('+' + '47' + filterPhoneNumber, filterOtp);
    } catch (err: any) {
      if (err?.name == 'too_many_attempts') {
        setotpErrors({
          ...otpErrors,
          message: t('loginScreen.tooManyAttempt'),
        });
        return;
      }
      setotpErrors({
        ...otpErrors,
        message: t('loginScreen.otpInvalid'),
      });
    }
  };

  const getValidationIcon = () => {
    return filterPhoneNumber.length >= 8 ? (
      <View pr={4}>
        {inputError || !(filterPhoneNumber.length === 8) ? (
          <XIcon color={'red'} />
        ) : (
          <CheckMarkIcon />
        )}
      </View>
    ) : (
      <></>
    );
  };

  const handleInputChange = (val: string) => {
    const trimmedVal = val.replace(/(\+47|\D)/g, '');
    setInputError(false);
    if (
      trimmedVal.length >= 8 &&
      trimmedVal !== '88888888' &&
      !trimmedVal.match(/^[94]/)
    ) {
      setInputError(true);
    }
    setPhoneNumber(trimmedVal);
  };

  useEffect(() => {
    if (otp.length === 6) {
      login();
    }
  }, [otp]);

  if (loading) return <MkLoader />;

  return (
    <MkBackground darkMode>
      <ScrollView
        contentContainerStyle={{ flex: 1 }}
        keyboardShouldPersistTaps={'handled'}
      >
        <KeyboardAvoidingView
          flex={1}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        >
          <View justifyContent={'center'} alignItems={'center'} flex={2}>
            <MKLogoLong />
          </View>
          <VStack space={5} flex={3} px={12}>
            {showOtpScreen ? (
              <>
                <Text
                  fontFamily={'readerPro'}
                  fontSize={16}
                  textAlign={'center'}
                  color={'light'}
                >
                  {t('loginScreen.otpInputLabel')}
                </Text>
                <HStack mb={-3}>
                  <Text color={'white'}>
                    +47 {filterPhoneNumber} (Feil nr?{' '}
                  </Text>
                  <TouchableOpacity
                    onPress={gotoLoginScreen}
                    activeOpacity={0.2}
                  >
                    <Text color={'primary'}>Klikk her</Text>
                  </TouchableOpacity>
                  <Text color={'white'}>)</Text>
                </HStack>
                <Input
                  autoFocus
                  keyboardType={
                    Platform.OS === 'android' ? 'numeric' : 'number-pad'
                  }
                  value={filterOtp}
                  bgColor={'white'}
                  placeholder="SMS kode"
                  color={'black'}
                  fontFamily={'readerRegular'}
                  placeholderTextColor={'bottomNavBorder'}
                  fontSize={18}
                  onChangeText={(otp) => {
                    setOtp(otp);
                    setotpErrors({ message: '' });
                  }}
                  py={4}
                  textContentType="oneTimeCode"
                  autoComplete="sms-otp"
                  maxLength={6}
                />
                <VStack mt={-3}>
                  {Boolean(remainingTime.remainingTime) && (
                    <Text textAlign={'left'} color={'white'}>
                      {t('loginScreen.resetTimmer', {
                        time: `${String(minutes).padStart(2, '0')}:${String(
                          seconds
                        ).padStart(2, '0')}`,
                      })}
                    </Text>
                  )}
                  <Text color={'expiredPillText'}>{otpErrors.message}</Text>
                </VStack>
                <VStack
                  justifyContent={'center'}
                  alignItems={'center'}
                  space={4}
                  mt={10}
                >
                  {!Boolean(remainingTime.remainingTime) && (
                    <Button
                      bg={'dark'}
                      w={[255, 300]}
                      rounded={'full'}
                      onPress={sendOtp}
                      borderColor={'white'}
                      borderWidth={1}
                    >
                      <Text
                        fontFamily={'readerPro'}
                        fontSize={18}
                        color={'white'}
                      >
                        {t('loginScreen.resentOtpButton')}
                      </Text>
                    </Button>
                  )}

                  <Button
                    bg={'primary'}
                    w={[255, 300]}
                    rounded={'full'}
                    onPress={login}
                  >
                    <Text fontFamily={'readerPro'} fontSize={18} color={'dark'}>
                      {t('loginScreen.login')}
                    </Text>
                  </Button>
                </VStack>
              </>
            ) : (
              <>
                <Text fontFamily={'readerPro'} fontSize={16} color={'light'}>
                  {t('loginScreen.loginInputLabel') + ' '}
                </Text>
                <HStack space={2}>
                  <Box
                    bgColor={'white'}
                    justifyContent={'center'}
                    borderRadius={3}
                  >
                    <Text
                      color={'dark'}
                      fontFamily={'readerRegular'}
                      px={3}
                      fontSize={18}
                    >
                      +{47}
                    </Text>
                  </Box>
                  <FormControl isRequired flex={1}>
                    <Input
                      autoFocus={true}
                      maxLength={14}
                      keyboardType={
                        Platform.OS === 'android' ? 'phone-pad' : 'number-pad'
                      }
                      value={filterPhoneNumber}
                      bgColor={'white'}
                      placeholder="Ditt nummer"
                      color={'black'}
                      fontFamily={'readerRegular'}
                      placeholderTextColor={'bottomNavBorder'}
                      fontSize={18}
                      py={4}
                      onChangeText={handleInputChange}
                      textContentType="telephoneNumber"
                      InputRightElement={getValidationIcon()}
                    />
                  </FormControl>
                </HStack>
                <Button
                  bg={'primary'}
                  variant={'solid'}
                  rounded={'full'}
                  onPress={sendOtp}
                  isDisabled={inputError || !(filterPhoneNumber.length === 8)}
                >
                  <Text fontFamily={'readerPro'} fontSize={18} color={'dark'}>
                    {t('loginScreen.loginButton')}
                  </Text>
                </Button>
              </>
            )}
          </VStack>
        </KeyboardAvoidingView>
      </ScrollView>
    </MkBackground>
  );
};

export default LoginScreen;
