import { createSlice } from '@reduxjs/toolkit';

export enum YAxisMode {
  energy = 'kWt',
  cost = 'Kr',
}

type YAxisModeStateType = {
  yAxisMode: YAxisMode;
};

const initialState: YAxisModeStateType = {
  yAxisMode: YAxisMode.energy,
};

export const consumptionYAxisModeSlice = createSlice({
  name: 'consumptionYAxisMode',
  initialState: initialState,
  reducers: {
    swapYAxisMode: (state) => {
      return {
        yAxisMode:
          state.yAxisMode === YAxisMode.energy
            ? YAxisMode.cost
            : YAxisMode.energy,
      };
    },
  },
});

export const { swapYAxisMode } = consumptionYAxisModeSlice.actions;

export default consumptionYAxisModeSlice.reducer;
