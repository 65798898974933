import moment from 'moment';
import { View, VStack } from 'native-base';
import { useInvoiceListQuery } from '../../redux/slices/invoiceApiSlice';
import { InvoiceListItem } from '../invoice/InvoiceListItem';

type InvoiceListProps = {
  limit: number;
};

const DashboardInvoiceList = ({ limit }: InvoiceListProps) => {
  const { data: dInvoiceList } = useInvoiceListQuery(limit);
  const invoices = dInvoiceList?.invoices
    .slice()
    .sort((a, b) =>
      moment({ year: b.year, month: b.month - 1 }).diff(
        moment({ year: a.year, month: a.month - 1 })
      )
    );

  return (
    <VStack space={4}>
      {invoices && invoices.length > 0
        ? invoices.map((item, index) => {
            return (
              <View key={index}>
                <InvoiceListItem invoice={item} />
              </View>
            );
          })
        : null}
    </VStack>
  );
};

export default DashboardInvoiceList;
