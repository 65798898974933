import { HStack, Pressable, ScrollView, Text, View } from 'native-base';
import { useTranslation } from 'react-i18next';
import MkBackground from '../../components/basic/MkBackground';
import OnboardingMaps from '../../components/dashboardWidgets/OnboardingMaps';
import { useCallback, useEffect, useRef, useState } from 'react';
import { motkraftTheme } from '../../theme';
import { getRegionsDropdownData } from '../../utils/functions';
import { RegionDropDownData } from './PowerPriceScreen';
import { usePowerPricesByRegionQuery } from '../../redux/slices/powerPriceApiSlice';
import ErrorWidget from '../miscellaneous/ErrorWidget';
import { AppState, RefreshControl } from 'react-native';
import LoadingSkeleton from '../miscellaneous/LoadingSkeleton';
import moment from 'moment';
import MkOnboardingWidget from '../../components/basic/MkOnboardingWidget';
import { useFocusEffect } from '@react-navigation/native';
import useProgressViewOffset from '../../hooks/useProgressViewOffset';
import MKStatusWidget from '../../components/basic/MKStatusWidget';
import {
  StatusIconFirst,
  StatusIconSecond,
  StatusIconThird,
  StatusIconFourth,
  MkLogoTitle,
} from '../../components/MkIcons';
import { useOnboardingStatusDataQuery } from '../../redux/slices/OnboardingStatusSlice';
import { OnboardingStatus } from '../../types/OnboardingStatus';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import MkBottomSheet from '../../components/basic/MkBottomSheet';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRegion } from '../../redux/slices/selectedRegionSlice';
import { saveRegionIds } from '../../redux/slices/regionSlice';
import { UseTrackEventType } from '../../../mixpanel.native';
import { Platform } from 'react-native';

let useTrackEvent: UseTrackEventType = null;
if (Platform.OS !== 'web') {
  import('../../../mixpanel.native').then((mixP) => {
    useTrackEvent = mixP.useTrackEvent;
  });
} else {
  console.warn('Mixpanel is disabled for web');
}

const REGION_MAP = {
  NO1: 0,
  NO2: 1,
  NO3: 2,
  NO4: 3,
  NO5: 4,
};

const OnboardingScreen = () => {
  const { t } = useTranslation();
  const [regions, setRegions] = useState(getRegionsDropdownData());
  const selectedRegion = useSelector(
    (state: RootState) => state.selectedRegion
  );
  const [mapColours, setMapColours] = useState<string[]>([]);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [offset, setOffset] = useState<string>(moment.tz.guess(true));
  const progressViewOffset = useProgressViewOffset();
  const bottomSheetModalRef = useRef<BottomSheetModal>(null);
  const [selectedRegionState, setSelectedRegionState] =
    useState<RegionDropDownData>();
  const [regionId, setRegionId] = useState<string>('');
  const storedRegionIds = useSelector((state: RootState) => state.regions);
  const dispatch = useDispatch();
  const trackSelectedRegion = useTrackEvent
    ? useTrackEvent('Price Zone')
    : null;

  const {
    data: powerPrices,
    error: powerPricesError,
    isLoading: pricesLoading,
    isFetching: pricesFetching,
    refetch: pricesRefetch,
  } = usePowerPricesByRegionQuery(
    {
      region: regionId,
      offset,
    },
    {
      skip: regionId === '' || !offset,
    }
  );

  const {
    data: onBoardingStatusData,
    error: onBoardingStatusError,
    isLoading: onBoardingStatusLoading,
    isFetching: onBoardingStatusFetching,
    refetch: onBoardingRefetch,
  } = useOnboardingStatusDataQuery();

  useFocusEffect(
    useCallback(() => {
      if (powerPrices || powerPricesError) {
        setOffset(moment.tz.guess(true));
        pricesRefetch();
        onBoardingRefetch();
      }
    }, [moment().hour()])
  );

  useEffect(() => {
    if (selectedRegion && selectedRegion.value)
      setRegionId(selectedRegion.value);
    if (trackSelectedRegion) {
      trackSelectedRegion({
        'Selected Price Zone': selectedRegion.value,
      });
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (storedRegionIds.length > 0) {
      const sortedRegions = regions.sort(
        (a, b) =>
          storedRegionIds.indexOf(a.value) - storedRegionIds.indexOf(b.value)
      );
      setRegions(sortedRegions);
      dispatch(
        setSelectedRegion(
          selectedRegion.value ? selectedRegion : sortedRegions[0]
        )
      );
      setRegionId(
        selectedRegion.value ? selectedRegion.value : sortedRegions[0].value
      );
    } else {
      const regionIds = regions.map((region) => region.value);
      dispatch(saveRegionIds(regionIds));
      dispatch(setSelectedRegion(regions[0]));
      setRegionId(regions[0].value);
    }
  }, [storedRegionIds]);

  const getOnboardingStatusScreen = (
    onboardingStatusData: OnboardingStatus
  ) => {
    const { processStarted, washStatus, onboardStatus } = onboardingStatusData;
    if (!processStarted && washStatus === null && onboardStatus === null) {
      return (
        <MKStatusWidget
          StatusIcon={<StatusIconFirst />}
          heading={t('status.headingFirst')}
          description={t('status.descriptionFirst')}
          showButton={false}
        />
      );
    } else if (
      processStarted &&
      washStatus === 'fail' &&
      onboardStatus === null
    ) {
      return (
        <MKStatusWidget
          StatusIcon={<StatusIconThird />}
          heading={t('status.headingThird')}
          description={t('status.descriptionThird')}
          showButton={true}
        />
      );
    } else if (processStarted && washStatus === 'success') {
      if (onboardStatus === null) {
        return (
          <MKStatusWidget
            StatusIcon={<StatusIconFirst />}
            heading={t('status.headingFirst')}
            description={t('status.descriptionFirst')}
            showButton={false}
          />
        );
      } else if (onboardStatus === 'fail' || onboardStatus === 'pending') {
        return (
          <MKStatusWidget
            StatusIcon={<StatusIconSecond />}
            heading={t('status.headingSecond')}
            description={t('status.descriptionSecond')}
            showButton={false}
          />
        );
      } else if (onboardStatus === 'success') {
        return (
          <MKStatusWidget
            StatusIcon={<StatusIconFourth />}
            heading={t('status.headingFourth')}
            description={t('status.descriptionFourth')}
            showButton={false}
          />
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (powerPrices) {
      // lastFetchedMoment.current = moment();
      powerPrices.prices.today.forEach((price: any) => {
        if (moment(price.timestamp).hour() === moment().hour()) {
          setCurrentPrice(price.value);
        }
      });
    }
  }, [powerPrices, moment().hour()]);

  useEffect(() => {
    const colours = [];
    for (let i = 0; i < 5; i++) {
      colours.push(
        i === REGION_MAP[selectedRegion.value]
          ? motkraftTheme.colors.primary
          : motkraftTheme.colors.carouselPaginationDefault
      );
    }
    setMapColours(colours);
  }, [selectedRegion.value]);

  const closeActionSheet = () => {
    bottomSheetModalRef.current?.dismiss();
  };

  const dismissBottomSheetRegions = () => {
    if (selectedRegionState !== selectedRegion) {
      setRegionId(selectedRegion.value);
      setOffset(moment.tz.guess(true));
      pricesRefetch();
    }
  };

  if (
    pricesLoading ||
    pricesFetching ||
    onBoardingStatusLoading ||
    onBoardingStatusFetching
  )
    return <LoadingSkeleton isBackEnabled={false} />;

  return (
    <MkBackground pt={9}>
      <HStack justifyContent={'space-between'}>
        <MkLogoTitle />
        <Pressable
          onPress={() => {
            setSelectedRegionState(selectedRegion);
            bottomSheetModalRef.current?.present();
          }}
          hitSlop={20}
        >
          <Text
            fontSize={18}
            fontFamily={'readerPro'}
            numberOfLines={1}
            _dark={{ color: 'primary' }}
            _light={{ color: 'lightText' }}
          >
            {t('dashboard.onboarding.select_regions_text')}
          </Text>
        </Pressable>
      </HStack>
      <ScrollView
        mt={2}
        pt={33}
        refreshControl={
          <RefreshControl
            refreshing={pricesFetching || onBoardingStatusFetching}
            onRefresh={() => {
              setOffset(moment.tz.guess(true));
              pricesRefetch();
              onBoardingRefetch();
            }}
            progressViewOffset={progressViewOffset}
          />
        }
      >
        {powerPricesError ? (
          <View>
            <ErrorWidget />
          </View>
        ) : (
          <View>
            <MkBottomSheet
              isCustomer={false}
              bottomSheetModalRef={bottomSheetModalRef}
              closeFunction={closeActionSheet}
              data={regions}
              setData={setRegions}
              dismissFunction={dismissBottomSheetRegions}
            />
            <OnboardingMaps
              mapColours={mapColours}
              selectedRegion={selectedRegion.value}
              currentPrice={currentPrice}
            />
          </View>
        )}
        {onBoardingStatusError ? (
          <MkOnboardingWidget />
        ) : (
          onBoardingStatusData &&
          getOnboardingStatusScreen(onBoardingStatusData)
        )}
      </ScrollView>
    </MkBackground>
  );
};

export default OnboardingScreen;
