import { Box, IBoxProps } from 'native-base';

const MkCard = ({ children, ...props }: IBoxProps) => {
  return (
    <Box
      _dark={{
        bgColor: 'cardBgDark',
      }}
      _light={{
        bgColor: 'white',
      }}
      borderRadius={12.5}
      {...props}
    >
      {children}
    </Box>
  );
};

export default MkCard;
