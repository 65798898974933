import { apiSlice } from './apiSlice';

type CustomerExistResponseType = {
  exists: boolean;
};

export const customerExistApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    customerExist: builder.query<CustomerExistResponseType, void>({
      query: () => ({
        url: '/v2/customerExists',
        method: 'get',
      }),
    }),
  }),
});

export const { useCustomerExistQuery } = customerExistApiSlice;
