import Constants from 'expo-constants';
import { Platform } from 'react-native';

export interface AppConfig {
  backendUrl: string;
  onboardingUrl: string;
  clientId: string;
  clientSecret: string;
  clientDomain: string;
  clientAudience: string,
  vippsConnectionName:string,
  idServerUrl: string;
  buildNumber: string;
  sentryDsn: string;
  versionNumber: string;
  mixpanelProjectToken: string;
  playStoreUrl: string;
  appStoreUrl: string;
  supportedCardVersion: string;
}

const getValueFromManifest = (key: string) => {
  if (!Constants.manifest) {
    throw 'Manifest is not present';
  }

  if (!Constants.manifest.extra) {
    throw 'Manifest extras are not present';
  }

  const value = Constants.manifest.extra[key];

  if (!value) {
    throw `Key '${key}' is not present in manifest`;
  }

  return value;
};

const DEFAULT_BUILD_NUMBER = '0';

const getBuildNumber = (): string => {
  if (!Constants.manifest) {
    throw 'Manifest is not present';
  }

  const platform = Platform.OS;
  const platformOpts = Constants.manifest[platform];

  if (!platformOpts) {
    return DEFAULT_BUILD_NUMBER;
  }

  switch (platform) {
    case 'ios':
      return platformOpts['buildNumber'].toString();
    case 'android':
      return platformOpts['versionCode'].toString();
    default:
      return DEFAULT_BUILD_NUMBER;
  }
};

export const reactAppConfig: AppConfig = {
  backendUrl: getValueFromManifest('BASE_URL'),
  onboardingUrl: getValueFromManifest('ONBOARDING_URL'),
  clientId: getValueFromManifest('CLIENT_ID'),
  clientSecret: getValueFromManifest('CLIENT_SECRET'),
  clientDomain: getValueFromManifest('CLIENT_DOMAIN'),
  clientAudience: getValueFromManifest('CLIENT_AUDIENCE'),
  vippsConnectionName:getValueFromManifest('VIPPS_CONNECTION_NAME'),
  idServerUrl: getValueFromManifest('ID_SERVER_URL'),
  buildNumber: getBuildNumber(),
  sentryDsn: getValueFromManifest('SENTRY_DSN'),
  versionNumber: Constants.manifest?.version || '0.0.0',
  mixpanelProjectToken: getValueFromManifest('MIXPANEL_PROJECT_TOKEN'),
  playStoreUrl: getValueFromManifest('PLAY_STORE_URL'),
  appStoreUrl: getValueFromManifest('APP_STORE_URL'),
  supportedCardVersion: getValueFromManifest('SUPPORTED_CARD_VERSION'),
};
