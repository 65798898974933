import { Text, VStack, View } from 'native-base';
import { useTranslation } from 'react-i18next';
import MkCard from './MkCard';
import { Platform } from 'react-native';
import { Suspense, lazy } from 'react';
import MkLoader from './MkLoader';

const StatusWidgetChatButton = lazy(
  () => import('../chatSupport/StatusWidgetChatButton')
);

interface MKStatusProps {
  StatusIcon: JSX.Element;
  heading: string;
  description: string;
  showButton: boolean;
}

const MKStatusWidget = ({
  StatusIcon,
  heading,
  description,
  showButton,
}: MKStatusProps) => {
  const { t } = useTranslation();
  const chatButtonText = t('status.buttonTitle');

  return (
    <MkCard my={14}>
      <VStack alignItems={'center'} pt={28} pb={39}>
        {StatusIcon}
        <Text
          mt={23}
          mx={38}
          textAlign={'center'}
          fontFamily={'readerBold'}
          fontSize={18}
        >
          {heading}
        </Text>
        <Text
          mt={23}
          mx={19}
          textAlign={'center'}
          fontFamily={'readerRegular'}
          fontSize={16}
          lineHeight={23}
        >
          {description}
        </Text>
        {showButton && (
          <View mt={25}>
            {Platform.OS != 'web' ? (
              <Suspense fallback={<MkLoader />}>
                <StatusWidgetChatButton chatButtonText={chatButtonText} />
              </Suspense>
            ) : (
              <Text>Not supported</Text>
            )}
          </View>
        )}
      </VStack>
    </MkCard>
  );
};

export default MKStatusWidget;
