import { createSlice } from '@reduxjs/toolkit';

export const regionSlice = createSlice({
  name: 'regions',
  initialState: [] as string[],
  reducers: {
    saveRegionIds: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { saveRegionIds } = regionSlice.actions;

export default regionSlice.reducer;
