import { HStack, Pressable, Text, VStack, View } from 'native-base';
import MkCard from '../basic/MkCard';
import { ForwardArrow } from '../MkIcons';

type ProfileItemButtonProps = {
  iconName: any;
  title: string;
  description: string;
  onPress: () => void;
};

const ProfileItemButton = ({
  iconName,
  title,
  description,
  onPress,
}: ProfileItemButtonProps) => {
  return (
    <MkCard>
      <Pressable onPress={onPress}>
        <HStack px={17} py={17} alignItems={'center'} space={3}>
          <View flexDirection={'row'} justifyContent={'center'} minW={27}>
            {iconName}
          </View>
          <VStack flex={1}>
            <Text fontFamily={'readerPro'} fontSize={18}>
              {title}
            </Text>
            <Text fontFamily={'readerRegular'} fontSize={16}>
              {description}
            </Text>
          </VStack>
          <ForwardArrow />
        </HStack>
      </Pressable>
    </MkCard>
  );
};

export default ProfileItemButton;
