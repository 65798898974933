import { apiSlice } from './apiSlice';
import { DashboardData } from '../../types/Dashboard';

type DashboardApiParams = {
  offset: string;
};

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    dashboardData: builder.query<DashboardData, DashboardApiParams>({
      query: ({ offset }) => ({
        url: '/v3/dashboard-data',
        params: { offset },
        method: 'get',
      }),
    }),
  }),
});

export const { useDashboardDataQuery } = dashboardApiSlice;
