import { createSlice } from '@reduxjs/toolkit';

type AppReviewStateType = {
  numberOfVisits: number;
  hasPrompted: boolean;
}

const initialState: AppReviewStateType = {
  numberOfVisits: 0,
  hasPrompted: false,
}

export const appReviewSlice = createSlice({
  name: 'appReview',
  initialState: initialState,
  reducers: {
    setNumberOfVisits: (state, action) => {
      return {
        numberOfVisits: action.payload,
        hasPrompted: state.hasPrompted
      }
    },
    setHasPrompted: (state, action) => {
      return {
        numberOfVisits: state.numberOfVisits,
        hasPrompted: action.payload
      }
    }
  },
});

export const { setNumberOfVisits, setHasPrompted } = appReviewSlice.actions;

export default appReviewSlice.reducer;
