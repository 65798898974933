import { Badge, Text, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { motkraftTheme } from '../../theme';
import { formatAsLocalNumber } from '../../utils/number-formatter';
import MkCard from '../basic/MkCard';

export type OverViewCardProps = {
  label: string;
  amount: number;
  monthYear: string;
};

const InvoiceDetailsOverview = ({
  label,
  amount,
  monthYear,
}: OverViewCardProps) => {
  const { t } = useTranslation();

  let textColor = '';
  let badgeBgColor = '';

  if (label === t(`profile.invoicesScreen.invoiceDetails.textPaid`)) {
    textColor = motkraftTheme.colors.cardBgDark;
    badgeBgColor = motkraftTheme.colors.paidPillBg;
  } else if (label === t(`profile.invoicesScreen.invoiceDetails.textUnpaid`)) {
    textColor = motkraftTheme.colors.unpaidPillText;
    badgeBgColor = motkraftTheme.colors.unpaidPillBg;
  } else {
    textColor = motkraftTheme.colors.expiredPillText;
    badgeBgColor = motkraftTheme.colors.expiredPillBg;
  }
  return (
    <MkCard>
      <VStack alignItems={'center'} space={4} mt={19} mb={21}>
        <Badge borderRadius={25} px={18.0} py={1.5} bgColor={badgeBgColor}>
          <Text color={textColor} fontFamily={'readerBold'} fontSize={14}>
            {label}
          </Text>
        </Badge>
        <Text mt={28} fontFamily={'readerBold'} color={'primary'} fontSize={28}>
          {formatAsLocalNumber(amount) + ' kr'}
        </Text>
        <Text fontFamily={'readerRegular'} fontSize={16}>
          {monthYear}
        </Text>
      </VStack>
    </MkCard>
  );
};

export default InvoiceDetailsOverview;
