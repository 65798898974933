import { createSlice } from '@reduxjs/toolkit';
import { RegionDropDownData } from '../../screens/gated/PowerPriceScreen';

export const selectedRegionSlice = createSlice({
  name: 'selectedRegion',
  initialState: {} as RegionDropDownData,
  reducers: {
    setSelectedRegion: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { setSelectedRegion } = selectedRegionSlice.actions;

export default selectedRegionSlice.reducer;
