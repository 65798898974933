import { HStack, Pressable, Text, View, VStack } from 'native-base';
import MkCard from '../basic/MkCard';
import { useTranslation } from 'react-i18next';
import { formatAsLocalNumber } from '../../utils/number-formatter';
import { monthToDate } from '../../utils/date';
import { Consumption } from '../../types/Dashboard';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../../types/Navigation';
import { ForwardArrow } from '../MkIcons';
import { setNumberOfVisits } from '../../redux/slices/appReviewSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const PowerConsumption = ({ energy, cost, month }: Consumption) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const numberOfVisits = useSelector(
    (state: RootState) => state.appReview.numberOfVisits
  );
  const dispatch = useDispatch();

  const handleWidgetClick = () => {
    dispatch(setNumberOfVisits(numberOfVisits + 1));
    navigation.navigate('gated', { screen: 'powerConsumption' });
  };

  return (
    <Pressable onPress={handleWidgetClick}>
      <MkCard py={17} pl={13} pr={17}>
        <VStack space={3}>
          <HStack justifyContent={'space-between'} alignItems={'center'}>
            <Text
              flex={1}
              fontFamily={'readerPro'}
              fontSize={16}
              _dark={{ color: 'primary' }}
            >
              {t('consumptionData.title')}
            </Text>
            <Text fontFamily={'readerPro'} fontSize={16} mr={19}>
              {t('dashboard.power.consumptionInMonth', {
                month: monthToDate(month),
                capitalize: true,
              })}
            </Text>
            <ForwardArrow />
          </HStack>
          <HStack pr={1} justifyContent={'space-between'} alignItems={'center'}>
            <Text fontFamily={'readerPro'} fontSize={24}>
              {t('consumptionData.used')}
            </Text>
            <Text fontFamily={'readerPro'} fontSize={24}>
              {formatAsLocalNumber(energy)} kWt
            </Text>
          </HStack>
          <HStack pr={1} justifyContent={'space-between'} alignItems={'center'}>
            <Text
              fontFamily={'readerPro'}
              fontSize={16}
              _dark={{ color: 'switchOffTrack' }}
              _light={{ color: 'lightUsage' }}
            >
              {t('consumptionData.powerCost')}
            </Text>
            <Text
              fontFamily={'readerPro'}
              fontSize={16}
              _dark={{ color: 'switchOffTrack' }}
              _light={{ color: 'lightUsage' }}
            >
              {formatAsLocalNumber(cost, 0)} kr
            </Text>
          </HStack>
        </VStack>
      </MkCard>
    </Pressable>
  );
};

export default PowerConsumption;
