import { Dimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { GraphIntervals } from '../basic/MkChartSwitch';
import { useState } from 'react';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { motkraftTheme } from '../../theme';
import {
  swapConsumptionChartMode,
  swapPriceChartMode,
} from '../../redux/slices/graphSettingsSlice';
import {
  YAxisMode,
  swapYAxisMode,
} from '../../redux/slices/consumptionYAxisModeSlice';
import { HStack, Pressable, View } from 'native-base';

export enum SwitchTypes {
  priceChart = 'priceChart',
  consumptionChart = 'consumptionChart',
  consumptionYAxis = 'consumptionYAxis',
}

type GraphSettingsSwitchProps = {
  text1: string;
  text2: string;
  switchType: SwitchTypes;
};

const GraphSettingsSwitch = ({
  text1,
  text2,
  switchType,
}: GraphSettingsSwitchProps) => {
  const bgWidth = (Dimensions.get('screen').width - 74 * 0.4) / 6;
  const priceChartMode = useSelector(
    (state: RootState) => state.graphSettings.priceChartMode
  );
  const consumptionChartMode = useSelector(
    (state: RootState) => state.graphSettings.consumptionChartMode
  );
  const consumptionYAxisMode = useSelector(
    (state: RootState) => state.consumptionYAxisMode.yAxisMode
  );

  const _selectedIndex =
    switchType === SwitchTypes.priceChart
      ? priceChartMode === GraphIntervals.lineChart
        ? 1
        : 0
      : switchType === SwitchTypes.consumptionChart
      ? consumptionChartMode === GraphIntervals.lineChart
        ? 1
        : 0
      : consumptionYAxisMode === YAxisMode.cost
      ? 1
      : 0;

  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(_selectedIndex);
  const bgTranslation = useSharedValue(selectedIndex === 0 ? 0 : bgWidth);

  const bgAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: bgTranslation.value }],
    };
  });

  const buttonOneTextStyle = useAnimatedStyle(() => {
    return {
      color: interpolateColor(
        bgTranslation.value,
        [0, bgWidth],
        ['white', motkraftTheme.colors.graphSwitchUnselectedText]
      ),
    };
  });

  const buttonTwoTextStyle = useAnimatedStyle(() => {
    return {
      color: interpolateColor(
        bgTranslation.value,
        [0, bgWidth],
        [motkraftTheme.colors.graphSwitchUnselectedText, 'white']
      ),
    };
  });

  const handleButtonPress = (index: number) => {
    setSelectedIndex(index);
    bgTranslation.value = withTiming(index === 0 ? 0 : bgWidth);
    const swapFunc =
      switchType === SwitchTypes.priceChart
        ? swapPriceChartMode
        : switchType === SwitchTypes.consumptionChart
        ? swapConsumptionChartMode
        : swapYAxisMode;
    dispatch(swapFunc());
  };

  return (
    <Pressable
      borderRadius={30}
      bgColor={'light'}
      p={1}
      onPress={() => {
        handleButtonPress(selectedIndex === 0 ? 1 : 0);
      }}
    >
      <HStack alignItems={'center'}>
        <Animated.View
          style={[
            {
              backgroundColor: motkraftTheme.colors.lightBottomSheetButtonText,
              width: '50%',
              height: '100%',
              position: 'absolute',
              borderRadius: 30,
            },
            bgAnimatedStyle,
          ]}
        />
        <Animated.Text
          style={[
            {
              fontFamily: 'readerRegular',
              fontSize: 14,
              flex: 1,
              textAlign: 'center',
              padding: 4,
            },
            buttonOneTextStyle,
          ]}
        >
          {text1}
        </Animated.Text>
        <Animated.Text
          style={[
            {
              fontFamily: 'readerRegular',
              fontSize: 14,
              flex: 1,
              textAlign: 'center',
              padding: 4,
            },
            buttonTwoTextStyle,
          ]}
        >
          {text2}
        </Animated.Text>
      </HStack>
    </Pressable>
  );
};

export default GraphSettingsSwitch;
