import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { format as formatDate, isDate, Locale } from 'date-fns';
import { enUS, nb } from 'date-fns/locale';
import translationEn from './locales/en/translationEn.json';
import translationNo from './locales/no/translationNo.json';
import { reactAppConfig } from '../config/environment';
import { Language } from './language';
import { capitalize } from '../utils/string';

export const resources = {
  [Language.EN]: {
    translation: translationEn,
  },
  [Language.NO]: {
    translation: translationNo,
  },
} as const;

const locales: Readonly<Record<string, Locale>> = {
  [Language.EN]: enUS,
  [Language.NO]: nb,
};

i18n.use(initReactI18next).init({
  resources,
  compatibilityJSON: 'v3', // eslint-disable-line
  ns: ['translation'],
  lng: 'no',
  fallbackLng: Language.EN,
  debug: false,
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
    format: (value, format, lng, options): string => {
      if (isDate(value) && lng !== undefined && format !== undefined) {
        const locale = locales[lng];
        if (!locale) {
          return value;
        }

        const formattedDate = formatDate(value, format, { locale });
        return options?.capitalize ? capitalize(formattedDate) : formattedDate;
      } else {
        return value;
      }
    },
  },
});

export { i18n };
