import { Text, VStack, View } from 'native-base';
import { motkraftTheme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { getModalTimeString } from '../../utils/date';
import LottieView from 'lottie-react-native';
import moment from 'moment';
import { useState } from 'react';
import { LayoutChangeEvent } from 'react-native';

const ElectricityPriceWait = () => {
  const { t } = useTranslation();
  const [animationParentHeight, setAnimationParentHeight] = useState(0);
  const handleAnimationParentOnLayout = (evt: LayoutChangeEvent) => {
    setAnimationParentHeight(evt.nativeEvent.layout.height);
  };

  return (
    <VStack flex={1} alignItems={'center'} justifyContent={'space-around'}>
      <VStack space={4} justifyContent={'center'} alignItems={'center'}>
        <Text
          fontSize={20}
          fontFamily={'readerBold'}
          _light={{ color: motkraftTheme.colors.onboardingButtonText }}
          textAlign={'center'}
        >
          {t('electricityPrice.noDataDisplay.title')}
        </Text>
        <Text
          fontSize={17}
          fontFamily={'readerRegular'}
          textAlign={'center'}
          px={49}
          _light={{ color: motkraftTheme.colors.onboardingButtonText }}
        >
          {t('electricityPrice.noDataDisplay.description', {
            startTime: getModalTimeString('13'),
            endTime: getModalTimeString('14'),
          })}
        </Text>
      </VStack>
      <View flex={1} onLayout={handleAnimationParentOnLayout}>
        {animationParentHeight > 0 && (
          <View
            mt={100}
            h={animationParentHeight * 0.7}
            w={animationParentHeight * 0.7}
          >
            <LottieView
              speed={2}
              source={require('../../assets/animations/graph-waiting.json')}
              autoPlay
              loop
            />
          </View>
        )}
      </View>
    </VStack>
  );
};

export default ElectricityPriceWait;
