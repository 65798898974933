import { apiSlice } from './apiSlice';
import { OnboardingStatus } from '../../types/OnboardingStatus';

export const OnboardingStatusSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        onboardingStatusData: builder.query<OnboardingStatus, void>({
            query: () => ({
                url: '/onboardStatus',
                method: 'get',
            }),
        }),
    }),
});

export const { useOnboardingStatusDataQuery } = OnboardingStatusSlice;