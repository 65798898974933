import { createSlice } from '@reduxjs/toolkit';
import { ColorMode } from 'native-base';

type ColorModeStateType = {
  colorMode: ColorMode;
};

const initialState: ColorModeStateType = {
  colorMode: 'dark',
};

export const colorModeSlice = createSlice({
  name: 'colorMode',
  initialState: initialState,
  reducers: {
    saveColorMode: (state, action) => {
      return {
        colorMode: action.payload,
      };
    },
  },
});

export const { saveColorMode } = colorModeSlice.actions;

export default colorModeSlice.reducer;
