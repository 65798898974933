import { useState, useRef, useEffect, useMemo } from 'react';
import {
  Button,
  HStack,
  Pressable,
  Text,
  useColorMode,
  View,
  VStack,
} from 'native-base';
import { Dimensions, Platform } from 'react-native';
import { SwiperFlatList } from 'react-native-swiper-flatlist';
import * as WebBrowser from 'expo-web-browser';
import { motkraftTheme } from '../../theme';
import { XIcon } from '../MkIcons';
import { SwiperFlatListRefProps } from 'react-native-swiper-flatlist/src/components/SwiperFlatList/SwiperFlatListProps';
import { useDispatch, useSelector } from 'react-redux';
import { saveDeletedCarousels } from '../../redux/slices/removedCarouselSlice';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import { reactAppConfig } from '../../config/environment';
import {
  AppVersionsFeatureSpecificationType,
  useFeatureToggleQuery,
} from '../../redux/slices/featureToggleApiSlice';
import { i18n } from '../../i18n/i18n-config';
import MkLoader from '../basic/MkLoader';
import { z } from 'zod';

const LocaleSchema = z.object({
  title: z.string(),
  desc: z.string(),
  buttonText: z.string(),
  buttonLink: z.string(),
});

const CarouselCardSchema = z.object({
  id: z.string(),
  cardVersion: z.number(),
  locales: z.object({
    no: LocaleSchema,
    en: LocaleSchema,
  }),
});

type CarouselItemType = z.infer<typeof CarouselCardSchema>;

type CarouselItemProps = z.infer<typeof LocaleSchema> & {
  id: string;
  removeCarouselItem: (id: string) => void;
};

const MkCarousel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const removedIds = useSelector((state: RootState) => state.carosals);
  const scrollRef = useRef<SwiperFlatListRefProps>(null);
  const currentLocale = i18n.language as keyof {
    en: z.infer<typeof LocaleSchema>;
    no: z.infer<typeof LocaleSchema>;
  };
  const {
    data: features,
    isLoading: featuresLoading,
    isFetching: featuresFetching,
  } = useFeatureToggleQuery();
  const [carouselData, setCarouselData] = useState([] as CarouselItemType[]);

  const encouragementCard: CarouselItemType = useMemo(() => {
    return {
      id: reactAppConfig.versionNumber,
      cardVersion: 1,
      locales: {
        no: {
          title: t('carousel.carousel1.title1'),
          desc: t('carousel.carousel1.title2'),
          buttonText: t('carousel.carousel1.buttontext'),
          buttonLink:
            Platform.OS === 'android'
              ? reactAppConfig.playStoreUrl
              : Platform.OS === 'ios'
              ? reactAppConfig.appStoreUrl
              : '',
        },
        en: {
          title: t('carousel.carousel1.title1'),
          desc: t('carousel.carousel1.title2'),
          buttonText: t('carousel.carousel1.buttontext'),
          buttonLink:
            Platform.OS === 'android'
              ? reactAppConfig.playStoreUrl
              : Platform.OS === 'ios'
              ? reactAppConfig.appStoreUrl
              : '',
        },
      },
    };
  }, []);

  useEffect(() => {
    if (features) {
      const carouselItems = [] as CarouselItemType[];
      const appVersionFeature = features.find(
        (e) => e.featureName === 'appVersions'
      );
      if (appVersionFeature) {
        const appVersionsFeatureSpecification: AppVersionsFeatureSpecificationType =
          JSON.parse(appVersionFeature.featureSpecification);
        const currentAppVersion = reactAppConfig.versionNumber;
        const showAppUpdateCard =
          appVersionsFeatureSpecification.encouragementVersions.includes(
            currentAppVersion
          );
        if (
          appVersionFeature.isEnabled &&
          showAppUpdateCard &&
          !removedIds.includes(encouragementCard.id)
        ) {
          carouselItems.push(encouragementCard);
        }
      }
      const carouselCardsFeature = features.find(
        (e) => e.featureName === 'carouselCards'
      );
      if (carouselCardsFeature) {
        const carouselCardsFeatureSpecification: CarouselItemType[] =
          JSON.parse(carouselCardsFeature.featureSpecification);
        carouselCardsFeatureSpecification.map((item) => {
          if (
            CarouselCardSchema.safeParse(item).success &&
            !removedIds.includes(item.id) &&
            item.cardVersion === parseInt(reactAppConfig.supportedCardVersion)
          ) {
            carouselItems.push(item);
          }
        });
        setCarouselData(carouselItems);
      }
    }
  }, [features]);

  const removeCarouselItem = (id: string) => {
    dispatch(saveDeletedCarousels(id));
    setCarouselData((prevData) =>
      prevData.filter((carousel) => carousel.id !== id)
    );
    if (scrollRef && scrollRef.current) {
      scrollRef.current.goToFirstIndex();
    }
  };

  if (featuresLoading || featuresFetching) return <MkLoader />;

  return carouselData.length > 0 ? (
    <View flex={1} mb={50}>
      <SwiperFlatList
        autoplay={true}
        ref={scrollRef}
        autoplayDelay={8}
        decelerationRate={'normal'}
        autoplayLoop={true}
        autoplayLoopKeepAnimation={true}
        showPagination
        index={0}
        paginationActiveColor={motkraftTheme.colors.primary}
        paginationDefaultColor={motkraftTheme.colors.carouselPaginationDefault}
        paginationStyle={{
          paddingRight: 25,
          paddingBottom: 25,
          position: 'absolute',
          bottom: -50,
        }}
        paginationStyleItem={{
          height: 5,
          width: 5,
          marginRight: 0,
        }}
        data={carouselData}
        renderItem={({ index }) => {
          return (
            <CarouselItem
              id={carouselData[index].id}
              title={carouselData[index].locales[currentLocale].title}
              desc={carouselData[index].locales[currentLocale].desc}
              buttonText={carouselData[index].locales[currentLocale].buttonText}
              buttonLink={carouselData[index].locales[currentLocale].buttonLink}
              removeCarouselItem={removeCarouselItem}
            />
          );
        }}
      />
    </View>
  ) : null;
};

const CarouselItem = ({
  title,
  desc,
  buttonText,
  buttonLink,
  id,
  removeCarouselItem,
}: CarouselItemProps) => {
  const width = Dimensions.get('window').width;
  const { colorMode } = useColorMode();
  const isEncouragementCard = id === reactAppConfig.versionNumber;

  return (
    <VStack
      bgColor={
        isEncouragementCard
          ? 'primary'
          : colorMode === 'dark'
          ? 'chartUnselectedBar'
          : 'white'
      }
      width={width - 38}
      p={4}
      mx={3}
      borderRadius={12}
      justifyContent={'space-between'}
    >
      <VStack justifyContent={'space-around'}>
        <View mr={46} pb={6}>
          <HStack>
            <View w={'100%'}>
              <Text
                fontFamily={'readerBold'}
                fontSize={18}
                pb={2}
                color={
                  isEncouragementCard
                    ? motkraftTheme.colors.encouragementTitle
                    : colorMode === 'dark'
                    ? 'white'
                    : 'lightText'
                }
              >
                {title}
              </Text>
            </View>
            <Pressable onPress={() => removeCarouselItem(id)} ml={30}>
              <XIcon
                color={
                  isEncouragementCard
                    ? motkraftTheme.colors.encouragementTitle
                    : colorMode === 'dark'
                    ? 'white'
                    : motkraftTheme.colors.chartUnselectedBar
                }
              />
            </Pressable>
          </HStack>
          <Text
            fontFamily={'readerRegular'}
            fontSize={16}
            color={
              isEncouragementCard
                ? 'encouragementDesc'
                : colorMode === 'dark'
                ? 'white'
                : 'lightText'
            }
          >
            {desc}
          </Text>
        </View>
      </VStack>
      <HStack alignSelf={'flex-end'}>
        <Button
          bgColor={
            isEncouragementCard
              ? 'encouragementButtonBg'
              : colorMode === 'dark'
              ? 'primary'
              : 'lightText'
          }
          w={isEncouragementCard ? Dimensions.get('window').width - 74 : 121}
          _text={{
            fontSize: 14,
            fontFamily: 'readerPro',
            color: isEncouragementCard
              ? 'white'
              : colorMode === 'dark'
              ? 'chartUnselectedBar'
              : 'white',
          }}
          borderRadius={20}
          onPress={() => {
            WebBrowser.openBrowserAsync(buttonLink);
          }}
        >
          {buttonText}
        </Button>
      </HStack>
    </VStack>
  );
};

export default MkCarousel;
