export enum PowerPriceIntervals {
  today = 'today',
  tomorrow = 'tomorrow',
}

export type PowerPrice = {
  prices: { [key in PowerPriceIntervals]: PowerPriceItem[] };
  region: string;
};

export type PowerPriceItem = {
  tag: string;
  timestamp: string;
  value: number;
};

export enum Regions {
  'NO 1' = 'NO1',
  'NO 2' = 'NO2',
  'NO 3' = 'NO3',
  'NO 4' = 'NO4',
  'NO 5' = 'NO5',
}
