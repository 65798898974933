import { apiSlice } from './apiSlice';
import { InvoicePdfParams } from '../../types/invoice';

export const invoicePdfSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    invoicePdf: builder.query<any, InvoicePdfParams>({
      query: ({ invoiceSerialNumber }) => ({
        url: `/invoice/download/${invoiceSerialNumber}`,
        method: 'get',
      }),
    }),
  }),
});

export const { useLazyInvoicePdfQuery } = invoicePdfSlice;
