import { NavigationProp, useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { HStack, Pressable, Text, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { RootStackParamList } from '../../types/Navigation';
import { formatAsLocalNumber } from '../../utils/number-formatter';
import MkCard from '../basic/MkCard';
import MkLineChart from '../chart/MkLineChart';
import { ForwardArrow } from '../MkIcons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
  setHasPrompted,
  setNumberOfVisits,
} from '../../redux/slices/appReviewSlice';
import { useEffect } from 'react';
import * as StoreReview from 'expo-store-review';
import { UseTrackEventType } from '../../../mixpanel.native';
import { Platform } from 'react-native';

let useTrackEvent: UseTrackEventType = null;
if (Platform.OS !== 'web') {
  import('../../../mixpanel.native').then((mixP) => {
    useTrackEvent = mixP.useTrackEvent;
  });
} else {
  console.warn('Mixpanel is disabled for web');
}

type ElectricityPriceProps = {
  dayPrices: any;
  defaultSelected: string;
};

const ElectricityPrice = ({
  dayPrices,
  defaultSelected,
}: ElectricityPriceProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const trackRatingPrompt = useTrackEvent
    ? useTrackEvent('Star Rating Prompt')
    : null;
  const trackSelectedMeterPrice = useTrackEvent
    ? useTrackEvent('Meter Price')
    : null;
  const dispatch = useDispatch();
  const numberOfVisits = useSelector(
    (state: RootState) => state.appReview.numberOfVisits
  );
  const hasPrompted = useSelector(
    (state: RootState) => state.appReview.hasPrompted
  );
  const currentHour = moment().hour();
  let currentPrice = 0;
  const scatterData: any = [];
  const chartData: any = [];
  let minDomain = Number.MAX_SAFE_INTEGER;
  let maxDomain = 0;
  let totalPrice = 0;

  dayPrices.forEach((price: any, index: any) => {
    if (moment(price.timestamp).hour() === moment().hour()) {
      currentPrice = price.value;
      scatterData.push({ x: index, y: price.value });
    }
    if (price.value > maxDomain) {
      maxDomain = price.value;
    }
    if (price.value < minDomain) {
      minDomain = price.value;
    }
    totalPrice = totalPrice + price.value;
    chartData.push({ x: index, y: price.value });
  });

  function handleWidgetClick() {
    dispatch(setNumberOfVisits(numberOfVisits + 1));
    navigation.navigate('gated', {
      screen: 'powerPrice',
      params: {
        isCustomer: true,
        defaultSelected,
      },
    });
  }

  useEffect(() => {
    const askReview = async () => {
      if (
        !hasPrompted &&
        numberOfVisits >= 3 &&
        (await StoreReview.isAvailableAsync()) &&
        totalPrice / 24 < 180
      ) {
        StoreReview.requestReview().then(() => {
          if (trackRatingPrompt) {
            trackRatingPrompt({});
          }
          dispatch(setHasPrompted(true));
        });
      }
    };
    askReview();
  }, []);

  useEffect(() => {
    if (trackSelectedMeterPrice) {
      trackSelectedMeterPrice({
        'Meter Id': defaultSelected,
        'Meter Price': `${formatAsLocalNumber(currentPrice)} øre/kWt`,
      });
    }
  }, [currentPrice]);

  return (
    <MkCard>
      <Pressable
        bgColor={'transparent'}
        borderRadius={12.5}
        position={'absolute'}
        h={'100%'}
        w={'100%'}
        zIndex={1}
        onPress={handleWidgetClick}
      />
      <VStack p={17} pl={13}>
        <HStack alignItems={'center'} justifyContent={'space-between'}>
          <Text
            _dark={{
              color: 'primary',
            }}
            fontFamily={'readerPro'}
            fontSize={18}
          >
            {t('dashboard.power.noPriceData.title')}
          </Text>
          <ForwardArrow />
        </HStack>
        <HStack
          alignItems={'baseline'}
          justifyContent={'space-between'}
          mt={14}
          mb={2}
        >
          <HStack alignItems={'baseline'}>
            <Text lineHeight={38}>
              <Text fontSize={35} fontFamily={'readerBold'}>
                {formatAsLocalNumber(currentPrice)}
              </Text>
              <Text fontSize={18} fontFamily={'readerBold'} pl={1}>
                {' øre/kWt'}
              </Text>
            </Text>
          </HStack>
          <Text fontFamily={'readerPro'} fontSize={16} pr={1}>
            {`${t('consumptionData.resolution.daily.at')} ${
              currentHour < 10 ? `0${currentHour}` : `${currentHour}`
            }-${
              currentHour + 1 === 24
                ? '00'
                : currentHour + 1 < 10
                ? `0${currentHour + 1}`
                : `${currentHour + 1}`
            }`}
          </Text>
        </HStack>
        {chartData.length > 0 && scatterData.length > 0 ? (
          <MkLineChart
            scatterData={scatterData}
            chartData={chartData}
            minDomain={minDomain}
            maxDomain={maxDomain}
          />
        ) : null}
      </VStack>
    </MkCard>
  );
};

export default ElectricityPrice;
