import { Box, Button, Input, Modal, Text, useToast } from 'native-base';
import { useState, type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { MeterNickName } from '../../screens/gated/PowerAgreementScreen';

type NickNameModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  meterId: string;
  onSubmit: MutationTrigger<
    MutationDefinition<
      MeterNickName,
      BaseQueryFn,
      'residences',
      MeterNickName,
      'api'
    >
  >;
  defaultValue: string;
  loading: boolean;
};

const NickNameModal = ({
  isOpen,
  setIsOpen,
  meterId,
  onSubmit,
  loading,
  defaultValue,
}: NickNameModalProps) => {
  const [meterNickName, setMeterNickName] = useState(defaultValue);
  const [errorMSG, setErrorMSG] = useState('');
  const toast = useToast();
  const { t } = useTranslation();

  const showCustomToast = (type: string, message: string) => {
    toast.show({
      duration: 3000,
      render: () => {
        return (
          <Box
            bg={type === 'success' ? 'primary' : 'expiredPillText'}
            px="2"
            py="1"
            mb={2}
            rounded={'md'}
          >
            <Text
              fontSize={15}
              fontFamily={'readerRegular'}
              color={type === 'success' ? 'black' : 'white'}
            >
              {message}
            </Text>
          </Box>
        );
      },
    });
  };

  const handleSaveClick = () => {
    if (meterNickName.length <= 0) {
      setErrorMSG(
        t('profile.residences.details.nickNameInputErrorMessage') as string
      );
    } else {
      onSubmit({ meterId: meterId, nickName: meterNickName.trimEnd() })
        .unwrap()
        .then((res) => {
          setErrorMSG('');
          setIsOpen(false);
          setMeterNickName(res.nickName);
          showCustomToast(
            'success',
            t('profile.residences.details.meterNickNameSuccessMessage')
          );
        })
        .catch((error) => {
          setErrorMSG('');
          setIsOpen(false);
          console.log(error);
          console.error(
            'Error while updating meter nick name.',
            error.response
          );
          showCustomToast(
            'error',
            t('profile.residences.details.meterNickNameErrorMessage')
          );
        });
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        setErrorMSG('');
      }}
    >
      <Modal.Content bgColor={'white'}>
        <Modal.Body _scrollview={{ bounces: false }}>
          <Input
            bgColor={'white'}
            color={'black'}
            fontFamily={'readerRegular'}
            fontSize={16}
            onChangeText={(meterNickName) => {
              setErrorMSG('');
              const validNickName = meterNickName.slice(0, 20).trimStart();
              setMeterNickName(validNickName);
            }}
            py={3}
            value={meterNickName}
            maxLength={20}
          />
          {errorMSG !== '' && <Text color={'expiredPillText'}>{errorMSG}</Text>}
        </Modal.Body>
        <Button
          bgColor={'primary'}
          borderRadius={'2xl'}
          alignSelf={'center'}
          px={10}
          mb={4}
          onPress={() => {
            handleSaveClick();
          }}
          disabled={loading}
        >
          <Text color={'white'} fontSize={13} fontFamily={'readerBold'}>
            {t('profile.residences.details.save')}
          </Text>
        </Button>
      </Modal.Content>
    </Modal>
  );
};
export default NickNameModal;
