import { FC, useEffect } from 'react';
import { useAuthentication } from '../hooks/useAuthentication';
import axios from 'axios';
import { backend } from '../redux/slices/apiSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

export const AxiosInterceptor: FC = () => {
  const {
    jwt,
    refreshSession,
    userAuthenticated,
    canRefreshSession,
    accessToken,
  } = useAuthentication();
  const impersonate = useSelector(
    (state: RootState) => state.impersonateReducer
  );

  useEffect(() => {
    const addHeadersInterceptor = backend.interceptors.request.use((config) => {
      if (config.headers && userAuthenticated) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
        config.headers['Motkraft-User'] = `${jwt}`;
      }
      //Motkraft-Impersonate-User-Phone
      if (config.headers && impersonate.value) {
        config.headers[
          'Motkraft-Impersonate-User-Phone'
        ] = `+${impersonate.value.trim()}`;
      }
      return config;
    });

    const reauthenticationInterceptor = backend.interceptors.response.use(
      (response) => response,
      (error) => {
        const originalRequest = error.config;
        if (
          error.toJSON().status === 401 &&
          canRefreshSession() &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          return refreshSession()
            .then((token) => {
              if (token && !!token.accessToken && !!token.jwt) {
                axios.defaults.headers.common[
                  'Authorization'
                ] = `Bearer ${token.accessToken}`;
                axios.defaults.headers.common['Motkraft-User'] = `${token.jwt}`;
                return backend(originalRequest);
              }
            })
            .catch((error) => {
              console.error({ error });
              console.error('Error while getting refresh token.');
              return Promise.reject(error);
            });
        }
        return Promise.reject(error);
      }
    );
    return () => {
      backend.interceptors.request.eject(addHeadersInterceptor);
      backend.interceptors.response.eject(reauthenticationInterceptor);
    };
  }, [jwt, userAuthenticated, refreshSession, canRefreshSession]);

  return null;
};
