import { useCallback, useEffect, useState } from 'react';
import {
  HStack,
  ScrollView,
  Text,
  View,
  VStack,
  useColorMode,
  Pressable,
} from 'native-base';
import MkBackground from '../../components/basic/MkBackground';
import {
  CollapsibleDownArrow,
  CollapsibleUpArrow,
  EqualIcon,
  PdfIcon,
} from '../../components/MkIcons';
import MkCard from '../../components/basic/MkCard';
import { useTranslation } from 'react-i18next';
import { RefreshControl, TouchableOpacity } from 'react-native';
import Accordion from 'react-native-collapsible/Accordion';
import InvoiceDetailsOverview from '../../components/invoice/InvoiceDetailsOverview';
import InvoiceDetailsPaymentInfo from '../../components/invoice/InvoiceDetailsPaymentInfo';
import LoadingSkeleton from '../miscellaneous/LoadingSkeleton';
import { RouteProp, useRoute } from '@react-navigation/native';
import { GatedStackParamList } from '../../types/Navigation';
import { formatAsLocalNumber } from '../../utils/number-formatter';
import { InvoiceDetailMeters } from '../../types/invoice';
import ErrorPageScreen from '../miscellaneous/ErrorPageScreen';
import moment from 'moment';
import useProgressViewOffset from '../../hooks/useProgressViewOffset';
import { useLazyInvoicePdfQuery } from '../../redux/slices/invoicePdfSlice';
import { openBrowserAsync } from 'expo-web-browser';
import { useInvoiceDetailsQuery } from '../../redux/slices/invoiceApiSlice';

type InvoiceBasis = {
  section: InvoiceDetailMeters;
};

type TitleValuePairProps = {
  title: string;
  value: string;
};

type HeaderProps = {
  header: string;
};

type InnerCardProps = {
  energy: number;
  cost: number;
};

export const InvoiceDetailScreen = () => {
  const { t } = useTranslation();
  const [activeInvoiceBasisSections, setActiveInvoiceBasisSections] = useState<
    number[]
  >([]);

  const route = useRoute<RouteProp<GatedStackParamList, 'invoiceDetails'>>();
  const params = route.params;
  const { colorMode } = useColorMode();
  const progressViewOffset = useProgressViewOffset();

  const {
    data: invoiceDetails,
    isLoading: invoiceDetailsLoading,
    isFetching: invoiceDetailsFetching,
    refetch: invoiceDetailsRefetch,
  } = useInvoiceDetailsQuery({
    accountId: params.accountId,
    invoiceNumber: params.invoiceNumber,
  });

  const [fetchPdf] = useLazyInvoicePdfQuery();

  const renderHeader = (section: InvoiceDetailMeters) => {
    return (
      <Header
        header={
          section.meterDto.name ||
          section.address.street ||
          section.address.city ||
          `${section.address.postalCode}`
        }
      />
    );
  };
  const renderContentHeader = (
    section: InvoiceDetailMeters,
    _: any,
    isActive: boolean
  ) => {
    return (
      <MkCard borderTopRadius={0} borderBottomRadius={12}>
        {isActive ? (
          <View alignSelf={'center'} mb={4} mt={4}>
            <CollapsibleUpArrow
              color={colorMode === 'dark' ? 'white' : '#386666'}
            />
          </View>
        ) : (
          <>
            <InnerCard
              energy={section.meterDto.consumption.energy}
              cost={section.meterDto.consumption.cost}
            />
            <View alignSelf={'center'} my={4}>
              <CollapsibleDownArrow
                color={colorMode === 'dark' ? 'white' : '#386666'}
              />
            </View>
          </>
        )}
      </MkCard>
    );
  };

  const renderContentForInvoice = (section: InvoiceDetailMeters) => {
    return (
      <VStack>
        <InvoiceBasis section={section} />
      </VStack>
    );
  };

  // useEffect(() => {
  //   if (pdfData) {
  //     openBrowserAsync(pdfData);
  //   }
  // }, [pdfData]);

  const handleShowPdfPress = useCallback(() => {
    if (invoiceDetails) {
      fetchPdf({ invoiceSerialNumber: invoiceDetails.invoiceSerialId }).then(
        (res) => {
          openBrowserAsync(res.data);
        }
      );
    }
  }, [invoiceDetails]);

  if (invoiceDetailsLoading || invoiceDetailsFetching)
    return <LoadingSkeleton />;

  return (
    <MkBackground pt={12} pb={10}>
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={invoiceDetailsFetching}
            onRefresh={invoiceDetailsRefetch}
            progressViewOffset={progressViewOffset}
          />
        }
      >
        {invoiceDetails ? (
          <View flex={1}>
            <InvoiceDetailsOverview
              label={params.statusTitle}
              amount={params.amount}
              monthYear={params.monthYear}
            />
            <Pressable
              onPress={handleShowPdfPress}
              py={3}
              mt={3}
              borderRadius={10}
              bgColor={'invoicePdfBgDark'}
              _pressed={{
                opacity: 0.8,
              }}
            >
              <HStack justifyContent={'center'} alignItems={'center'} space={2}>
                <PdfIcon />
                <Text fontFamily={'readerPro'} fontSize={14} color={'white'}>
                  {t('profile.invoicesScreen.invoiceDetails.showPdf')}
                </Text>
              </HStack>
            </Pressable>
            <Text
              fontFamily={'readerRegular'}
              pb={4}
              pt={7}
              fontSize={18}
              _light={{ color: 'black' }}
            >
              {t(
                'profile.invoicesScreen.invoiceDetails.paymentInformationTitle'
              )}
            </Text>
            <InvoiceDetailsPaymentInfo
              accountNumber={invoiceDetails.account.number}
              id={invoiceDetails.account.id}
              amount={params.amount}
              dueDate={invoiceDetails.account.dueDate}
            />
            <Text
              fontFamily={'readerRegular'}
              pb={4}
              pt={7}
              fontSize={18}
              _light={{ color: 'black' }}
            >
              {t('profile.invoicesScreen.invoiceDetails.invoiceBasisTitle')}
            </Text>
            <View flex={1}>
              <Accordion
                activeSections={activeInvoiceBasisSections}
                sections={invoiceDetails.meters}
                renderHeader={renderContentHeader}
                touchableComponent={TouchableOpacity}
                renderContent={renderContentForInvoice}
                sectionContainerStyle={{ paddingVertical: 16 }}
                renderSectionTitle={renderHeader}
                onChange={setActiveInvoiceBasisSections}
                expandFromBottom={true}
              />
            </View>
          </View>
        ) : (
          <ErrorPageScreen />
        )}
      </ScrollView>
    </MkBackground>
  );
};
const InvoiceBasis = ({ section }: InvoiceBasis) => {
  const { t } = useTranslation();
  let conditionaløre: Boolean;

  let calculatedkr =
    (section.meterDto.surcharge * 100) / section.meterDto.consumption.energy;
  let remainder = Math.abs(Math.round(calculatedkr) - calculatedkr);

  if (remainder > 0.01 || calculatedkr === 0) {
    conditionaløre = false;
  } else {
    conditionaløre = true;
  }

  return (
    <MkCard borderRadius={0}>
      <InnerCard
        energy={section.meterDto.consumption.energy}
        cost={section.meterDto.consumption.cost}
      />
      <VStack space={6} m={4}>
        <TitleValuePair
          title={t('profile.residences.address')}
          value={
            section.address.street ||
            section.address.city ||
            `${section.address.postalCode}`
          }
        />
        <TitleValuePair
          title={t('profile.invoicesScreen.invoiceDetails.period')}
          value={`${t('profile.invoicesScreen.date', {
            date: moment(section.meterDto.period.from).toDate(),
          })} - ${t('profile.invoicesScreen.date', {
            date: moment(section.meterDto.period.to).toDate(),
          })}`}
        />
        <TitleValuePair
          title={t('profile.invoicesScreen.invoiceDetails.surcharge')}
          value={
            conditionaløre
              ? `${calculatedkr.toFixed(0)} øre * ${formatAsLocalNumber(
                  section.meterDto.consumption.energy,
                  0
                )} kWt = ${formatAsLocalNumber(section.meterDto.surcharge)} kr`
              : t(
                  'profile.invoicesScreen.invoiceDetails.surchargeEquationError'
                )
          }
        />
        <TitleValuePair
          title={t('profile.invoicesScreen.invoiceDetails.monthlyFee')}
          value={`${formatAsLocalNumber(section.meterDto.monthlyFee, 0)} kr`}
        />
        {section.meterDto.contribution > 0 ? (
          <TitleValuePair
            title={t('profile.invoicesScreen.invoiceDetails.greenContribution')}
            value={`${formatAsLocalNumber(
              section.meterDto.contribution,
              0
            )} kr`}
          />
        ) : null}
      </VStack>
    </MkCard>
  );
};

const TitleValuePair = ({ title, value }: TitleValuePairProps) => {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  return (
    <VStack space={1}>
      <HStack space={2}>
        <Text fontFamily={'readerPro'} fontSize={16}>
          {title}
        </Text>
      </HStack>
      <Text fontFamily={'readerRegular'} fontSize={16}>
        {value}
      </Text>
    </VStack>
  );
};

const Header = ({ header }: HeaderProps) => {
  return (
    <MkCard
      _dark={{
        bgColor: 'primary',
      }}
      _light={{
        bgColor: 'lightText',
      }}
      py={4}
      borderBottomLeftRadius={0}
      borderBottomRightRadius={0}
      alignItems="center"
    >
      <Text
        _dark={{
          color: 'cardBgDark',
        }}
        _light={{
          color: 'white',
        }}
        fontFamily={'readerBold'}
        fontSize={16}
      >
        {header}
      </Text>
    </MkCard>
  );
};

const InnerCard = ({ energy, cost }: InnerCardProps) => {
  const { t } = useTranslation();
  return (
    <MkCard
      borderRadius={12}
      borderWidth={2}
      _dark={{
        borderColor: 'innerCardBorder',
      }}
      _light={{ borderColor: 'lightText' }}
      px={4}
      py={3}
      m={4}
    >
      <HStack justifyContent={'space-between'} alignItems={'center'}>
        <VStack justifyContent={'center'} flex={1}>
          <Text fontFamily={'readerRegular'} fontSize={16} textAlign={'center'}>
            {t('profile.invoicesScreen.invoiceDetails.consumption')}
          </Text>
          <Text
            fontFamily={'readerPro'}
            _dark={{
              color: 'primary',
            }}
            fontSize={16}
            textAlign={'center'}
          >
            {formatAsLocalNumber(energy) + ' kWt'}
          </Text>
        </VStack>
        <View alignItems={'center'} flex={1}>
          <EqualIcon />
        </View>
        <VStack justifyContent={'center'} flex={1}>
          <Text fontFamily={'readerRegular'} fontSize={16} textAlign={'center'}>
            {t('profile.invoicesScreen.invoiceDetails.invoiced')}
          </Text>
          <Text
            fontFamily={'readerPro'}
            _dark={{
              color: 'primary',
            }}
            fontSize={16}
            textAlign={'center'}
          >
            {formatAsLocalNumber(cost) + ' kr'}
          </Text>
        </VStack>
      </HStack>
    </MkCard>
  );
};
