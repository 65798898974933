import {
  BaseQueryFn,
  createApi,
  retry,
} from '@reduxjs/toolkit/dist/query/react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { reactAppConfig } from '../../config/environment';

export const backend = axios.create({
  baseURL: reactAppConfig.backendUrl,
});

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await backend({
        url: baseUrl + url,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const apiSlice = createApi({
  baseQuery: retry(axiosBaseQuery({ baseUrl: reactAppConfig.backendUrl }), {
    maxRetries: 1,
  }),
  endpoints: (builder) => ({}),
});
