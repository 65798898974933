import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Login from '../../screens/public/LoginScreen';
import LandingScreen from '../../screens/public/LandingScreen';
import { PublicStackParamList } from '../../types/Navigation';

const PublicStack = createNativeStackNavigator<PublicStackParamList>();

export function PublicNavigator() {
  return (
    <PublicStack.Navigator
      initialRouteName={'landing'}
      screenOptions={{
        headerBackVisible: false,
        headerShown: false,
        animation: 'slide_from_right',
      }}
    >
      <PublicStack.Screen name="landing" component={LandingScreen} />
      <PublicStack.Screen name="login" component={Login}></PublicStack.Screen>
    </PublicStack.Navigator>
  );
}
