import { useDisclose, Modal, Center, Button } from 'native-base';
import { ReactElement } from 'react';
import { motkraftTheme } from '../../theme';
import { InfoCircle } from '../MkIcons';

type MkModalProps = {
  children: ReactElement;
  iconHeight?: number;
  iconWidth?: number;
  iconColor?: string;
  trackMixPanelEvent: () => void;
};

const MkModal = ({
  children,
  iconHeight = 29,
  iconWidth = 29,
  iconColor = motkraftTheme.colors.primary,
  trackMixPanelEvent,
}: MkModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclose();

  return (
    <Center>
      <Button
        p={0}
        onPress={() => {
          trackMixPanelEvent();
          onOpen();
        }}
      >
        <InfoCircle
          width={iconWidth}
          height={iconHeight}
          iconColor={iconColor}
        />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Content _dark={{ bgColor: 'white' }}>
          <Modal.CloseButton p={0} _icon={{ color: 'chartUnselectedBar' }} />
          <Modal.Body
            justifyContent={'center'}
            alignItems={'center'}
            minHeight={125}
            _scrollview={{ bounces: false }}
          >
            {children}
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Center>
  );
};

export default MkModal;
