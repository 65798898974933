import { NavigationProp, useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { HStack, Pressable, Text } from 'native-base';
import { useTranslation } from 'react-i18next';
import { RootStackParamList } from '../../types/Navigation';
import { formatAsLocalNumber } from '../../utils/number-formatter';
import MkCard from '../basic/MkCard';
import { ForwardArrow, OnboardingWidgetMap } from '../MkIcons';
import { UseTrackEventType } from '../../../mixpanel.native';
import { Platform } from 'react-native';

let useTrackEvent: UseTrackEventType = null;
if (Platform.OS !== 'web') {
  import('../../../mixpanel.native').then((mixP) => {
    useTrackEvent = mixP.useTrackEvent;
  });
} else {
  console.warn('Mixpanel is disabled for web');
}

type OnboardingMapsProps = {
  mapColours: string[];
  selectedRegion: string;
  currentPrice: number;
};

const OnboardingMaps = ({
  mapColours,
  selectedRegion,
  currentPrice,
}: OnboardingMapsProps) => {
  const { t } = useTranslation();
  const currentHour = moment().hour();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const trackNonCustomerToPriceScreen = useTrackEvent
    ? useTrackEvent('Price Widget Non Customer')
    : null;

  return (
    <Pressable
      onPress={() => {
        navigation.navigate('gated', {
          screen: 'powerPrice',
          params: {
            isCustomer: false,
            defaultSelected: selectedRegion,
          },
        });
        if (trackNonCustomerToPriceScreen) {
          const price = formatAsLocalNumber(currentPrice);
          trackNonCustomerToPriceScreen({
            'Date-Time': moment().format('HH:mm DD:MM:YYYY'),
            'Current Price': `${price} øre/kWt`,
          });
        }
      }}
    >
      <MkCard p={17}>
        <HStack justifyContent={'space-between'}>
          <Text
            fontFamily={'readerPro'}
            fontSize={18}
            _dark={{ color: 'primary' }}
          >
            {`${t('dashboard.onboarding.maps_title')} ${selectedRegion}`}
          </Text>
          <ForwardArrow />
        </HStack>
        <HStack
          mt={33}
          mb={52}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <HStack alignItems={'baseline'}>
            <Text fontFamily={'readerBold'} fontSize={35}>
              {formatAsLocalNumber(currentPrice)}
            </Text>
            <Text fontFamily={'readerBold'} fontSize={18} ml={1}>
              øre/kWt
            </Text>
          </HStack>
          <Text fontFamily={'readerPro'} fontSize={16}>{`${t(
            'consumptionData.resolution.daily.at'
          )} ${currentHour}-${
            currentHour + 1 === 24 ? '00' : currentHour + 1
          }`}</Text>
        </HStack>
        <OnboardingWidgetMap colours={mapColours} />
      </MkCard>
    </Pressable>
  );
};

export default OnboardingMaps;
