import { Dispatch, SetStateAction, useState } from 'react';
import { HStack, Button, useColorMode, View } from 'native-base';
import { useTranslation } from 'react-i18next';
import { BarGraphIcon, LineGraphIcon } from '../MkIcons';
import { motkraftTheme } from '../../theme';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { Dimensions } from 'react-native';

export enum GraphIntervals {
  lineChart = 'lineChart',
  barChart = 'barChart',
}

type MkChartSwitchProps = {
  chartType: GraphIntervals;
  switchChartType: any;
  toggleChartSwapping: Dispatch<SetStateAction<boolean>>;
};

const MkChartSwitch = ({
  chartType,
  switchChartType,
  toggleChartSwapping,
}: MkChartSwitchProps) => {
  const INTERVALS = Object.values(GraphIntervals);
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  const bgWidth = (Dimensions.get('screen').width - 50) / 2;
  const [selectedIndex, setSelectedIndex] = useState(
    chartType === GraphIntervals.lineChart ? 0 : 1
  );

  const bgTranslation = useSharedValue(
    chartType === INTERVALS[0] ? 0 : bgWidth
  );

  const bgAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: bgTranslation.value }],
    };
  });

  const lineGraphTextStyle = useAnimatedStyle(() => {
    return {
      color: interpolateColor(
        bgTranslation.value,
        [0, bgWidth],
        [
          colorMode === 'dark' ? motkraftTheme.colors.primary : 'white',
          colorMode === 'dark'
            ? 'white'
            : motkraftTheme.colors.chartUnselectedBar,
        ]
      ),
    };
  });

  const barGraphTextStyle = useAnimatedStyle(() => {
    return {
      color: interpolateColor(
        bgTranslation.value,
        [0, bgWidth],
        [
          colorMode === 'dark'
            ? 'white'
            : motkraftTheme.colors.chartUnselectedBar,
          colorMode === 'dark' ? motkraftTheme.colors.primary : 'white',
        ]
      ),
    };
  });

  const handleButtonPress = (index: number) => {
    if (INTERVALS[index] != chartType) {
      toggleChartSwapping(true);
      setSelectedIndex(index);
      bgTranslation.value = withTiming(index === 0 ? 0 : bgWidth);
      setTimeout(() => {
        switchChartType();
      }, 150);
    }
  };

  return (
    <View
      p={2}
      borderRadius={15}
      _dark={{
        bgColor: 'cardBgDark',
      }}
      _light={{
        bgColor: 'transparent',
        borderWidth: '2',
        borderColor: 'chartUnselectedBar',
      }}
    >
      <HStack>
        <Animated.View
          style={[
            {
              backgroundColor: motkraftTheme.colors.chartUnselectedBar,
              width: '50%',
              height: '100%',
              position: 'absolute',
              borderRadius: 15,
            },
            bgAnimatedStyle,
          ]}
        />
        <Button
          flex={1}
          onPress={() => {
            handleButtonPress(0);
          }}
          borderRadius={15}
          leftIcon={
            <LineGraphIcon
              fill={
                selectedIndex === 0
                  ? colorMode === 'dark'
                    ? motkraftTheme.colors.primary
                    : 'white'
                  : colorMode === 'dark'
                  ? 'white'
                  : motkraftTheme.colors.chartUnselectedBar
              }
            />
          }
        >
          <Animated.Text
            style={[
              { fontFamily: 'readerPro', fontSize: 16 },
              lineGraphTextStyle,
            ]}
          >
            {t(`chartSwitch.${INTERVALS[0]}.label`)}
          </Animated.Text>
        </Button>
        <Button
          flex={1}
          onPress={() => {
            handleButtonPress(1);
          }}
          borderRadius={15}
          leftIcon={
            <BarGraphIcon
              strokeColor={
                selectedIndex === 1
                  ? colorMode === 'dark'
                    ? motkraftTheme.colors.primary
                    : 'white'
                  : colorMode === 'dark'
                  ? 'white'
                  : motkraftTheme.colors.chartUnselectedBar
              }
            />
          }
        >
          <Animated.Text
            style={[
              { fontFamily: 'readerPro', fontSize: 16 },
              barGraphTextStyle,
            ]}
          >
            {t(`chartSwitch.${INTERVALS[1]}.label`)}
          </Animated.Text>
        </Button>
      </HStack>
    </View>
  );
};

export default MkChartSwitch;
