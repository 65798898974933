import { Modal } from 'native-base';
type ModelProps = {
  children?: JSX.Element | JSX.Element[] | string | any;
  modalVisible: boolean;
  closeModel: () => void;
  openMode?: () => void;
  showCloseModelBtn?: boolean;
  contentStyle?: any;
};
const MkModal = ({
  children,
  modalVisible,
  closeModel,
  contentStyle,
  showCloseModelBtn = false,
}: ModelProps) => {
  return (
    <Modal isOpen={modalVisible} onClose={closeModel} avoidKeyboard size="xl">
      <Modal.Content _dark={{ bgColor: 'white' }}>
        {showCloseModelBtn && <Modal.CloseButton />}
        <Modal.Body m={4}>{children}</Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
export { MkModal };
