import {
  HStack,
  Pressable,
  Text,
  useColorMode,
  View,
  VStack,
} from 'native-base';
import { useTranslation } from 'react-i18next';
import { CalendarLeftArrow, CalendarRightArrow } from '../MkIcons';
import { monthToDate } from '../../utils/date';
import moment from 'moment';
import { useState } from 'react';

type MonthPillProp = {
  month: number;
  selectedMonth: number;
  year: number;
  selectedYear: number;
  setSelectedMonth: any;
  disabled: boolean;
};

type MkMonthDatePickerProps = {
  minMonthDate: string;
  maxMonthDate: string;
  selectedMonth: string;
  setSelectedMonth: any;
  previousSelectedMonth: string;
};

const MkMonthDatePicker = ({
  minMonthDate,
  maxMonthDate,
  selectedMonth,
  setSelectedMonth,
}: MkMonthDatePickerProps) => {
  const { colorMode } = useColorMode();
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [yearState, setYearState] = useState(
    selectedMonth !== '' ? moment(selectedMonth).year() : moment().year()
  );

  const handleLeftArrowPress = () => {
    setYearState(yearState - 1);
  };

  const handleRightArrowPress = () => {
    setYearState(yearState + 1);
  };

  return (
    <View
      bgColor={colorMode === 'dark' ? '#294747' : '#EBEBEB'}
      borderRadius={20}
      py={3}
      px={3}
      borderWidth={1}
      borderColor={'transparent'}
    >
      <HStack justifyContent={'space-between'} alignItems={'center'} px={3}>
        <Pressable onPress={handleLeftArrowPress} hitSlop={20}>
          <CalendarLeftArrow
            stroke={colorMode === 'dark' ? 'white' : '#294747'}
          />
        </Pressable>
        <Text
          fontFamily={'readerBold'}
          fontSize={18}
          _dark={{ color: 'white' }}
          _light={{ color: '#294747' }}
        >
          {yearState}
        </Text>
        <Pressable onPress={handleRightArrowPress} hitSlop={20}>
          <CalendarRightArrow
            stroke={colorMode === 'dark' ? 'white' : '#294747'}
          />
        </Pressable>
      </HStack>
      <VStack mt={3}>
        <HStack>
          {months.slice(0, 3).map((month) => (
            <MonthPill
              key={month}
              month={month}
              selectedMonth={
                selectedMonth !== '' ? moment(selectedMonth).month() + 1 : -1
              }
              year={yearState}
              selectedYear={
                selectedMonth !== ''
                  ? moment(selectedMonth).year()
                  : moment().year()
              }
              setSelectedMonth={setSelectedMonth}
              disabled={
                !moment()
                  .year(yearState)
                  .month(month - 1)
                  .isBetween(
                    moment(minMonthDate).add(1, 'day'),
                    moment(maxMonthDate).add(1, 'month')
                  )
              }
            />
          ))}
        </HStack>
        <HStack>
          {months.slice(3, 6).map((month) => (
            <MonthPill
              key={month}
              month={month}
              selectedMonth={
                selectedMonth !== '' ? moment(selectedMonth).month() + 1 : -1
              }
              year={yearState}
              selectedYear={
                selectedMonth !== ''
                  ? moment(selectedMonth).year()
                  : moment().year()
              }
              setSelectedMonth={setSelectedMonth}
              disabled={
                !moment()
                  .year(yearState)
                  .month(month - 1)
                  .isBetween(
                    moment(minMonthDate).add(1, 'day'),
                    moment(maxMonthDate).add(1, 'month')
                  )
              }
            />
          ))}
        </HStack>
        <HStack>
          {months.slice(6, 9).map((month) => (
            <MonthPill
              key={month}
              month={month}
              selectedMonth={
                selectedMonth !== '' ? moment(selectedMonth).month() + 1 : -1
              }
              year={yearState}
              selectedYear={
                selectedMonth !== ''
                  ? moment(selectedMonth).year()
                  : moment().year()
              }
              setSelectedMonth={setSelectedMonth}
              disabled={
                !moment()
                  .year(yearState)
                  .month(month - 1)
                  .isBetween(
                    moment(minMonthDate).add(1, 'day'),
                    moment(maxMonthDate).add(1, 'month')
                  )
              }
            />
          ))}
        </HStack>
        <HStack>
          {months.slice(9, 12).map((month) => (
            <MonthPill
              key={month}
              month={month}
              selectedMonth={
                selectedMonth !== '' ? moment(selectedMonth).month() + 1 : -1
              }
              year={yearState}
              selectedYear={
                selectedMonth !== ''
                  ? moment(selectedMonth).year()
                  : moment().year()
              }
              setSelectedMonth={setSelectedMonth}
              disabled={
                !moment()
                  .year(yearState)
                  .month(month - 1)
                  .isBetween(
                    moment(minMonthDate).add(1, 'day'),
                    moment(maxMonthDate).add(1, 'month')
                  )
              }
            />
          ))}
        </HStack>
      </VStack>
    </View>
  );
};

const MonthPill = ({
  month,
  selectedMonth,
  year,
  selectedYear,
  setSelectedMonth,
  disabled,
}: MonthPillProp) => {
  const { t } = useTranslation();

  const isSelectedPill =
    year === selectedYear && month === selectedMonth ? true : false;

  const handlePillPress = () => {
    if (disabled) return;
    const yearMonth = `${year}-${month < 10 ? `0${month}` : `${month}`}`;
    setSelectedMonth(yearMonth);
  };

  return (
    <Pressable
      _dark={{ bgColor: isSelectedPill ? 'primary' : null }}
      _light={{ bgColor: isSelectedPill ? '#294747' : null }}
      py={2}
      mx={2}
      my={5}
      borderRadius={5}
      onPress={handlePillPress}
      flex={1}
    >
      <Text
        fontFamily={'readerBold'}
        fontSize={15}
        textAlign={'center'}
        _dark={{
          color: disabled ? '#9B9B9B' : isSelectedPill ? 'black' : 'white',
        }}
        _light={{
          color: disabled ? '#858585' : isSelectedPill ? 'white' : '#294747',
        }}
      >
        {t('dashboard.power.consumptionInMonth', {
          month: monthToDate(month),
          capitalize: true,
        })}
      </Text>
    </Pressable>
  );
};

export default MkMonthDatePicker;
