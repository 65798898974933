import { apiSlice } from './apiSlice';

export const deleteRequestSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteData: builder.mutation<void, void>({
      query: () => ({
        url: '/initiateAccountDeletion',
        method: 'post',
      }),
    }),
  }),
});

export const { useDeleteDataMutation } = deleteRequestSlice;
