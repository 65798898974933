import { Text, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import MkCard from '../../components/basic/MkCard';

const ErrorWidget = () => {
  const { t } = useTranslation();

  return (
    <MkCard
      bgColor={'expiredPillBg'}
      _dark={{}}
      _light={{ borderColor: 'invoiceIconExpired', borderWidth: 1 }}
    >
      <VStack pl={10} pt={28} pr={37} pb={21}>
        <Text color="black" fontFamily={'readerBold'} fontSize={16} pb={5}>
          {t('errorCard.title')}
        </Text>
        <Text color="black" fontFamily={'readerRegular'} fontSize={13}>
          {t('errorCard.content')} {t('errorCard.updatecontent')}
        </Text>
      </VStack>
    </MkCard>
  );
};

export default ErrorWidget;
