import {
  BottomSheetModal,
  useBottomSheetTimingConfigs,
} from '@gorhom/bottom-sheet';
import {
  Button,
  HStack,
  Pressable,
  Text,
  useColorMode,
  View,
} from 'native-base';
import { RefObject, useCallback, useMemo, useState } from 'react';
import { motkraftTheme } from '../../theme';
import { ConsumptionInterval } from '../../types/PowerConsumption';
import MkMonthDatePicker from '../datePicker/MkMonthDatePicker';
import MkDayDatePicker from '../datePicker/MkDayDatePicker';
import MkWeekDatePicker from '../datePicker/MkWeekDatePicker';
import { Dimensions, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTranslation } from 'react-i18next';
import { UseTrackEventType } from '../../../mixpanel.native';
import moment from 'moment';

let useTrackEvent: UseTrackEventType = null;
if (Platform.OS !== 'web') {
  import('../../../mixpanel.native').then((mixP) => {
    useTrackEvent = mixP.useTrackEvent;
  });
} else {
  console.warn('Mixpanel is disabled for web');
}

type MkDatePickerBottomSheetProps = {
  bottomSheetRef: RefObject<BottomSheetModal>;
  selectedIntervalType: ConsumptionInterval;
  closeFunction: (
    update: boolean,
    interval: string,
    intervalType: ConsumptionInterval
  ) => void;
  previousSelectedDay: string;
  minDayDate: string;
  maxDayDate: string;
  previousSelectedWeek: string;
  minWeekDate: string;
  maxWeekDate: string;
  previousSelectedMonth: string;
  minMonthDate: string;
  maxMonthDate: string;
};

type IntervalPillProps = {
  pillText: string;
  onPillPress: () => void;
  isSelected: boolean;
};

const MkDatePickerBottomSheet = ({
  bottomSheetRef,
  selectedIntervalType,
  closeFunction,
  previousSelectedDay,
  minDayDate,
  maxDayDate,
  previousSelectedWeek,
  minWeekDate,
  maxWeekDate,
  previousSelectedMonth,
  minMonthDate,
  maxMonthDate,
}: MkDatePickerBottomSheetProps) => {
  const insets = useSafeAreaInsets();
  const BOTTOM_SHEET_HEIGHT = 575;
  const SNAP_PERCENT = Math.ceil(
    (BOTTOM_SHEET_HEIGHT / (Dimensions.get('window').height - insets.bottom)) *
      100
  );
  const snapPoints = useMemo(() => [`${SNAP_PERCENT}%`], []);
  const { colorMode } = useColorMode();
  const INTERVALS = Object.values(ConsumptionInterval);
  const [intervalTypeState, setIntervalTypeState] =
    useState(selectedIntervalType);
  const [selectedDay, setSelectedDay] = useState(previousSelectedDay);
  const [selectedWeek, setSelectedWeek] = useState(previousSelectedWeek);
  const [selectedMonth, setSelectedMonth] = useState(previousSelectedMonth);
  const animationConfigs = useBottomSheetTimingConfigs({
    duration: 750,
  });
  const { t } = useTranslation();
  const trackDayWeekMonthSelecter = useTrackEvent
    ? useTrackEvent('Day/Month/Week Change')
    : null;

  function handlePillPress(interval: ConsumptionInterval) {
    if (intervalTypeState !== interval) {
      setIntervalTypeState(interval);
    }
    if (trackDayWeekMonthSelecter) {
      trackDayWeekMonthSelecter({
        'Date-Time': moment().format('HH:mm DD:MM:YYYY'),
      });
    }
  }

  const shouldDisable = useMemo(() => {
    return (
      intervalTypeState === selectedIntervalType &&
      ((intervalTypeState === ConsumptionInterval.daily &&
        selectedDay === previousSelectedDay) ||
        (intervalTypeState === ConsumptionInterval.weekly &&
          selectedWeek === previousSelectedWeek) ||
        (intervalTypeState === ConsumptionInterval.monthly &&
          selectedMonth === previousSelectedMonth))
    );
  }, [
    intervalTypeState,
    selectedDay,
    selectedWeek,
    selectedMonth,
    previousSelectedDay,
    previousSelectedWeek,
    previousSelectedMonth,
  ]);

  const handeShowSelectedPress = useCallback(() => {
    closeFunction(
      true,
      intervalTypeState === ConsumptionInterval.daily
        ? selectedDay
        : intervalTypeState === ConsumptionInterval.weekly
        ? selectedWeek
        : selectedMonth,
      intervalTypeState
    );
  }, [intervalTypeState, selectedDay, selectedWeek, selectedMonth]);

  const getDatePickerToShow = () => {
    switch (intervalTypeState) {
      case ConsumptionInterval.daily:
        return (
          <MkDayDatePicker
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            minDayDate={minDayDate}
            maxDayDate={maxDayDate}
            previousSelectedDay={previousSelectedDay}
          />
        );
      case ConsumptionInterval.weekly:
        return (
          <MkWeekDatePicker
            selectedWeek={selectedWeek}
            setSelectedWeek={setSelectedWeek}
            minWeekDate={minWeekDate}
            maxWeekDate={maxWeekDate}
            previousSelectedWeek={previousSelectedWeek}
          />
        );
      case ConsumptionInterval.monthly:
        return (
          <MkMonthDatePicker
            minMonthDate={minMonthDate}
            maxMonthDate={maxMonthDate}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            previousSelectedMonth={previousSelectedMonth}
          />
        );
      default:
        return <></>;
    }
  };

  const handleBottomSheetCloseWithoutSaving = () => {
    setIntervalTypeState(selectedIntervalType);
    if (selectedIntervalType === ConsumptionInterval.daily) {
      setSelectedDay(previousSelectedDay);
      setSelectedWeek(previousSelectedWeek);
      setSelectedMonth(previousSelectedMonth);
    } else if (selectedIntervalType === ConsumptionInterval.weekly) {
      setSelectedWeek(previousSelectedWeek);
      setSelectedDay(previousSelectedWeek);
      setSelectedMonth(previousSelectedMonth);
    } else if (selectedIntervalType === ConsumptionInterval.monthly) {
      setSelectedMonth(previousSelectedMonth);
      setSelectedDay(previousSelectedWeek);
      setSelectedWeek(previousSelectedMonth);
    }
  };

  return (
    <BottomSheetModal
      ref={bottomSheetRef}
      index={0}
      snapPoints={snapPoints}
      animationConfigs={animationConfigs}
      enablePanDownToClose={true}
      enableContentPanningGesture={false}
      backdropComponent={(props) => (
        <Pressable
          onPress={() => {
            handleBottomSheetCloseWithoutSaving();
            bottomSheetRef.current?.close();
          }}
          bgColor={'rgba(0,0,0,0.75)'}
          {...props}
        />
      )}
      backgroundStyle={{
        backgroundColor:
          colorMode === 'dark' ? motkraftTheme.colors.cardBgDark : 'white',
      }}
      handleIndicatorStyle={{
        backgroundColor: motkraftTheme.colors.chartUnselectedBar,
        width: 25,
      }}
    >
      <View p={4} h={BOTTOM_SHEET_HEIGHT}>
        <View>
          <HStack>
            <IntervalPill
              pillText={t('consumptionData.datePicker.day')}
              onPillPress={() => {
                handlePillPress(INTERVALS[0]);
              }}
              isSelected={intervalTypeState === INTERVALS[0]}
            />
            <IntervalPill
              pillText={t('consumptionData.datePicker.week')}
              onPillPress={() => {
                handlePillPress(INTERVALS[1]);
              }}
              isSelected={intervalTypeState === INTERVALS[1]}
            />
            <IntervalPill
              pillText={t('consumptionData.datePicker.month')}
              onPillPress={() => {
                handlePillPress(INTERVALS[2]);
              }}
              isSelected={intervalTypeState === INTERVALS[2]}
            />
          </HStack>
        </View>
        <View my={4}>{getDatePickerToShow()}</View>
        <View>
          <Button
            disabled={shouldDisable}
            bgColor={shouldDisable ? 'bottomNavBorder' : 'chartUnselectedBar'}
            borderRadius={15}
            _text={{ fontFamily: 'readerPro', fontSize: 18 }}
            py={3}
            onPress={handeShowSelectedPress}
          >
            {t('consumptionData.datePicker.confirmTimeSelection')}
          </Button>
        </View>
      </View>
    </BottomSheetModal>
  );
};

const IntervalPill = ({
  pillText,
  onPillPress,
  isSelected,
}: IntervalPillProps) => {
  return (
    <Pressable
      bgColor={isSelected ? '#294747' : 'transparent'}
      px={4}
      py={4}
      borderRadius={15}
      onPress={onPillPress}
      flex={1}
    >
      <Text
        _dark={{
          color: isSelected ? 'primary' : 'white',
        }}
        _light={{ color: isSelected ? 'white' : 'chartUnselectedBar' }}
        fontFamily={isSelected ? 'readerBold' : 'readerRegular'}
        fontSize={16}
        textAlign={'center'}
      >
        {pillText}
      </Text>
    </Pressable>
  );
};

export default MkDatePickerBottomSheet;
