import {
  Button,
  ScrollView,
  Text,
  VStack,
  View,
  useColorMode,
} from 'native-base';
import MkBackground from '../../components/basic/MkBackground';
import LottieView from 'lottie-react-native';
import { useTranslation } from 'react-i18next';
import { Linking, Platform } from 'react-native';
import { reactAppConfig } from '../../config/environment';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setStatusBarStyle } from 'expo-status-bar';
import { AppState } from 'react-native';

const UpdateEnforcementScreen = () => {
  const { t } = useTranslation();
  const { colorMode, toggleColorMode } = useColorMode();
  const savedColorMode = useSelector(
    (state: RootState) => state.colorMode.colorMode
  );
  const rocketAnimationRef = useRef<LottieView>(null);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (nextAppState === 'active') {
        rocketAnimationRef.current?.play();
      }
    });

    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    if (colorMode !== savedColorMode) {
      toggleColorMode();
    }
  }, [savedColorMode, colorMode]);

  useEffect(() => {
    setStatusBarStyle(colorMode === 'dark' ? 'light' : 'dark');
  }, [colorMode]);

  const handleOpenInAppStore = () => {
    const appStoreUrl =
      Platform.OS === 'android'
        ? reactAppConfig.playStoreUrl
        : Platform.OS === 'ios'
        ? reactAppConfig.appStoreUrl
        : '';
    if (appStoreUrl !== '') {
      Linking.openURL(appStoreUrl);
    }
  };

  return (
    <MkBackground>
      <ScrollView>
        <VStack alignItems={'center'}>
          <View h={295} w={295} mt={75}>
            <LottieView
              ref={rocketAnimationRef}
              source={require('../../assets/animations/enforcement-rocket.json')}
              colorFilters={[
                {
                  keypath: 'rocket.Layer 10 Outlines',
                  color: '#FFFFFF',
                },
                {
                  keypath: 'rocket.Layer 9 Outlines',
                  color: '#FFFFFF',
                },
              ]}
              autoPlay
              loop
            />
          </View>
          <Text
            mt={30}
            fontFamily={'readerPro'}
            fontSize={20}
            textAlign={'center'}
            _light={{
              color: 'dark',
            }}
          >
            {t('enforcement.title')}
          </Text>
          <Text
            mt={30}
            fontFamily={'readerRegular'}
            fontSize={16}
            textAlign={'center'}
            _light={{
              color: 'dark',
            }}
          >
            {t('enforcement.desc')}
          </Text>
          <Button
            mt={39}
            py={3}
            w={'full'}
            bgColor={'updateButtonBg'}
            _text={{
              fontFamily: 'readerPro',
              fontSize: 18,
            }}
            borderRadius={50}
            onPress={handleOpenInAppStore}
          >
            {t('enforcement.buttonText')}
          </Button>
        </VStack>
      </ScrollView>
    </MkBackground>
  );
};

export default UpdateEnforcementScreen;
