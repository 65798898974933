import { Text, View } from 'native-base';

type MkDayNameComponentProps = {
  text: string;
};

const MkDayNameComponent = ({ text }: MkDayNameComponentProps) => {
  return (
    <View flex={1}>
      <Text
        fontFamily={'readerPro'}
        fontSize={11}
        textAlign={'center'}
        _light={{
          color: 'chartUnselectedBar',
        }}
      >
        {text}
      </Text>
    </View>
  );
};

export default MkDayNameComponent;
