import { LayoutChangeEvent, RefreshControl } from 'react-native';
import { HStack, ScrollView, Text, VStack, View, Pressable } from 'native-base';
import {
  useChartIntervalsQuery,
  useConsumptionDataQuery,
} from '../../redux/slices/powerConsumptionSlice';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ConsumptionInterval } from '../../types/PowerConsumption';
import LoadingSkeleton from '../../screens/miscellaneous/LoadingSkeleton';
import { MeterData, PriceData } from '../../types/Dashboard';
import MkBackground from '../../components/basic/MkBackground';
import MkModal from '../../components/basic/MkModal';
import {
  convertIntervalToDropdownDataConsumption,
  getDayNames,
} from '../../utils/functions';
import { formatAsLocalNumber } from '../../utils/number-formatter';
import { useDashboardDataQuery } from '../../redux/slices/dashboardApiSlice';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import useProgressViewOffset from '../../hooks/useProgressViewOffset';
import MkBottomSheet from '../../components/basic/MkBottomSheet';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { GraphIntervals } from '../../components/basic/MkChartSwitch';
import MkDetailsLineChart from '../../components/chart/MkDetailsLineChart';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setSelectedMeterId } from '../../redux/slices/selectedMeterSlice';
import { CalendarIcon } from '../../components/MkIcons';
import MkDetailsBarChart from '../../components/chart/MkDetailsBarChart';
import MkDatePickerBottomSheet from '../../components/basic/MkDatePickerBottomSheet';
import GraphError from '../miscellaneous/GraphError';
import { YAxisMode } from '../../redux/slices/consumptionYAxisModeSlice';
import { useFocusEffect } from '@react-navigation/native';
import MkLoader from '../../components/basic/MkLoader';
import { UseTrackEventType } from '../../../mixpanel.native';
import { Platform } from 'react-native';

let useTrackEvent: UseTrackEventType = null;
if (Platform.OS !== 'web') {
  import('../../../mixpanel.native').then((mixP) => {
    useTrackEvent = mixP.useTrackEvent;
  });
} else {
  console.warn('Mixpanel is disabled for web');
}

type DataSet = {
  x: number;
  y: number;
  xLabel?: string;
  label?: string[];
  labelLine1: string;
  labelLine2: string;
  labelLine3: string;
};

type IntervalDropdownValue = { label: string; value: string };

const PowerConsumptionScreen = () => {
  const { t } = useTranslation();
  const INTERVALS = Object.values(ConsumptionInterval);
  const [selectedIntervalType, setSelectedIntervalType] = useState(
    INTERVALS[0]
  );
  const [meters, setMeters] = useState<MeterData[]>([]);
  const selectedMeterId = useSelector(
    (state: RootState) => state.selectedMeterId
  );
  const [intervals, setIntervals] = useState<IntervalDropdownValue[]>([]);
  const [selectedInterval, setSelectedInterval] =
    useState<IntervalDropdownValue>({} as IntervalDropdownValue);
  const [chartData, setChartData] = useState<DataSet[]>([]);
  const [offset, setOffset] = useState<string>(moment.tz.guess(true));
  const progressViewOffset = useProgressViewOffset();
  const bottomSheetModalRef = useRef<BottomSheetModal>(null);
  const [selectedMeter, setSelectedMeter] = useState<MeterData>();
  const graphSettings = useSelector(
    (state: RootState) => state.graphSettings.consumptionChartMode
  );
  const dispatch = useDispatch();
  const datePickerBottomSheetRef = useRef<BottomSheetModal>(null);
  const [chartParentHeight, setChartParentHeight] = useState(0);
  const scrollRef = useRef(null);
  const [chartSwapping, setChartSwapping] = useState(false);
  const consumptionYAxisMode = useSelector(
    (state: RootState) => state.consumptionYAxisMode.yAxisMode
  );
  const [currentPrice, setCurrentPrice] = useState('0');
  const trackCunsumptionChartType = useTrackEvent
    ? useTrackEvent('Cunsumption Screen Chart Type')
    : null;
  const trackCunsumptionMeterChange = useTrackEvent
    ? useTrackEvent('Meter change')
    : null;
  const trackInfoClickFromCunsumption = useTrackEvent
    ? useTrackEvent('Consumption Information Button')
    : null;
  const trackOpenDatePicker = useTrackEvent
    ? useTrackEvent('Open Date Picker')
    : null;

  const trackInfoModalOpen = () => {
    if (trackInfoClickFromCunsumption) {
      trackInfoClickFromCunsumption({
        'Date-Time': moment().format('HH:mm DD:MM:YYYY'),
      });
    }
  };

  const populateChartData = () => {
    if (consumptionData && !intervalsFetching && !consumptionFetching) {
      // lastFetchedMoment.current = moment();
      let cd: DataSet[] = [];
      consumptionData.values.forEach((value) => {
        const date = moment(value.startTime).tz('Europe/Oslo');
        const labelLine2 = `${formatAsLocalNumber(value.cost || 0)} kr`;
        let x = 0;
        let xLabel = '';
        let labelLine1 = '';
        if (selectedIntervalType === ConsumptionInterval.daily) {
          let utcHour = date.hour();
          x = utcHour;
          xLabel = `${utcHour}`;
          labelLine1 = `${t('consumptionData.resolution.daily.at')} ${
            utcHour < 10 ? `0${utcHour}` : utcHour
          }-${
            utcHour + 1 === 24
              ? '00'
              : utcHour + 1 < 10
              ? `0${utcHour + 1}`
              : utcHour + 1
          }`;
        } else if (selectedIntervalType === ConsumptionInterval.weekly) {
          const weekday = date.weekday();
          x = weekday === 0 ? 7 : weekday;
          xLabel = t('general.weekday', {
            date: new Date(date.year(), date.month(), date.date()),
          }).substring(0, 2);
          labelLine1 = t('consumptionData.resolution.weekly.tooltipDate', {
            date: new Date(date.year(), date.month(), date.date()),
          });
        } else {
          x = date.date();
          xLabel = `${date.date()}`;
          labelLine1 = t('consumptionData.resolution.monthly.tooltipDate', {
            date: new Date(date.year(), date.month(), date.date()),
          });
        }
        let cost = value.cost || 0;
        let energy = value.energy || 0;
        cd.push({
          x: x,
          y: consumptionYAxisMode === YAxisMode.cost ? cost : energy,
          xLabel: xLabel,
          labelLine1: labelLine1,
          labelLine2: labelLine2,
          labelLine3: `${formatAsLocalNumber(energy)} kWt`,
        });
      });
      cd = cd.sort((a, b) => a.x - b.x);
      setChartData(cd);
      setChartSwapping(false);
    }
  };

  const {
    data: dashboardData,
    error: dashboardError,
    isLoading: dashboardLoading,
    isFetching: dashboardFetching,
  } = useDashboardDataQuery({ offset });

  const {
    data: chartIntervals,
    error: intervalsError,
    isLoading: intervalsLoading,
    isFetching: intervalsFetching,
    isUninitialized: intervalsStatus,
    refetch: intervalsRefetch,
  } = useChartIntervalsQuery(
    {
      meterId: selectedMeter?.meterId!,
    },
    { skip: !(selectedMeter && selectedMeter.meterId) }
  );

  const {
    data: consumptionData,
    error: consumptionError,
    isFetching: consumptionFetching,
    isLoading: consumptionLoading,
    isUninitialized: consumptionStatus,
    refetch: consumptionRefetch,
  } = useConsumptionDataQuery(
    {
      meterId: selectedMeter?.meterId!,
      interval: selectedIntervalType,
      params: {
        date: selectedInterval.value,
      },
    },
    {
      skip: !(
        selectedMeter?.meterId &&
        selectedIntervalType &&
        Object.keys(selectedInterval).length > 0
      ),
    }
  );

  useEffect(() => {
    if (selectedMeter?.prices.day !== undefined) {
      selectedMeter?.prices.day.map((price: PriceData) => {
        if (moment(price.timestamp).hour() === moment().hour()) {
          const elecPrice = formatAsLocalNumber(price.value);
          setCurrentPrice(elecPrice);
          return;
        }
      });
    }
  }, []);

  useFocusEffect(
    useCallback(() => {
      if (!intervalsStatus && !consumptionStatus) {
        setChartData([] as DataSet[]);
        setOffset(moment.tz.guess(true));
        intervalsRefetch();
        consumptionRefetch();
      }
    }, [consumptionYAxisMode])
  );

  useEffect(() => {
    if (dashboardData) {
      setMeters(dashboardData.meters);
      if (dashboardData.meters.length > 0) {
        const currentSelectedMeter = dashboardData.meters.find(
          (meter) => meter.meterId === selectedMeterId
        );
        if (currentSelectedMeter) {
          setSelectedMeter(currentSelectedMeter);
        } else {
          dispatch(setSelectedMeterId(dashboardData.meters[0].meterId));
          setSelectedMeter(dashboardData.meters[0]);
        }
      }
    }
  }, [dashboardData]);

  useEffect(() => {
    if (chartIntervals) {
      let interval =
        chartIntervals[selectedIntervalType][
          chartIntervals[selectedIntervalType].length - 1
        ];
      setSelectedInterval({
        value: interval,
        label: interval,
      });
    }
  }, [chartIntervals]);

  useEffect(() => {
    if (
      chartIntervals &&
      chartIntervals[selectedIntervalType] &&
      !selectedInterval.value
    ) {
      let interval =
        chartIntervals[selectedIntervalType][
          chartIntervals[selectedIntervalType].length - 1
        ];
      setSelectedInterval({
        value: interval,
        label: interval,
      });
      setIntervals(
        convertIntervalToDropdownDataConsumption(
          chartIntervals[selectedIntervalType],
          selectedIntervalType,
          t
        )
      );
    }
  }, [selectedIntervalType, chartIntervals]);

  useEffect(() => {
    populateChartData();
  }, [consumptionData, intervalsFetching, consumptionFetching]);

  useFocusEffect(
    useCallback(() => {
      if (trackCunsumptionChartType) {
        trackCunsumptionChartType({
          'Chart-Type': graphSettings,
        });
      }
    }, [graphSettings])
  );

  const closeActionSheet = () => {
    bottomSheetModalRef.current?.dismiss();
  };

  const dismissBottomSheetMeters = () => {
    if (selectedMeter?.meterId !== selectedMeterId) {
      setChartData([] as DataSet[]);
      setSelectedMeter(
        meters.find((meter) => meter.meterId === selectedMeterId)
      );
      setOffset(moment.tz.guess(true));
      intervalsRefetch();
      consumptionRefetch();
    }
  };

  const closeDatePickerBottomSheet = (
    update: boolean,
    interval: string = '',
    intervalType: ConsumptionInterval
  ) => {
    if (
      update &&
      (selectedIntervalType !== intervalType ||
        interval !== selectedInterval.value)
    ) {
      setSelectedIntervalType(intervalType);
      setSelectedInterval({
        value: interval,
        label: interval,
      });
      setChartData([] as DataSet[]);
    }
    datePickerBottomSheetRef.current?.dismiss();
  };

  const getMonthXLabels = (totalDays: number) => {
    const monthXLabels = [1];
    const numberOf5Multiples = Math.floor(totalDays / 5);
    let i = 1;
    while (i <= numberOf5Multiples) {
      monthXLabels.push(i * 5);
      i++;
    }
    if (totalDays - monthXLabels[monthXLabels.length - 1] < 3) {
      monthXLabels.pop();
    }
    monthXLabels.push(totalDays);
    return monthXLabels;
  };

  const chartParentOnLayoutHandler = (evt: LayoutChangeEvent) => {
    setChartParentHeight(evt.nativeEvent.layout.height);
  };

  if (
    dashboardLoading ||
    intervalsLoading ||
    consumptionLoading ||
    dashboardFetching ||
    intervalsFetching ||
    consumptionFetching
  )
    return <LoadingSkeleton />;

  return (
    <MkBackground pt={12} pb={7}>
      <ScrollView
        contentContainerStyle={{ flex: 1 }}
        ref={scrollRef}
        refreshControl={
          <RefreshControl
            refreshing={intervalsFetching && consumptionFetching}
            onRefresh={() => {
              setChartData([] as DataSet[]);
              setOffset(moment.tz.guess(true));
              intervalsRefetch();
              consumptionRefetch();
            }}
            progressViewOffset={progressViewOffset}
          />
        }
      >
        {dashboardError || intervalsError || consumptionError ? (
          <View flex={1}>
            <GraphError screen={'powerConsumption'} />
          </View>
        ) : (
          <View flex={1}>
            <View mb={5}>
              <MkBottomSheet
                isCustomer={true}
                bottomSheetModalRef={bottomSheetModalRef}
                closeFunction={closeActionSheet}
                data={meters}
                setData={setMeters}
                dismissFunction={dismissBottomSheetMeters}
              />
              {intervals.length > 0 && chartIntervals && (
                <MkDatePickerBottomSheet
                  bottomSheetRef={datePickerBottomSheetRef}
                  selectedIntervalType={selectedIntervalType}
                  closeFunction={closeDatePickerBottomSheet}
                  previousSelectedDay={
                    selectedIntervalType === ConsumptionInterval.daily
                      ? selectedInterval.value
                      : chartIntervals.daily[chartIntervals.daily.length - 1]
                  }
                  minDayDate={chartIntervals.daily[0]}
                  maxDayDate={
                    chartIntervals.daily[chartIntervals.daily.length - 1]
                  }
                  previousSelectedWeek={
                    selectedIntervalType === ConsumptionInterval.weekly
                      ? selectedInterval.value
                      : chartIntervals.weekly[chartIntervals.weekly.length - 1]
                  }
                  minWeekDate={chartIntervals.weekly[0]}
                  maxWeekDate={
                    chartIntervals.weekly[chartIntervals.weekly.length - 1]
                  }
                  previousSelectedMonth={
                    selectedIntervalType === ConsumptionInterval.monthly
                      ? selectedInterval.value
                      : chartIntervals.monthly[
                          chartIntervals.monthly.length - 1
                        ]
                  }
                  minMonthDate={chartIntervals.monthly[0]}
                  maxMonthDate={
                    chartIntervals.monthly[chartIntervals.monthly.length - 1]
                  }
                />
              )}
              <VStack alignItems={'center'} space={4}>
                <Pressable
                  onPress={() => {
                    bottomSheetModalRef.current?.present();
                    if (trackCunsumptionMeterChange) {
                      trackCunsumptionMeterChange({
                        'Date-Time': moment().format('HH:mm DD:MM:YYYY'),
                        'Current Price': `${currentPrice} øre/kWt`,
                      });
                    }
                  }}
                >
                  <Text
                    fontSize={18}
                    fontFamily={'readerPro'}
                    _dark={{ color: 'primary' }}
                    _light={{ color: 'lightBottomSheetButtonText' }}
                  >
                    {selectedMeter?.name ||
                      selectedMeter?.address?.street ||
                      selectedMeter?.address?.city ||
                      selectedMeter?.address?.postalCode}
                  </Text>
                </Pressable>
                <Pressable
                  onPress={() => {
                    datePickerBottomSheetRef.current?.present();
                    if (trackOpenDatePicker) {
                      trackOpenDatePicker({
                        'Date-Time': moment().format('HH:mm DD:MM:YYYY'),
                      });
                    }
                  }}
                >
                  <HStack
                    space={3}
                    alignItems={'center'}
                    bgColor={'chartUnselectedBar'}
                    px={8}
                    py={2}
                    borderRadius={18}
                  >
                    <Text
                      fontFamily={'readerRegular'}
                      fontSize={18}
                      _light={{ color: 'white' }}
                    >
                      {t(
                        `consumptionData.resolution.${selectedIntervalType}.powerConsumptionLabel`,
                        {
                          date: moment(selectedInterval.value).toDate(),
                          capitalize: true,
                        }
                      )}
                    </Text>
                    <CalendarIcon />
                  </HStack>
                </Pressable>
              </VStack>
              <VStack mt={29}>
                <Text
                  fontSize={18}
                  fontFamily={'readerPro'}
                  _light={{ color: 'chartUnselectedBar' }}
                >
                  {selectedInterval.value &&
                    t('consumptionData.totalConsumption') +
                      ' ' +
                      t(
                        `consumptionData.resolution.${selectedIntervalType}.powerConsumptionLabel`,
                        {
                          date: moment(selectedInterval.value).toDate(),
                          capitalize: true,
                        }
                      )}
                </Text>
                {consumptionData && (
                  <>
                    <HStack space={3}>
                      <Text
                        fontSize={30}
                        fontFamily={'readerBold'}
                        _light={{ color: 'chartUnselectedBar' }}
                      >
                        {formatAsLocalNumber(
                          consumptionData.values.reduce((total, data) => {
                            return total + data.energy;
                          }, 0)
                        ) + ' '}
                        kWt
                      </Text>
                      <MkModal trackMixPanelEvent={trackInfoModalOpen}>
                        <Text
                          fontSize={16}
                          color={'black'}
                          textAlign={'center'}
                          fontFamily={'readerRegular'}
                        >
                          {t('dashboard.powerConsumption.popupText')}
                        </Text>
                      </MkModal>
                    </HStack>
                    <Text
                      fontSize={18}
                      fontFamily={'readerPro'}
                      _light={{ color: 'chartUnselectedBar' }}
                    >
                      {`${t('consumptionData.totalPrice')}`}
                    </Text>
                    <Text
                      fontSize={18}
                      fontFamily={'readerBold'}
                      _light={{ color: 'chartUnselectedBar' }}
                    >
                      {`${formatAsLocalNumber(
                        consumptionData.values.reduce((total, data) => {
                          return total + data.cost;
                        }, 0)
                      )} kr`}
                    </Text>
                  </>
                )}
              </VStack>
            </View>
            {chartData.length > 0 && (
              <View flex={1} onLayout={chartParentOnLayoutHandler} mb={25}>
                {chartSwapping ? (
                  <MkLoader renderMkBackground={false} />
                ) : (
                  chartParentHeight > 0 && (
                    <>
                      {graphSettings === GraphIntervals.barChart ? (
                        <MkDetailsBarChart
                          parentSrollRef={scrollRef}
                          chartHeight={chartParentHeight}
                          data={chartData}
                          xLabels={
                            selectedIntervalType === ConsumptionInterval.daily
                              ? [0, 4, 8, 12, 16, 20, 23]
                              : selectedIntervalType ===
                                ConsumptionInterval.weekly
                              ? getDayNames(t)
                              : getMonthXLabels(
                                  moment(selectedInterval.value).daysInMonth()
                                )
                          }
                          screen={'powerConsumption'}
                          interval={selectedIntervalType}
                          graphLabel={
                            consumptionYAxisMode === YAxisMode.cost
                              ? t(
                                  'profile.graphSettings.consumptionGraph.krUnit'
                                )
                              : t(
                                  'profile.graphSettings.consumptionGraph.kwtUnit'
                                )
                          }
                        />
                      ) : (
                        <MkDetailsLineChart
                          parentSrollRef={scrollRef}
                          chartHeight={chartParentHeight}
                          data={chartData}
                          xLabels={
                            selectedIntervalType === ConsumptionInterval.daily
                              ? [0, 4, 8, 12, 16, 20, 23]
                              : selectedIntervalType ===
                                ConsumptionInterval.weekly
                              ? getDayNames(t)
                              : getMonthXLabels(
                                  moment(selectedInterval.value).daysInMonth()
                                )
                          }
                          screen={'powerConsumption'}
                          interval={selectedIntervalType}
                          graphLabel={
                            consumptionYAxisMode === YAxisMode.cost
                              ? t(
                                  'profile.graphSettings.consumptionGraph.krUnit'
                                )
                              : t(
                                  'profile.graphSettings.consumptionGraph.kwtUnit'
                                )
                          }
                        />
                      )}
                    </>
                  )
                )}
              </View>
            )}
          </View>
        )}
      </ScrollView>
    </MkBackground>
  );
};

export default PowerConsumptionScreen;
