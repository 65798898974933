import { HStack, useColorMode } from 'native-base';
import { lazy, Suspense } from 'react';
import { MkLogoTitle } from '../MkIcons';
import { Platform } from 'react-native';
import MkLoader from './MkLoader';

const IntercomChatSupport = lazy(
  () => import('../chatSupport/IntercomChatSupport')
);

const MkLogoWithChat = () => {
  const { colorMode } = useColorMode();

  return (
    <HStack justifyContent={'space-between'}>
      <MkLogoTitle />
      {Platform.OS == 'ios' || Platform.OS == 'android' ? (
        <Suspense fallback={<MkLoader />}>
          <IntercomChatSupport extended={false} />
        </Suspense>
      ) : null}
    </HStack>
  );
};

export default MkLogoWithChat;
