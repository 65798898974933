import { createSlice } from '@reduxjs/toolkit';

export const carouselSlice = createSlice({
  name: 'carousels',
  initialState: [] as string[],
  reducers: {
    saveDeletedCarousels: (state, action) => {
      const deleted = state;
      deleted.push(action.payload);
      return deleted;
    },
    emptyPreference: (state) => {
      return (state = []);
    },
  },
});

export const { saveDeletedCarousels, emptyPreference } = carouselSlice.actions;

export default carouselSlice.reducer;
