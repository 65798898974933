import {
  Button,
  HStack,
  Pressable,
  Text,
  useColorMode,
  View,
  VStack,
} from 'native-base';
import DraggableFlatList, {
  RenderItemParams,
  ScaleDecorator,
} from 'react-native-draggable-flatlist';
import { DraggableActiveIcon, DraggableIcon, XIcon } from '../MkIcons';
import { gestureHandlerRootHOC } from 'react-native-gesture-handler';
import { MeterData } from '../../types/Dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setSelectedMeterId } from '../../redux/slices/selectedMeterSlice';
import { useTranslation } from 'react-i18next';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../../types/Navigation';
import { useState } from 'react';
import { Dimensions } from 'react-native';
import { saveMeterIds } from '../../redux/slices/meterSlice';

type MkMetersActionSheetProps = {
  closeCallback: () => void;
  data: MeterData[];
  setActionSheetData: any;
};

const MkMetersActionSheet = gestureHandlerRootHOC(
  ({
    closeCallback,
    data: meters,
    setActionSheetData,
  }: MkMetersActionSheetProps) => {
    const dispatch = useDispatch();
    const DRAGGBLE_FLATLIST_HEIGHT = Dimensions.get('screen').height / 3.2;
    const selectedMeterId = useSelector(
      (state: RootState) => state.selectedMeterId
    );
    const { t } = useTranslation();
    const navigation = useNavigation<NavigationProp<RootStackParamList>>();
    const { colorMode } = useColorMode();
    const [isDragging, setIsDragging] = useState(false);

    const renderItem = ({
      item,
      drag,
      isActive,
    }: RenderItemParams<MeterData>) => {
      return (
        <ScaleDecorator>
          <HStack
            alignItems={'center'}
            justifyContent={'space-between'}
            px={27}
            py={13}
            my={19}
            _light={{
              bgColor: isActive ? 'light' : 'transparent',
            }}
            _dark={{ bgColor: isActive ? 'chartUnselectedBar' : 'transparent' }}
            borderRightRadius={15}
          >
            <Pressable
              flex={1}
              flexDirection={'row'}
              justifyContent={'space-between'}
              onPress={() => {
                dispatch(setSelectedMeterId(item.meterId));
              }}
            >
              <VStack space={2}>
                <Text
                  fontFamily={'readerPro'}
                  fontSize={18}
                  _light={{
                    color:
                      item.meterId === selectedMeterId
                        ? 'primary'
                        : isDragging
                        ? 'bottomNavBorder'
                        : 'chartUnselectedBar',
                  }}
                  _dark={{
                    color:
                      item.meterId === selectedMeterId
                        ? 'primary'
                        : isDragging
                        ? 'bottomNavBorder'
                        : 'white',
                  }}
                  maxW={280}
                >
                  {item.name ||
                    item.address.street ||
                    item.address.city ||
                    item.address.postalCode ||
                    ''}
                </Text>
              </VStack>
              <Pressable onLongPress={drag} hitSlop={30}>
                {isActive ? (
                  <DraggableActiveIcon />
                ) : (
                  <DraggableIcon
                    color={colorMode === 'dark' ? '#DCDCDC' : '#294747'}
                  />
                )}
              </Pressable>
            </Pressable>
          </HStack>
        </ScaleDecorator>
      );
    };

    return (
      <VStack py={4}>
        <HStack px={27} alignItems={'center'}>
          <Text
            fontFamily={'readerPro'}
            fontSize={18}
            textAlign={'center'}
            flex={1}
            _light={{ color: '#294747' }}
          >
            {t('dashboard.metersBottomSheet.heading')}
          </Text>
          <Pressable onPress={closeCallback} hitSlop={10}>
            <XIcon />
          </Pressable>
        </HStack>
        <View h={DRAGGBLE_FLATLIST_HEIGHT} mb={2}>
          <DraggableFlatList
            keyExtractor={(item) => item.meterId}
            data={meters}
            renderItem={renderItem}
            style={{ marginVertical: 8 }}
            persistentScrollbar={true}
            onDragBegin={() => {
              setIsDragging(true);
            }}
            onDragEnd={({ data }) => {
              setIsDragging(false);
              const meterIds = data.map((meter) => meter.meterId);
              dispatch(saveMeterIds(meterIds));
              setActionSheetData(data);
            }}
            dragHitSlop={20}
            extraData={selectedMeterId}
          />
        </View>
        <Button
          bgColor={'chartUnselectedBar'}
          borderRadius={15}
          width={250}
          alignSelf={'center'}
          onPress={() => {
            closeCallback();
            navigation.navigate('gated', {
              screen: 'agreement',
            });
          }}
        >
          {t('dashboard.metersBottomSheet.powerButton')}
        </Button>
      </VStack>
    );
  }
);

export default MkMetersActionSheet;
