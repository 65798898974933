import { AccountDetails } from '../../types/AccountDetails';
import { apiSlice } from './apiSlice';

export const accountDetailsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    accountDetail: builder.query<AccountDetails, void>({
      query: () => ({
        url: '/accountDetails',
        method: 'get',
      }),
    }),
  }),
});

export const { useAccountDetailQuery } = accountDetailsSlice;
