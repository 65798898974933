import * as WebBrowser from 'expo-web-browser';
import {
  FileLogo,
  SettingsLogo,
  EnergyIcon,
  HelpIcon,
} from '../../components/MkIcons';
import {
  HStack,
  Pressable,
  ScrollView,
  Text,
  useColorMode,
  View,
  VStack,
} from 'native-base';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import MkBackground from '../../components/basic/MkBackground';
import { RootStackParamList } from '../../types/Navigation';
import { reactAppConfig } from '../../config/environment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { saveColorMode } from '../../redux/slices/colorModeSlice';
import { openBrowserAsync } from 'expo-web-browser';
import { apiSlice } from '../../redux/slices/apiSlice';
import { emptyPreference } from '../../redux/slices/removedCarouselSlice';
import { saveMeterIds } from '../../redux/slices/meterSlice';
import { setSelectedMeterId } from '../../redux/slices/selectedMeterSlice';
import { saveRegionIds } from '../../redux/slices/regionSlice';
import { setSelectedRegion } from '../../redux/slices/selectedRegionSlice';
import AccountDetailsWidget from '../../components/profile/AccountDetailsWidget';
import { Platform } from 'react-native';
import { Suspense, lazy } from 'react';
import ProfileItemButton from '../../components/profile/ProfileItemButton';
import { useCustomerExistQuery } from '../../redux/slices/customerExistApiSlice';
import MkLoader from '../../components/basic/MkLoader';
import { useAuthentication } from '../../hooks/useAuthentication';
import { UseTrackEventType } from '../../../mixpanel.native';
import moment from 'moment';

let useTrackEvent: UseTrackEventType = null;
if (Platform.OS !== 'web') {
  import('../../../mixpanel.native').then((mixP) => {
    useTrackEvent = mixP.useTrackEvent;
  });
} else {
  console.warn('Mixpanel is disabled for web');
}

const CustomerServiceButton = lazy(
  () => import('../../components/chatSupport/CustomerServiceButton')
);

const ProfileScreen = () => {
  const { t } = useTranslation();
  const { destroySession } = useAuthentication();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();
  const { data: customerExists } = useCustomerExistQuery();
  const trackHelpButtonPress = useTrackEvent
    ? useTrackEvent('Help Page Button Pressed')
    : null;

  const handleLogout = () => {
    destroySession().then(() => {
      dispatch(apiSlice.util.resetApiState());
      dispatch(emptyPreference());
      dispatch(saveMeterIds([]));
      dispatch(setSelectedMeterId(''));
      dispatch(saveRegionIds([]));
      dispatch(setSelectedRegion({}));
    });
    dispatch(saveColorMode(colorMode));
  };

  return (
    <MkBackground pt={9}>
      <ScrollView>
        <VStack space={5} flex={1}>
          <HStack alignItems={'center'} justifyContent={'space-between'}>
            <Text
              _light={{
                color: 'onboardingButtonText',
              }}
              fontSize={25}
              fontFamily={'readerRegular'}
            >
              {t('profile.title')}
            </Text>
            <Pressable
              onPress={() => {
                openBrowserAsync('https://hjelp.motkraft.no/nb/');
                if (trackHelpButtonPress) {
                  trackHelpButtonPress({
                    'Date-Time': moment().format('HH:mm DD:MM:YYYY'),
                  });
                }
              }}
            >
              <HStack alignItems={'center'} justifyContent={'center'} space={2}>
                <Text
                  fontSize={18}
                  pb={0.5}
                  color={'primary'}
                  fontFamily={'readerPro'}
                >
                  {t('profile.help')}
                </Text>
                <HelpIcon />
              </HStack>
            </Pressable>
          </HStack>
          {customerExists && customerExists.exists && <AccountDetailsWidget />}
          <ProfileItemButton
            iconName={<SettingsLogo />}
            title={t('settings.title')}
            description={t('settings.settingsDescription')}
            onPress={() => {
              navigation.navigate('gated', {
                screen: 'settings',
              });
            }}
          />
          <ProfileItemButton
            iconName={<FileLogo />}
            title={t('profile.invoices')}
            description={t('profile.invoicesDescription')}
            onPress={() => {
              navigation.navigate('gated', {
                screen: 'invoices',
              });
            }}
          />
          <ProfileItemButton
            iconName={<EnergyIcon width={17} height={24} />}
            title={t('service.agreement')}
            description={t('service.description')}
            onPress={() => {
              navigation.navigate('gated', {
                screen: 'agreement',
              });
            }}
          />
          {Platform.OS !== 'web' && (
            <Suspense fallback={<MkLoader />}>
              <CustomerServiceButton />
            </Suspense>
          )}
          <VStack m={2} mb={16} space={2}>
            <Text
              color={'primary'}
              onPress={handleLogout}
              fontFamily={'readerBold'}
              fontSize={18}
              mb={22}
            >
              {t('profile.logOut')}
            </Text>
            <Text
              onPress={() => {
                WebBrowser.openBrowserAsync(
                  'https://www.motkraft.no/bruksvilkar'
                );
              }}
              fontFamily={'readerBold'}
              fontSize={14}
              _light={{ color: 'bottomNavBorder' }}
            >
              {t('profile.tnc')}
            </Text>
            <Text
              onPress={() => {
                navigation.navigate('gated', {
                  screen: 'termsandconditions',
                });
              }}
              fontFamily={'readerBold'}
              fontSize={14}
              _light={{ color: 'bottomNavBorder' }}
            >
              {t('profile.privacy')}
            </Text>
          </VStack>
          <Text
            mb={23}
            fontFamily={'readerRegular'}
            fontSize={15}
            textAlign={'center'}
            _light={{ color: 'bottomNavBorder' }}
          >
            Motkraft v {reactAppConfig.versionNumber} (
            {reactAppConfig.buildNumber})
          </Text>
        </VStack>
      </ScrollView>
    </MkBackground>
  );
};

export default ProfileScreen;
