import {
  DarkModeIcon,
  ForwardArrow,
  LanguageIcon,
  LightModeIcon,
  LineGraphIcon,
} from '../../components/MkIcons';
import {
  View,
  HStack,
  Pressable,
  ScrollView,
  Text,
  VStack,
  useColorMode,
} from 'native-base';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Platform, StyleProp, ViewStyle } from 'react-native';
import MkBackground from '../../components/basic/MkBackground';
import MkCard from '../../components/basic/MkCard';
import React from 'react';
import { RootStackParamList } from '../../types/Navigation';
import { useTranslation } from 'react-i18next';
import { setStatusBarStyle } from 'expo-status-bar';
import { LANGUAGES } from './LanguageSelectorScreen';
import { useDispatch } from 'react-redux';
import { saveColorMode } from '../../redux/slices/colorModeSlice';
import { motkraftTheme } from '../../theme';
import { UseTrackEventType } from '../../../mixpanel.native';
import moment from 'moment';

let useTrackEvent: UseTrackEventType = null;
if (Platform.OS !== 'web') {
  import('../../../mixpanel.native').then((mixP) => {
    useTrackEvent = mixP.useTrackEvent;
  });
} else {
  console.warn('Mixpanel is disabled for web');
}

export type SettingsItemButtonProps = {
  iconName: any;
  title: string;
  description?: string;
  onPress?: () => void;
  endIcon?: JSX.Element;
  vstackStyle?: StyleProp<ViewStyle>;
  hstackStyle?: StyleProp<ViewStyle>;
};

const SettingsScreen = () => {
  const { t, i18n } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { colorMode, toggleColorMode } = useColorMode();
  const dispatch = useDispatch();
  const trackDarkLightMode = useTrackEvent
    ? useTrackEvent('Dark/Light Mode')
    : null;
  let languageTitle = t('languageSelector.language');

  if (i18n.language !== 'en') {
    languageTitle = `${languageTitle} (Language)`;
  }

  const languageDescription = LANGUAGES.find(
    (lang) => lang.code === i18n.language
  )?.label;

  const onToggle = () => {
    dispatch(saveColorMode(colorMode === 'dark' ? 'light' : 'dark'));
    toggleColorMode();
    setStatusBarStyle(colorMode === 'dark' ? 'light' : 'dark');
    if (trackDarkLightMode) {
      trackDarkLightMode({
        'Date-Time': moment().format('HH:mm DD:MM:YYYY'),
      });
    }
  };

  return (
    <MkBackground pt={9}>
      <ScrollView mt={22}>
        <VStack space={5} flex={1}>
          <SettingsItemButton
            iconName={
              <LineGraphIcon
                width={30}
                height={30}
                fill={motkraftTheme.colors.primary}
              />
            }
            title={t('profile.graphSettings.title')}
            onPress={() => {
              navigation.navigate('gated', {
                screen: 'graphSettings',
              });
            }}
            vstackStyle={{ flexDirection: 'row' }}
            hstackStyle={{ paddingHorizontal: 18, paddingVertical: 22 }}
          />
          <SettingsItemButton
            iconName={<LanguageIcon />}
            title={languageTitle}
            description={languageDescription}
            onPress={() => {
              navigation.navigate('gated', {
                screen: 'languageSelector',
              });
            }}
            vstackStyle={{ flexDirection: 'column' }}
            hstackStyle={{ paddingHorizontal: 18, paddingVertical: 22 }}
          />
          <SettingsItemButton
            iconName={
              colorMode == 'dark' ? <DarkModeIcon /> : <LightModeIcon />
            }
            title={t('settings.darkMode')}
            description={
              colorMode == 'dark'
                ? `${t('settings.statusOn')}`
                : `${t('settings.statusOff')}`
            }
            endIcon={
              <Pressable
                w={50}
                h={29}
                borderRadius={15}
                flexDirection={'row'}
                justifyContent={
                  colorMode === 'dark' ? 'flex-end' : 'flex-start'
                }
                bg={colorMode === 'dark' ? 'light' : 'switchOffTrack'}
                onPress={onToggle}
              >
                <View
                  w={25}
                  h={25}
                  bg={colorMode === 'dark' ? 'primary' : 'bottomNavBorder'}
                  borderRadius={'50'}
                  m={0.5}
                />
              </Pressable>
            }
            vstackStyle={{ flexDirection: 'column' }}
            hstackStyle={{ paddingHorizontal: 18, paddingVertical: 13 }}
          />
        </VStack>
      </ScrollView>
    </MkBackground>
  );
};

const SettingsItemButton = ({
  iconName,
  title,
  description,
  onPress,
  endIcon = <ForwardArrow />,
  vstackStyle,
  hstackStyle,
}: SettingsItemButtonProps) => {
  return (
    <MkCard>
      <Pressable onPress={onPress}>
        <HStack style={hstackStyle} alignItems={'center'} space={4}>
          {iconName}
          <VStack flex={1} style={vstackStyle}>
            <Text fontFamily={'readerPro'} fontSize={18}>
              {title}
            </Text>
            <Text
              fontFamily={'readerRegular'}
              _light={{ color: 'bottomNavBorder' }}
              fontSize={16}
            >
              {description}
            </Text>
          </VStack>
          {endIcon}
        </HStack>
      </Pressable>
    </MkCard>
  );
};

export default SettingsScreen;
