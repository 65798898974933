import { Box, IBoxProps, useColorMode, View } from 'native-base';
import { LinearGradientColors } from '../../theme';
import { LinearGradient } from 'expo-linear-gradient';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface IMKBackgroundProps {
  childContainerStyles?: IBoxProps;
  darkMode?: boolean;
}

const MkBackground = ({
  children,
  darkMode = false,
  ...props
}: IBoxProps & IMKBackgroundProps) => {
  const { colorMode } = useColorMode();
  const insets = useSafeAreaInsets();

  return (
    <LinearGradient
      start={[0, 0]}
      end={[0.5, 0.3]}
      colors={
        colorMode === 'dark' || darkMode
          ? LinearGradientColors.colors
          : ['#F0F0F0', '#F0F0F0']
      }
      style={{ flex: 1 }}
    >
      <View
        style={{
          flex: 1,
          paddingBottom: insets.bottom,
          paddingLeft: insets.left,
          paddingRight: insets.right,
          paddingTop: insets.top,
        }}
      >
        <Box flex={1} px={19} {...props}>
          {children}
        </Box>
      </View>
    </LinearGradient>
  );
};
export default MkBackground;
