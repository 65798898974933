import { Button, Text, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { ThunderIcon } from '../MkIcons';
import MkCard from './MkCard';
import { reactAppConfig } from '../../config/environment';
import { openBrowserAsync } from 'expo-web-browser';
import { UseTrackEventType } from '../../../mixpanel.native';
import { Platform } from 'react-native';
import moment from 'moment';

let useTrackEvent: UseTrackEventType = null;
if (Platform.OS !== 'web') {
  import('../../../mixpanel.native').then((mixP) => {
    useTrackEvent = mixP.useTrackEvent;
  });
} else {
  console.warn('Mixpanel is disabled for web');
}

const MkOnboardingWidget = () => {
  const { t } = useTranslation();
  const trackOrderPower = useTrackEvent
    ? useTrackEvent('Order Non Customer')
    : null;

  return (
    <MkCard my={23}>
      <VStack alignItems={'center'} pt={33} pb={63}>
        <ThunderIcon />
        <Text
          mt={23}
          textAlign={'center'}
          fontFamily={'readerBold'}
          fontSize={18}
        >
          {t('dashboard.onboarding.title')}
        </Text>
        <Text
          mt={23}
          mx={25}
          textAlign={'center'}
          fontFamily={'readerRegular'}
          fontSize={17}
        >
          {t('dashboard.onboarding.desc1')}
        </Text>
        <Text
          mt={35}
          mx={25}
          textAlign={'center'}
          fontFamily={'readerRegular'}
          fontSize={17}
        >
          {t('dashboard.onboarding.desc2')}
        </Text>
        <Button
          bgColor="primary"
          borderRadius={40}
          mt={35}
          px={41}
          py={17}
          _text={{
            fontFamily: 'readerPro',
            fontSize: 18,
            color: 'onboardingButtonText',
          }}
          onPress={() => {
            if (trackOrderPower) {
              trackOrderPower({
                'Date-Time': moment().format('HH:mm DD:MM:YYYY'),
              });
            }
            openBrowserAsync(reactAppConfig.onboardingUrl);
          }}
        >
          {t('dashboard.onboarding.button_title')}
        </Button>
      </VStack>
    </MkCard>
  );
};

export default MkOnboardingWidget;
