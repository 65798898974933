import * as WebBrowser from 'expo-web-browser';
import {
  Button,
  HStack,
  Pressable,
  ScrollView,
  Text,
  VStack,
  View,
} from 'native-base';
import { ForwardArrow, SecurityIcon } from '../../components/MkIcons';
import { useEffect, useState } from 'react';
import ErrorPageScreen from '../miscellaneous/ErrorPageScreen';
import MkBackground from '../../components/basic/MkBackground';
import MkCard from '../../components/basic/MkCard';
import { MkModal } from '../../components/basic/MkModalForTerms';
import { motkraftTheme } from '../../theme';
import { useDeleteDataMutation } from '../../redux/slices/deleteRequestSlice';
import { useTranslation } from 'react-i18next';

const TermsAndCondition = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const [trigger, result] = useDeleteDataMutation();

  const { t } = useTranslation();

  const openConfirmModel = () => {
    setModalVisible(true);
  };
  const closeConfirmModel = () => {
    setModalVisible(false);
  };
  const closeSuccesModel = () => {
    setSuccessModalVisible(false);
  };

  useEffect(() => {
    if (result.isSuccess) {
      closeConfirmModel();
      setSuccessModalVisible(true);
    }
  }, [result]);

  return (
    <MkBackground pt={55}>
      <ScrollView>
        {result.isError ? (
          <ErrorPageScreen />
        ) : (
          <View>
            <VStack space={8}>
              <Pressable
                onPress={() => {
                  WebBrowser.openBrowserAsync(
                    'https://www.motkraft.no/personvernerklaering'
                  );
                }}
              >
                <MkCard>
                  <HStack p={6} justifyContent={'space-between'}>
                    <VStack space={4}>
                      <Text fontFamily={'readerPro'} fontSize={18}>
                        {t('profile.privacyScreen.privacyCardTitle')}
                      </Text>
                      <Text fontFamily={'readerRegular'} fontSize={16}>
                        {t('profile.privacyScreen.privacyCardDescription')}
                      </Text>
                    </VStack>
                    <VStack justifyContent={'space-between'} space={58}>
                      <SecurityIcon />
                      <View alignSelf={'flex-end'}>
                        <ForwardArrow />
                      </View>
                    </VStack>
                  </HStack>
                </MkCard>
              </Pressable>
              <View pt={20}>
                <Button
                  alignSelf={'center'}
                  variant={'solidRounded'}
                  bgColor={'deleteButtonBg'}
                  onPress={openConfirmModel}
                >
                  <Text
                    fontFamily={'readerRegular'}
                    fontSize={16}
                    color={'white'}
                  >
                    {t('profile.privacyScreen.requestDeleteAccountButton')}
                  </Text>
                </Button>
              </View>
            </VStack>
            <MkModal modalVisible={modalVisible} closeModel={closeConfirmModel}>
              <VStack alignItems={'center'} space={4}>
                <Text fontFamily={'readerBold'} fontSize={18} color={'black'}>
                  {t('profile.privacyScreen.deleteAccountConfirmationTitle')}
                </Text>
                <Text
                  pb={1}
                  fontFamily={'readerRegular'}
                  fontSize={16}
                  color={'black'}
                >
                  {t(
                    'profile.privacyScreen.deleteAccountConfirmationDescription'
                  )}
                </Text>
                <HStack
                  flex={1}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  space={4}
                >
                  <Button
                    bgColor={'deleteCancelButtonBg'}
                    variant={'solidRounded'}
                    w={140}
                    onPress={closeConfirmModel}
                  >
                    <Text
                      fontFamily={'readerPro'}
                      fontSize={13}
                      color={'black'}
                    >
                      {t('profile.privacyScreen.deleteDataCancel')}
                    </Text>
                  </Button>
                  <Button
                    variant={'solidRounded'}
                    bgColor={'deleteButtonBg'}
                    w={140}
                    onPress={() => {
                      trigger();
                    }}
                  >
                    <Text
                      fontFamily={'readerPro'}
                      fontSize={13}
                      color={'white'}
                    >
                      {t('profile.privacyScreen.deleteDataAccept')}
                    </Text>
                  </Button>
                </HStack>
              </VStack>
            </MkModal>
            <MkModal
              modalVisible={successModalVisible}
              closeModel={closeSuccesModel}
            >
              <VStack alignItems={'center'} space={5}>
                <Text
                  textAlign={'center'}
                  color={'black'}
                  fontFamily={'readerRegular'}
                  fontSize={15}
                >
                  {t('profile.privacyScreen.deleteDataRequestSent')}
                </Text>
                <Button
                  borderRadius={25}
                  bgColor={'primary'}
                  w={120}
                  onPress={closeSuccesModel}
                >
                  <Text fontFamily={'readerPro'} fontSize={13} color={'white'}>
                    Ok!
                  </Text>
                </Button>
              </VStack>
            </MkModal>
          </View>
        )}
      </ScrollView>
    </MkBackground>
  );
};

export default TermsAndCondition;
