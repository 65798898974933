import { HStack, Pressable, ScrollView, Text, View, VStack } from 'native-base';
import MkBackground from '../../components/basic/MkBackground';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDashboardDataQuery } from '../../redux/slices/dashboardApiSlice';
import { usePowerPricesByRegionQuery } from '../../redux/slices/powerPriceApiSlice';
import { MeterData } from '../../types/Dashboard';
import { formatAsLocalNumber } from '../../utils/number-formatter';
import MkModal from '../../components/basic/MkModal';
import { PowerPriceIntervals, Regions } from '../../types/PowerPrice';
import {
  convertIntervalToDropdownData,
  getRegionsDropdownData,
} from '../../utils/functions';
import GraphError from '../miscellaneous/GraphError';
import ElectricityPriceWait from '../../components/price/ElectricityPriceWait';
import LoadingSkeleton from '../miscellaneous/LoadingSkeleton';
import { LayoutChangeEvent, RefreshControl } from 'react-native';
import moment from 'moment-timezone';
import { RouteProp, useFocusEffect, useRoute } from '@react-navigation/native';
import { GatedStackParamList } from '../../types/Navigation';
import { getModalTimeString } from '../../utils/date';
import useProgressViewOffset from '../../hooks/useProgressViewOffset';
import { GraphIntervals } from '../../components/basic/MkChartSwitch';
import MkDetailsLineChart from '../../components/chart/MkDetailsLineChart';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import MkBottomSheet from '../../components/basic/MkBottomSheet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { saveMeterIds } from '../../redux/slices/meterSlice';
import { setSelectedMeterId } from '../../redux/slices/selectedMeterSlice';
import { setSelectedRegion } from '../../redux/slices/selectedRegionSlice';
import { saveRegionIds } from '../../redux/slices/regionSlice';
import MkDetailsBarChart from '../../components/chart/MkDetailsBarChart';
import { ConsumptionInterval } from '../../types/PowerConsumption';
import MkDayIntervalSwitch from '../../components/price/MkDayIntervalSwitch';
import MkLoader from '../../components/basic/MkLoader';
import { UseTrackEventType } from '../../../mixpanel.native';
import { Platform } from 'react-native';

let useTrackEvent: UseTrackEventType = null;
if (Platform.OS !== 'web') {
  import('../../../mixpanel.native').then((mixP) => {
    useTrackEvent = mixP.useTrackEvent;
  });
} else {
  console.warn('Mixpanel is disabled for web');
}

type DataSet = {
  x: number;
  y: number;
  xLabel?: string;
  label?: string[];
  labelLine1: string;
  labelLine2: string;
  labelLine3: string;
};

export type PriceScreenIntervalDropdownValue = {
  label: string;
  value: PowerPriceIntervals;
};

export type RegionDropDownData = {
  label: string;
  value: Regions;
};

const PowerPriceScreen = () => {
  const { isCustomer } =
    useRoute<RouteProp<GatedStackParamList, 'powerPrice'>>().params;
  const graphMode = useSelector(
    (state: RootState) => state.graphSettings.priceChartMode
  );
  const { t } = useTranslation();
  const DAYS = Object.values(PowerPriceIntervals);
  const intervals = convertIntervalToDropdownData(DAYS, t);
  const [selectedInterval, setSelectedInterval] =
    useState<PriceScreenIntervalDropdownValue>(intervals[0]);
  const [averagePrice, setAveragePrice] = useState(0);
  const [meters, setMeters] = useState<MeterData[]>([] as MeterData[]);
  const selectedMeterId = useSelector(
    (state: RootState) => state.selectedMeterId
  );
  const [chartData, setChartData] = useState<DataSet[]>([]);
  const [defaultSelectedIndex, setDefaultSelectedIndex] = useState<number>(0);
  const [offset, setOffset] = useState<string>(moment.tz.guess(true));
  const progressViewOffset = useProgressViewOffset();
  const bottomSheetModalRef = useRef<BottomSheetModal>(null);
  const [selectedMeter, setSelectedMeter] = useState<MeterData>();
  const storedMeterIds = useSelector((state: RootState) => state.meters);
  const dispatch = useDispatch();
  const [regionId, setRegionId] = useState<string>('');
  const [regions, setRegions] = useState(getRegionsDropdownData());
  const selectedRegion = useSelector(
    (state: RootState) => state.selectedRegion
  );
  const [selectedRegionState, setSelectedRegionState] =
    useState<RegionDropDownData>();
  const storedRegionIds = useSelector((state: RootState) => state.regions);
  const [chartParentHeight, setChartParentHeight] = useState(0);
  const scrollRef = useRef(null);
  const [chartSwapping, setChartSwapping] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(0);
  const trackPriceScreenChartType = useTrackEvent
    ? useTrackEvent('Price Page Chart Type')
    : null;
  const trackPriceMeterChange = useTrackEvent
    ? useTrackEvent('Meter Change')
    : null;
  const trackInfoClickFromPower = useTrackEvent
    ? useTrackEvent('Price Information Button')
    : null;

  const trackInfoModalOpen = () => {
    if (trackInfoClickFromPower) {
      trackInfoClickFromPower({
        'Date-Time': moment().format('HH:mm DD:MM:YYYY'),
      });
    }
  };

  const populateChartData = () => {
    if (powerPrices && !pricesFetching) {
      // lastFetchedMoment.current = moment();
      let cd: DataSet[] = [];
      let totalPrices = 0;
      let xValueCounter = 0;
      powerPrices.prices[PowerPriceIntervals.today].forEach((price) => {
        if (moment(price.timestamp).hour() === moment().hour()) {
          setCurrentPrice(price.value);
        }
      });
      powerPrices.prices[selectedInterval.value].forEach((price) => {
        totalPrices += price.value;
        let xLabel = moment(price.timestamp).hour();
        if (moment(price.timestamp).hour() === moment().hour()) {
          setDefaultSelectedIndex(xValueCounter);
        }
        cd.push({
          x: xValueCounter++,
          y: price.value,
          xLabel:
            selectedInterval.value === PowerPriceIntervals.today &&
            moment().hour() === xLabel
              ? `${t('electricityPrice.now')}`
              : xLabel.toString(),
          labelLine1: `${t('consumptionData.resolution.daily.at')} ${
            xLabel < 10 ? `0${xLabel}` : xLabel
          }-${
            xLabel === 23
              ? '00'
              : xLabel + 1 < 10
              ? `0${xLabel + 1}`
              : xLabel + 1
          }`,
          labelLine2: `${formatAsLocalNumber(price.value)} øre`,
          labelLine3: 'per kWt',
        });
      });
      setAveragePrice(totalPrices / cd.length);
      setChartData(cd);
      setChartSwapping(false);
    }
  };

  const {
    data: dashboardData,
    error: dashboardError,
    isLoading: dashboardLoading,
  } = useDashboardDataQuery({ offset }, { skip: !isCustomer });

  const {
    data: powerPrices,
    error: pricesError,
    isLoading: pricesLoading,
    isFetching: pricesFetching,
    isUninitialized: pricesStatus,
    refetch: pricesRefetch,
  } = usePowerPricesByRegionQuery(
    {
      region: regionId,
      offset,
    },
    {
      skip: regionId === '' || !offset,
    }
  );

  useFocusEffect(
    useCallback(() => {
      if (!pricesStatus) {
        setChartData([] as DataSet[]);
        setOffset(moment.tz.guess(true));
        pricesRefetch();
      }
    }, [graphMode])
  );

  useEffect(() => {
    if (!isCustomer) {
      if (storedRegionIds.length > 0) {
        const sortedRegions = regions.sort(
          (a, b) =>
            storedRegionIds.indexOf(a.value) - storedRegionIds.indexOf(b.value)
        );
        setRegions(sortedRegions);
        dispatch(
          setSelectedRegion(
            selectedRegion.value ? selectedRegion : sortedRegions[0]
          )
        );
        setRegionId(
          selectedRegion.value ? selectedRegion.value : sortedRegions[0].value
        );
      } else {
        const regionIds = regions.map((region) => region.value);
        dispatch(saveRegionIds(regionIds));
        dispatch(setSelectedRegion(regions[0]));
        setRegionId(regions[0].value);
      }
    }
  }, [storedRegionIds]);

  useEffect(() => {
    if (dashboardData) {
      const apiMeterIds = dashboardData.meters.reduce((x, y) => {
        x.push(y.meterId);
        return x;
      }, [] as string[]);

      let isDifferent = false;

      apiMeterIds.forEach((meterId) => {
        if (!storedMeterIds.includes(meterId)) {
          isDifferent = true;
          return false;
        }
      });

      if (isDifferent) {
        dispatch(saveMeterIds(apiMeterIds));
        setMeters(dashboardData.meters);
        dispatch(setSelectedMeterId(dashboardData.meters[0].meterId));
        setSelectedMeter(dashboardData.meters[0]);
        setRegionId(dashboardData.meters[0].prices.region);
      } else {
        const sortedMeters = dashboardData.meters
          .slice()
          .sort(
            (a, b) =>
              storedMeterIds.indexOf(a.meterId) -
              storedMeterIds.indexOf(b.meterId)
          );
        setMeters(sortedMeters);
        if (selectedMeterId === '') {
          dispatch(setSelectedMeterId(sortedMeters[0].meterId));
          setSelectedMeter(sortedMeters[0]);
          setRegionId(sortedMeters[0].prices.region);
        } else {
          const userSelectedMeter =
            sortedMeters.find((meter) => meter.meterId === selectedMeterId) ||
            sortedMeters[0];
          setSelectedMeter(userSelectedMeter);
          setRegionId(userSelectedMeter.prices.region);
        }
      }
    }
  }, [dashboardData]);

  useEffect(() => {
    populateChartData();
  }, [powerPrices, selectedInterval, pricesFetching]);

  const closeActionSheet = () => {
    bottomSheetModalRef.current?.dismiss();
  };

  const dismissBottomSheetMeters = () => {
    if (selectedMeter?.meterId !== selectedMeterId) {
      const userSelectedMeter =
        meters.find((meter) => meter.meterId === selectedMeterId) || meters[0];
      setChartData([] as DataSet[]);
      setSelectedMeter(userSelectedMeter);
      setRegionId(userSelectedMeter.prices.region);
      setOffset(moment.tz.guess(true));
      pricesRefetch();
    }
  };

  const dismissBottomSheetRegions = () => {
    if (selectedRegionState !== selectedRegion) {
      setChartData([] as DataSet[]);
      setRegionId(selectedRegion.value);
      setOffset(moment.tz.guess(true));
      pricesRefetch();
    }
  };

  const getHoursXLabels = (totalHours: number) => {
    const hoursXLabels = [0];
    const numberOf4Multiples = Math.floor(totalHours / 4);
    let i = 1;
    while (i <= numberOf4Multiples) {
      hoursXLabels.push(i * 4);
      i++;
    }
    hoursXLabels.push(totalHours);
    return hoursXLabels;
  };

  const chartParentOnLayoutHandler = (evt: LayoutChangeEvent) => {
    setChartParentHeight(evt.nativeEvent.layout.height);
  };

  const handleDayIntervalChange = (
    interval: PriceScreenIntervalDropdownValue
  ) => {
    if (selectedInterval.label === interval.label) {
      return;
    }
    setTimeout(() => {
      setChartData([] as DataSet[]);
      setSelectedInterval(interval);
    }, 150);
  };

  useFocusEffect(
    useCallback(() => {
      if (trackPriceScreenChartType) {
        trackPriceScreenChartType({
          'Chart-Type': graphMode,
        });
      }
    }, [graphMode])
  );
  if (dashboardLoading || pricesLoading || pricesFetching)
    return <LoadingSkeleton />;

  return (
    <MkBackground mt={12}>
      <ScrollView
        contentContainerStyle={{ flex: 1 }}
        ref={scrollRef}
        refreshControl={
          <RefreshControl
            refreshing={pricesFetching}
            onRefresh={() => {
              setChartData([] as DataSet[]);
              setOffset(moment.tz.guess(true));
              pricesRefetch();
            }}
            progressViewOffset={progressViewOffset}
          />
        }
      >
        {dashboardError || pricesError ? (
          <View flex={1}>
            <GraphError screen={'powerPrice'} />
          </View>
        ) : (
          <View flex={1}>
            <MkBottomSheet
              isCustomer={isCustomer}
              bottomSheetModalRef={bottomSheetModalRef}
              closeFunction={closeActionSheet}
              data={isCustomer ? meters : regions}
              setData={isCustomer ? setMeters : setRegions}
              dismissFunction={
                isCustomer
                  ? dismissBottomSheetMeters
                  : dismissBottomSheetRegions
              }
            />
            <VStack alignItems={'center'} space={4}>
              <Pressable
                onPress={() => {
                  if (!isCustomer) {
                    setSelectedRegionState(selectedRegion);
                  }
                  bottomSheetModalRef.current?.present();
                  if (trackPriceMeterChange) {
                    trackPriceMeterChange({
                      'Date-Time': moment().format('HH:mm DD:MM:YYYYY'),
                      'Current-Price': `${formatAsLocalNumber(
                        currentPrice
                      )} øre/kWt`,
                    });
                  }
                }}
              >
                <Text
                  fontSize={18}
                  fontFamily={'readerPro'}
                  _dark={{ color: 'primary' }}
                  _light={{ color: 'lightBottomSheetButtonText' }}
                >
                  {isCustomer
                    ? selectedMeter?.name ||
                      selectedMeter?.address?.street ||
                      selectedMeter?.address?.city ||
                      selectedMeter?.address?.postalCode
                    : selectedRegion.label}
                </Text>
              </Pressable>
              <MkDayIntervalSwitch
                selectedInterval={selectedInterval}
                handleIntervalChange={handleDayIntervalChange}
                toggleChartSwapping={setChartSwapping}
              />
            </VStack>
            {chartSwapping ? (
              <MkLoader renderMkBackground={false} />
            ) : chartData.length > 0 ? (
              <View flex={1}>
                <VStack mt={19} mb={5}>
                  <Text
                    fontSize={18}
                    fontFamily={'readerPro'}
                    _light={{ color: 'chartUnselectedBar' }}
                  >
                    {t('dashboard.power.spotPriceData.title')}
                  </Text>
                  <HStack space={1}>
                    <Text
                      fontSize={30}
                      fontFamily={'readerBold'}
                      _light={{ color: 'chartUnselectedBar' }}
                    >
                      {`${t('electricityPrice.pricePerKilowattHourLabel', {
                        price: formatAsLocalNumber(currentPrice),
                      })}`}
                    </Text>
                    <MkModal trackMixPanelEvent={trackInfoModalOpen}>
                      <View px={2} py={5} justifyContent={'center'}>
                        <Text
                          fontSize={16}
                          fontFamily={'readerRegular'}
                          textAlign={'center'}
                          color={'black'}
                          pb={2}
                        >
                          {t('electricityPrice.popupTextHeader')}
                        </Text>
                        <Text
                          fontSize={16}
                          fontFamily={'readerRegular'}
                          textAlign={'center'}
                          color={'black'}
                          pb={4}
                        >
                          {t('electricityPrice.popupTextBody', {
                            startTime: getModalTimeString('13'),
                            endTime: getModalTimeString('14'),
                          })}
                        </Text>
                        <Text
                          fontSize={16}
                          fontFamily={'readerRegular'}
                          textAlign={'center'}
                          color={'black'}
                        >
                          {t('electricityPrice.popupTextFooter')}
                        </Text>
                      </View>
                    </MkModal>
                  </HStack>
                  <Text
                    fontSize={18}
                    fontFamily={'readerPro'}
                    _light={{ color: 'chartUnselectedBar' }}
                  >{`${t(
                    `dashboard.powerPrice.${
                      selectedInterval.value === PowerPriceIntervals.today
                        ? 'averagePriceToday'
                        : 'averagePriceTomorrow'
                    }`
                  )}`}</Text>
                  <Text
                    fontSize={18}
                    fontFamily={'readerBold'}
                    _light={{ color: 'chartUnselectedBar' }}
                  >
                    {`${formatAsLocalNumber(averagePrice)} øre`}
                  </Text>
                </VStack>
                <View flex={1} onLayout={chartParentOnLayoutHandler} mb={25}>
                  {chartParentHeight > 0 && (
                    <>
                      {graphMode === GraphIntervals.barChart ? (
                        <MkDetailsBarChart
                          parentSrollRef={scrollRef}
                          chartHeight={chartParentHeight}
                          data={chartData}
                          defaultSelectedBar={defaultSelectedIndex}
                          xLabels={getHoursXLabels(23)}
                          screen={'powerPrice'}
                          interval={ConsumptionInterval.daily}
                          graphLabel={t('electricityPrice.unit')}
                        />
                      ) : (
                        <MkDetailsLineChart
                          parentSrollRef={scrollRef}
                          chartHeight={chartParentHeight}
                          data={chartData}
                          defaultSelectedBar={defaultSelectedIndex}
                          xLabels={getHoursXLabels(23)}
                          screen={'powerPrice'}
                          interval={ConsumptionInterval.daily}
                          graphLabel={t('electricityPrice.unit')}
                        />
                      )}
                    </>
                  )}
                </View>
              </View>
            ) : (
              <View mt={10} flex={1}>
                <ElectricityPriceWait />
              </View>
            )}
          </View>
        )}
      </ScrollView>
    </MkBackground>
  );
};

export default PowerPriceScreen;
