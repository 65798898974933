import {
  HStack,
  Pressable,
  ScrollView,
  Text,
  useColorMode,
  View,
  VStack,
} from 'native-base';
import MkBackground from '../../components/basic/MkBackground';
import MkCarousel from '../../components/dashboardWidgets/MkCarousel';
import { useTranslation } from 'react-i18next';
import PowerConsumption from '../../components/dashboardWidgets/PowerConsumption';
import DashboardInvoiceList from '../../components/dashboardWidgets/DashboardInvoiceList';
import ElectricityPrice from '../../components/dashboardWidgets/ElectricityPrice';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDashboardDataQuery } from '../../redux/slices/dashboardApiSlice';
import { useInvoiceListQuery } from '../../redux/slices/invoiceApiSlice';
import LoadingSkeleton from '../miscellaneous/LoadingSkeleton';
import ErrorPageScreen from '../miscellaneous/ErrorPageScreen';
import ErrorWidget from '../miscellaneous/ErrorWidget';
import { MeterData, PriceData } from '../../types/Dashboard';
import {
  NavigationProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import { RootStackParamList } from '../../types/Navigation';
import OnboardingScreen from './OnboardingScreen';
import { Platform, RefreshControl } from 'react-native';
import { setStatusBarStyle } from 'expo-status-bar';
import { useCustomerExistQuery } from '../../redux/slices/customerExistApiSlice';
import moment from 'moment-timezone';
import { MkLogoTitle } from '../../components/MkIcons';
import { useDispatch, useSelector } from 'react-redux';
import { saveMeterIds } from '../../redux/slices/meterSlice';
import { RootState } from '../../redux/store';
import { setSelectedMeterId } from '../../redux/slices/selectedMeterSlice';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import useProgressViewOffset from '../../hooks/useProgressViewOffset';
import MkBottomSheet from '../../components/basic/MkBottomSheet';
import { UseTrackEventType } from '../../../mixpanel.native';
import { formatAsLocalNumber } from '../../utils/number-formatter';

let useTrackEvent: UseTrackEventType = null;
if (Platform.OS !== 'web') {
  import('../../../mixpanel.native').then((mixP) => {
    useTrackEvent = mixP.useTrackEvent;
  });
} else {
  console.warn('Mixpanel is disabled for web');
}

const DashboardScreen = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const progressViewOffset = useProgressViewOffset();
  const savedColorMode = useSelector(
    (state: RootState) => state.colorMode.colorMode
  );

  useEffect(() => {
    if (colorMode !== savedColorMode) {
      toggleColorMode();
    }
  }, [savedColorMode, colorMode]);

  useEffect(() => {
    setStatusBarStyle(colorMode === 'dark' ? 'light' : 'dark');
  }, [colorMode]);

  const {
    data: customerExists,
    isLoading: customerExistsLoading,
    isFetching: customerExistsFetching,
    refetch: customerExistsRefetch,
    error: customerExistsError,
  } = useCustomerExistQuery();

  if (customerExistsLoading) return <LoadingSkeleton isBackEnabled={false} />;

  return customerExistsError ? (
    <MkBackground pt={9}>
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={customerExistsFetching}
            onRefresh={customerExistsRefetch}
            progressViewOffset={progressViewOffset}
          />
        }
      >
        <ErrorPageScreen />
      </ScrollView>
    </MkBackground>
  ) : customerExists?.exists ? (
    <CustomerDashboard />
  ) : (
    <OnboardingScreen />
  );
};

const CustomerDashboard = () => {
  const bottomSheetModalRef = useRef<BottomSheetModal>(null);
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const INVOICE_LIMIT = 3;
  const [meters, setMeters] = useState<MeterData[]>([]);
  const [offset, setOffset] = useState<string>(moment.tz.guess(true));
  const storedMeterIds = useSelector((state: RootState) => state.meters);
  const selectedMeterId = useSelector(
    (state: RootState) => state.selectedMeterId
  );
  const dispatch = useDispatch();
  const [selectedMeter, setSelectedMeter] = useState<MeterData>();
  const trackDashboardMeterChange = useTrackEvent
    ? useTrackEvent('Meter Change')
    : null;
  const trackCurrentPrice = useTrackEvent
    ? useTrackEvent('Current Price')
    : null;

  const {
    data: dashboardData,
    error: dashboardError,
    isLoading: dashboardLoading,
    isFetching: dashboardFetching,
    refetch: dashboardRefetch,
  } = useDashboardDataQuery({ offset });

  useEffect(() => {}, []);

  useFocusEffect(
    useCallback(() => {
      setOffset(moment.tz.guess(true));
      dashboardRefetch();
    }, [moment().hour()])
  );

  useEffect(() => {
    if (dashboardData) {
      // lastFetchedMoment.current = moment();
      const apiMeterIds = dashboardData.meters.reduce((x, y) => {
        x.push(y.meterId);
        return x;
      }, [] as string[]);

      let isDifferent = false;

      apiMeterIds.forEach((meterId) => {
        if (!storedMeterIds.includes(meterId)) {
          isDifferent = true;
          return false;
        }
      });

      if (isDifferent) {
        dispatch(saveMeterIds(apiMeterIds));
        setMeters(dashboardData.meters);
      } else {
        const sortedMeters = dashboardData.meters
          .slice()
          .sort(
            (a, b) =>
              storedMeterIds.indexOf(a.meterId) -
              storedMeterIds.indexOf(b.meterId)
          );
        setMeters(sortedMeters);
      }
    }
  }, [dashboardData, selectedMeterId]);

  useEffect(() => {
    if (meters.length > 0) {
      const userSelectedMeter = meters.find(
        (meter) => meter.meterId === selectedMeterId
      );
      let meterPrice = '';
      if (userSelectedMeter) {
        setSelectedMeter(userSelectedMeter);
        userSelectedMeter.prices.day.map((price: PriceData) => {
          if (moment(price.timestamp).hour() === moment().hour()) {
            meterPrice = formatAsLocalNumber(price.value);
          }
        });
      } else {
        dispatch(setSelectedMeterId(meters[0].meterId));
        setSelectedMeter(meters[0]);
        meters[0].prices.day.map((price: PriceData) => {
          if (moment(price.timestamp).hour() === moment().hour()) {
            meterPrice = formatAsLocalNumber(price.value);
          }
        });
      }
      if (trackDashboardMeterChange) {
        trackDashboardMeterChange({
          'Date-Time': moment().format('HH:mm DD:MM:YYYY'),
          'Current-Price': `${meterPrice} øre/kWt`,
        });
      }
    }
  }, [meters, selectedMeterId]);

  const {
    error: invoiceError,
    isLoading: invoiceLoading,
    isFetching: invoiceFetching,
    refetch: invoiceRefetch,
  } = useInvoiceListQuery(INVOICE_LIMIT);

  const currentMeterConsumption = selectedMeter?.consumption;

  const closeActionSheet = () => {
    bottomSheetModalRef.current?.dismiss();
  };

  const dismissBottomSheet = () => {
    if (selectedMeter?.meterId !== selectedMeterId) {
      setSelectedMeter(
        meters.find((meter) => meter.meterId === selectedMeterId)
      );
      setOffset(moment.tz.guess(true));
      dashboardRefetch();
      invoiceRefetch();
    }
  };

  useEffect(() => {
    if (selectedMeter?.prices.day !== undefined) {
      selectedMeter?.prices.day.map((price: PriceData) => {
        if (moment(price.timestamp).hour() === moment().hour()) {
          const current_Price = formatAsLocalNumber(price.value);
          if (trackCurrentPrice) {
            trackCurrentPrice({
              'Selected Meter Id': selectedMeter.meterId,
              'Current Price': `${current_Price} øre/kWt`,
            });
          }
          return;
        }
      });
    }
  }, []);

  if (
    dashboardLoading ||
    invoiceLoading ||
    dashboardFetching ||
    invoiceFetching
  )
    return <LoadingSkeleton isBackEnabled={false} />;

  return (
    <MkBackground pt={9}>
      <HStack justifyContent={'space-between'}>
        <MkLogoTitle />
        {meters.length > 0 && (
          <Pressable
            onPress={() => {
              bottomSheetModalRef.current?.present();
            }}
            hitSlop={20}
            maxW={150}
          >
            <Text
              fontFamily={'readerPro'}
              fontSize={18}
              numberOfLines={1}
              _dark={{ color: 'primary' }}
              _light={{ color: 'lightText' }}
            >
              {selectedMeter?.name ||
                selectedMeter?.address?.street ||
                selectedMeter?.address?.city ||
                selectedMeter?.address?.postalCode ||
                ''}
            </Text>
          </Pressable>
        )}
      </HStack>
      <ScrollView
        mt={2}
        pt={33}
        refreshControl={
          <RefreshControl
            refreshing={dashboardFetching && invoiceFetching}
            onRefresh={() => {
              setOffset(moment.tz.guess(true));
              dashboardRefetch();
              invoiceRefetch();
            }}
          />
        }
      >
        {dashboardError && invoiceError ? (
          <ErrorPageScreen showTitle={false} showChatButton={true} />
        ) : (
          <VStack mb={85}>
            <MkBottomSheet
              isCustomer={true}
              bottomSheetModalRef={bottomSheetModalRef}
              closeFunction={closeActionSheet}
              data={meters}
              setData={setMeters}
              dismissFunction={dismissBottomSheet}
            />
            <View mx={-3}>
              <MkCarousel />
            </View>
            {dashboardError ? (
              <ErrorWidget />
            ) : (
              <View>
                {selectedMeter?.prices !== undefined && (
                  <ElectricityPrice
                    dayPrices={selectedMeter.prices.day}
                    defaultSelected={selectedMeter.meterId}
                  />
                )}
                <View mt={14}>
                  <PowerConsumption
                    energy={currentMeterConsumption?.energy || 0}
                    cost={currentMeterConsumption?.cost || 0}
                    month={currentMeterConsumption?.month || 1}
                  />
                </View>
              </View>
            )}
            <HStack
              p={2}
              pt={31}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Text
                fontFamily={'readerRegular'}
                fontSize={16}
                _light={{ color: 'black' }}
              >
                {t('dashboard.tabs.invoice')}
              </Text>
              <View
                alignSelf={'center'}
                borderBottomColor={'primary'}
                borderBottomWidth={1.5}
              >
                <Text
                  _light={{
                    color: 'black',
                  }}
                  fontFamily={'readerPro'}
                  fontSize={16}
                  onPress={() => {
                    navigation.navigate('gated', {
                      screen: 'invoices',
                    });
                  }}
                >
                  {t('dashboard.tabs.seemore')}
                </Text>
              </View>
            </HStack>
            {invoiceError ? (
              <ErrorWidget />
            ) : (
              <View mt={15}>
                <DashboardInvoiceList limit={3} />
              </View>
            )}
          </VStack>
        )}
      </ScrollView>
    </MkBackground>
  );
};

export default DashboardScreen;
