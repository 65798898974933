import moment from 'moment';

export const getCurrentTimestamp = (): number => {
  return Date.now();
};

export const getWeekFromTimestamp = (timestamp: number): number => {
  const currentDate = moment(timestamp).toDate();
  const firstDayOfYear = moment([currentDate.getFullYear(), 0, 1]).toDate();
  const diff = currentDate.getTime() - firstDayOfYear.getTime();
  const numberOfDays = Math.floor(diff / (24 * 60 * 60 * 1000));
  return Math.floor((currentDate.getDay() + 1 + numberOfDays) / 7);
};

export const getMinutesPassedSinceBeginningOfDayUntilNow = () => {
  const now = moment();
  return now.hour() * 60 + now.minute();
};

export const isTimeWithinGivenIntervalFromNow = (
  time: number,
  allowedDeviation: number
) => {
  const minutes = moment(time).hour() * 60 + moment(time).minute();
  return (
    Math.abs(minutes - getMinutesPassedSinceBeginningOfDayUntilNow()) <=
    allowedDeviation
  );
};

export function yearMonthToDate(year: number, month: number): Date {
  return moment([year, month - 1, 1]).toDate();
}

export function monthToDate(month: number): Date {
  const arbitraryYear = 2000;
  return moment([arbitraryYear, month - 1, 1]).toDate();
}

export function getModalTimeString(t: string) {
  return moment
    .tz(t, 'HH', 'Europe/Oslo')
    .utc()
    .add(moment().utcOffset(), 'm')
    .format('HH:mm');
}
