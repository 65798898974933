import { Text, View, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import LottieView from 'lottie-react-native';
import { motkraftTheme } from '../../theme';
import { useEffect, useRef } from 'react';
import { AppState } from 'react-native';

type GraphErrorPropsType = {
  screen: string;
};

const GraphError = ({ screen }: GraphErrorPropsType) => {
  const { t } = useTranslation();
  const graphErrorAnimationRef = useRef<LottieView>(null);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (nextAppState === 'active') {
        graphErrorAnimationRef.current?.play();
      }
    });

    return () => {
      subscription.remove();
    };
  }, []);

  return (
    <VStack mt={128} flex={1} alignItems={'center'}>
      <VStack space={4} justifyContent={'center'} alignItems={'center'}>
        <Text
          fontSize={20}
          fontFamily={'readerBold'}
          textAlign={'center'}
          _light={{ color: 'black' }}
        >
          {t('consumptionData.error.title')}
        </Text>
        <Text
          fontSize={17}
          fontFamily={'readerRegular'}
          textAlign={'center'}
          px={29}
          _light={{ color: 'black' }}
        >
          {screen === 'powerPrice'
            ? t('electricityPrice.errorDisplay.description')
            : t('consumptionData.error.description')}
        </Text>
      </VStack>
      <View h={325} w={325} mt={50}>
        <LottieView
          ref={graphErrorAnimationRef}
          source={require('../../assets/animations/graph-error.json')}
          speed={2}
          colorFilters={[
            {
              keypath: 'Shape Layer 1',
              color: motkraftTheme.colors.chartUnselectedBar,
            },
            {
              keypath: 'Shape Layer 2',
              color: motkraftTheme.colors.primary,
            },
          ]}
          autoPlay
          loop
        />
      </View>
    </VStack>
  );
};

export default GraphError;
