import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { PublicNavigator } from './stackNavigations/public-navigator';
import GatedNavigator from './stackNavigations/gated-navigator';
import { RootStackParamList } from '../types/Navigation';
import UpdateEnforcementScreen from '../screens/gated/UpdateEnforcementScreen';
import { useAuthentication } from '../hooks/useAuthentication';

const RootStack = createNativeStackNavigator<RootStackParamList>();

export function RootNavigator() {
  const { userAuthenticated, enforceUpdate } = useAuthentication();

  if (enforceUpdate) return <UpdateEnforcementScreen />;

  return (
    <RootStack.Navigator
      initialRouteName={'public'}
      screenOptions={{ headerBackVisible: false }}
    >
      {userAuthenticated ? (
        <RootStack.Screen
          name="gated"
          component={GatedNavigator}
          options={{ headerShown: false }}
        />
      ) : (
        <RootStack.Screen
          name="public"
          component={PublicNavigator}
          options={{ headerShown: false }}
        />
      )}
    </RootStack.Navigator>
  );
}
