import { RouteProp, useRoute } from '@react-navigation/native';
import { HStack, Pressable, Text, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import MkBackground from '../../components/basic/MkBackground';
import MkCard from '../../components/basic/MkCard';
import { GatedStackParamList } from '../../types/Navigation';
import { EditIcon } from '../../components/MkIcons';
import NickNameModal from '../../components/basic/NickNameModal';
import { useAddMeterNicknameMutation } from '../../redux/slices/residencesApiSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedMeterNickName } from '../../redux/slices/meterNickNameSlice';
import { RootState } from '../../redux/store';

type LabelValuePairProps = {
  label: string;
  value: string;
};

const AddressDetail = () => {
  const route = useRoute<RouteProp<GatedStackParamList, 'addressDetail'>>();
  const meter = route.params.meter;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [trigger, { isLoading, data }] = useAddMeterNicknameMutation();
  const dispatch = useDispatch();
  const storedMeterNickName = useSelector(
    (state: RootState) => state.meterNickName
  );

  useEffect(() => {
    if (data) {
      dispatch(setSelectedMeterNickName(data.nickName));
    }
  }, [data]);

  return (
    <MkBackground mt={9}>
      <VStack mt={14}>
        <HStack alignSelf={'center'} alignItems={'center'} mt={14}>
          <Text
            fontFamily={'readerRegular'}
            fontWeight={400}
            fontSize={25}
            mr={2}
          >
            {storedMeterNickName}
          </Text>
          <Pressable
            onPress={() => {
              setIsOpen(true);
            }}
            hitSlop={10}
          >
            <EditIcon />
          </Pressable>
        </HStack>
      </VStack>
      <MkCard p={43} mt={36}>
        <LabelValuePair
          label={t('profile.residences.details.owner')}
          value={meter.owner}
        />
        <LabelValuePair
          label={t('profile.invoicesScreen.invoiceDetails.measurementId')}
          value={meter.name ? meter?.name?.meterId : ''}
        />
        <LabelValuePair
          label={t('profile.residences.details.address')}
          value={
            meter.address.street ||
            meter.address.city ||
            meter.address.postalCode
          }
        />
      </MkCard>
      <NickNameModal
        defaultValue={storedMeterNickName}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        meterId={meter.name ? meter?.name?.meterId : ''}
        onSubmit={trigger}
        loading={isLoading}
      />
    </MkBackground>
  );
};

const LabelValuePair = ({ label, value }: LabelValuePairProps) => {
  return (
    <HStack justifyContent={'space-between'} my={2}>
      <Text fontFamily={'readerPro'} fontSize={16}>
        {label}
      </Text>
      <Text fontFamily={'readerRegular'} fontSize={16} textAlign={'right'}>
        {value}
      </Text>
    </HStack>
  );
};

export default AddressDetail;
